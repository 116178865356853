import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { Input } from "../../../components/Input";
import { useTranslation } from "react-i18next";
import { Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import years from "../../../assets/icons/years.svg";
import percent from "../../../assets/icons/percent.svg";

export const JuniorDebt = ({
  sourceForm,
  handleChange,
  handleChangePercent,
}) => {
  const { t } = useTranslation("strategies");

  const textProps = {
    size: theme.fonts.size.h6,
    weight: theme.fonts.weight.medium,
    color: theme.colors.blue
  }

  return (
    <Row gutter={[32, 16]} style={{ width: '100%' }}>
      <Col md={24}>
        <Text {...textProps}>
          {t("juniorDebtForm.sizing")}
        </Text>
      </Col>
      <Col md={8}>
        <Input 
          id="tenor"
          label={t("juniorDebtForm.financingTerm")}
          value={sourceForm.tenor}
          onChange={handleChangePercent}
          suffix={<img alt="years" src={years} />}
        />
      </Col>
      <Col md={8}>
        <Input 
          id="maxLeverageOverCAPEX"
          label={t("juniorDebtForm.maxCapex")}
          value={sourceForm.maxLeverageOverCAPEX}
          onChange={handleChangePercent}
          suffix={<img alt="percent" src={percent} />}
        />
      </Col>
      <Col md={8}>
        <Input 
          id="DSCR"
          label={t("juniorDebtForm.coverage")}
          value={sourceForm.DSCR}
          onChange={handleChange}
        />
      </Col>
      <Col md={24} style={{ marginTop: "16px" }}>
        <Text {...textProps}>
          {t("juniorDebtForm.financingPrices")}
        </Text>
      </Col>
      <Col md={8}>
        <Input 
          id="juniorOpeningFee"
          label={t("juniorDebtForm.upFrontFee")}
          value={sourceForm.juniorOpeningFee}
          onChange={handleChangePercent}
          suffix={<img alt="percent" src={percent} />}
        />
      </Col>
      <Col md={8}>
        <Input 
          id="juniorSpread"
          label={t("juniorDebtForm.margin")}
          value={sourceForm.juniorSpread}
          onChange={handleChangePercent}
          suffix={<img alt="percent" src={percent} />}
        />
      </Col>
    </Row>
  )
};

JuniorDebt.propTypes = {
  sourceForm: PropTypes.object,
  handleChange: PropTypes.func,
  handleChangePercent: PropTypes.func,
};