import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select2";
import { useTranslation } from "react-i18next";
import { theme } from "../../../styles/theme";
import percent from "../../../assets/icons/percent.svg";
import { NonCall } from "../../../utilities/generalOptions";

export const FinancingPrice = ({
  sourceForm,
  handleSelect,
  handleChangePercent
}) => {
  const { t } = useTranslation("strategies");

  return (
    <Row gutter={[32, 32]} style={{ width: '100%', marginTop: "32px" }}>
      <Col md={8}>
        <Input 
          id="financingOpeningFee"
          label={t("seniorDebtForm.upFrontFee")}
          value={sourceForm.financingOpeningFee}
          onChange={handleChangePercent}
          suffix={<img alt="percent" src={percent} />}
        />
      </Col>
      <Col md={8}>
        <Input 
          id="financingSpread"
          label={t("seniorDebtForm.margin")}
          value={sourceForm.financingSpread}
          onChange={handleChangePercent}
          suffix={<img alt="percent" src={percent} />}
        />
      </Col>
      <Col md={8}>
        <Input 
          id="makeWholeComission"
          label={t("seniorDebtForm.commitmentFee")}
          value={sourceForm.makeWholeComission}
          onChange={handleChangePercent}
          suffix={<img alt="percent" src={percent} />}
        />
      </Col>
      <Col md={8}>
        <Select 
          label={t("seniorDebtForm.noCallPeriod")}
          placeholder={t("select")}
          options={NonCall}
          value={sourceForm.nonCallPeriod}
          onChange={(e) => handleSelect(e, "nonCallPeriod")}
          arrowColor={theme.colors.gray200}
          width={"100%"}
        />
      </Col>
    </Row>
  )
};

FinancingPrice.propTypes = {
  sourceForm: PropTypes.object,
  handleSelect: PropTypes.func,
  handleChangePercent: PropTypes.func,
};