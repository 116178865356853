import React from "react";
import PropTypes from "prop-types";
import { Row, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Input } from "../../../components/Input";
import { useTranslation } from "react-i18next";
import { Tag } from "../../../components/Tag";

export const SponsorDescription = ({
  sponsorDescription,
  setSponsorDescription,
  handleChange,
  handleChangeNumber,
}) => {
  const { t } = useTranslation("teaserDocumentation");

  return (
    <>
      <Row gap="28px" m="17.5px 0 0 0">
        <Input
          label={t("description")}
          id="description"
          width="100%"
          height="106px"
          value={sponsorDescription.description}
          onChange={(event) =>
            handleChange(event, sponsorDescription, setSponsorDescription)
          }
        />
      </Row>

      <Row gap="28px" m="17.5px 0 0 0" p="0 0 0 0">
        <Input
          label={t("yearsExperience")}
          id="yearsExperience"
          type="number"
          width="100%"
          value={sponsorDescription.yearsExperience}
          onChange={(event) =>
            handleChangeNumber(event, sponsorDescription, setSponsorDescription)
          }
        />
        <Input
          label={t("completedProjects")}
          id="completedProjects"
          type="number"
          width="100%"
          value={sponsorDescription.completedProjects}
          onChange={(event) =>
            handleChangeNumber(event, sponsorDescription, setSponsorDescription)
          }
        />
        <Input
          label={t("totalMw")}
          id="totalMw"
          type="number"
          width="100%"
          suffix="MW"
          value={sponsorDescription.totalMw}
          onChange={(event) =>
            handleChangeNumber(event, sponsorDescription, setSponsorDescription)
          }
        />
      </Row>

      <Row gap="28px" m="17.5px 0 0 0" p="0 50px 0 0">
        <Text color={theme.colors.gray200} mb="18px">
          {t("presence")}
        </Text>
      </Row>
      <Row gap="16px" style={{ flexWrap: "wrap" }}>
        {sponsorDescription.presenceCountries?.map((country, index) => (
          <Tag key={index} color={theme.colors.gray100} width="auto">
            <Text
              size={theme.fonts.size.sm}
              weight={theme.fonts.weight.regular}
              color={theme.colors.gray300}
            >
              {t(country)}
            </Text>
          </Tag>
        ))}
      </Row>
    </>
  );
};

SponsorDescription.propTypes = {
  sponsorDescription: PropTypes.object,
  setSponsorDescription: PropTypes.func,
  handleChange: PropTypes.func,
  handleChangeNumber: PropTypes.func,
};
