import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Upload from "../../../../assets/icons/upload-green.svg";
import Document from "../../../../assets/icons/Document-green.svg";
import Email from "../../../../assets/icons/Email.svg";
import Arrow from "../../../../assets/icons/arrow-down-green.svg";
import Copy from "../../../../assets/icons/Copy-gray.svg";
import sun from "../../../../assets/icons/Sun-green.svg";
import windy from "../../../../assets/icons/Windy.svg";
import Switch from "../../../../assets/icons/Switch.svg";
import Delete from "../../../../assets/icons/Delete-gray.svg";
import { Button } from "../../../../components/Button";
import Card from "../../../../components/CardDropdown";
import { IconButton } from "../../../../components/IconButton";
import { Div, Text } from "../../../../styles/Common";
import { theme } from "../../../../styles/theme";
import { EmptyProject } from "../Promoter/emptyProject";
import { StatusLabel, ToggleContent, CardContainer } from "../styles";
import { projectStatusManager } from "../../../../utilities/helpers";
import { Pagination } from "../../../../components/Pagination";
import { axiosClient } from "../../../../core/services/axiosInstance";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { Link } from "../../../../components/Link";
import { Tooltip } from "antd";
import { ModalChangeOwner } from "./ModalChangeOwner";
import useChangeOwner from "../../hooks/useChangeOwner";
import useGetDevelopmentUsers from "../../hooks/useGetDeveloperUsers";

export const ProjectsTableManager = ({
  projects,
  activeKey,
  totalProjects,
  pageSize,
  currentPage,
  onPageChange,
  setShowDocumentsDrawer,
  setShowModalDistribution,
  setSelectedProject,
  setShowDrawerA5Modeling,
  companiesOptions,
}) => {
  const { t } = useTranslation("projectTableManager");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const changeOwner = useChangeOwner();
  const getDevelopmentUsers = useGetDevelopmentUsers();
  const [usersOptions, setUsersOptions] = useState([]);
  const [visibleContent, setVisibleContent] = useState(
    Array(projects.length).fill(false)
  );
  const [showOwnerModal, setShowOwnerModal] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [ownerId, setOwnerId] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [projectId, setProjectId] = useState("");

  useEffect(() => {
    const payload = {
      limit: null,
      offset: null,
      userRole: "Developer",
      companyId: companyId,
    };

    if (companyId) {
      getDevelopmentUsers.reset();
      getDevelopmentUsers.mutate(payload, {
        onSuccess: (resp) => {
          const users = resp?.data.data.map((ele) => ({
            label: ele.name,
            value: ele.id,
          }));
          setUsersOptions(users);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const handleToggleContent = (index) => {
    const newVisibleContent = [...visibleContent];
    newVisibleContent[index] = !newVisibleContent[index];
    setVisibleContent(newVisibleContent);
  };

  const formatName = (name) => {
    const trimmedName = name?.length > 20 ? name.substring(0, 20) : name;
    if (trimmedName) {
      return trimmedName.padEnd(20, "");
    } else {
      return trimmedName;
    }
  };

  if (activeKey === "1" && projects.length === 0) {
    return <EmptyProject />;
  }

  const panelInfo = [
    {
      label: "a5Modelling",
      value: 0,
      color: theme.colors.green,
      onClick: (project) => {
        setSelectedProject(project);
        setShowDrawerA5Modeling(true);
      },
    },
    {
      label: "documentation",
      value: 0,
      color: theme.colors.green,
      onClick: (project) => {
        setSelectedProject(project);
        setShowDocumentsDrawer(true);
      },
    },
    {
      label: "distribution",
      value: 0,
      color: theme.colors.green,
      onClick: (project) => {
        setSelectedProject(project);
        setShowModalDistribution(true);
      },
    },
    {
      label: "activity",
      value: 0,
      color: theme.colors.gray150,
      onClick: () => navigate("activity-project"),
    },
    {
      label: "marketPlace",
      value: 0,
      color: theme.colors.gray150,
      onClick: () => {},
    },
  ];

  const typeLabel = (label) => {
    switch (label) {
      case "financing":
        return "Financiación";
      case "sale":
        return "Venta";
      default:
        return "-";
    }
  };

  const handleDataRoom = (name, id) => {
    navigate(`/projects/documents?projectName=${name}&projectId=${id}`);
  };

  const handleProjectEdit = (
    projectId,
    objective,
    projectType,
    financingType,
    projectName
  ) => {
    navigate(
      `/projects/edit-project?objective=${objective}&projectType=${projectType}&financingType=${financingType}&projectId=${projectId}&projectName=${projectName}`
    );
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 2:
        return theme.colors.green500;
      case 1:
        return theme.colors.blue;
      case 5:
      case 9:
        return theme.colors.gray300;
      case 4:
      case 6:
        return theme.colors.red500;
      case 3:
        return theme.colors.green;
      default:
        return theme.colors.green500;
    }
  };

  // Función para determinar el color del texto
  const getTextColor = (backgroundColor) => {
    if (backgroundColor != "#ffff") {
      return theme.colors.white;
    } else {
      return theme.colors.green100;
    }
  };

  const currentPanel = (instance) => {
    if (instance === "sale") {
      return panelInfo.slice(1, panelInfo.length + 1);
    } else {
      return panelInfo;
    }
  };

  const currentIndex = (instance) => {
    if (instance === "sale") {
      return 3;
    } else {
      return 4;
    }
  };

  const handleSendEmail = (projectId) => {
    const getContactOwner = async () => {
      try {
        const response = await axiosClient.get(
          `/project/send-unfinished-project-reminder/${projectId}`
        );
        const contactOwnerData = response.data;
        if (contactOwnerData?.success) {
          toast.success(t("sendEmail"));
        }

        queryClient.invalidateQueries(["contactOwner", projectId]);
      } catch (error) {
        toast.error(t("notSendEmail"));
      }
    };

    if (projectId) {
      getContactOwner();
    }
  };

  const handleChangeOwner = (name, id) => {
    setShowOwnerModal(true);
    setProjectId(id);
    setProjectName(name);
  };

  const handleClose = () => {
    setShowOwnerModal(false);
    setProjectId("");
    setProjectName("");
    setCompanyId(null);
    setOwnerId(null);
  };

  const actions = [
    {
      icon: Switch,
      long: "48px",
      tooltip: t("changeOwner"),
      handleClick: handleChangeOwner,
      disabled: false,
    },
    {
      icon: Copy,
      long: "45px",
      tooltip: t("copyProject"),
      handleClick: () => {},
      disabled: true,
    },
    {
      icon: Delete,
      long: "48px",
      tooltip: t("deleteProject"),
      handleClick: () => {},
      disabled: true,
    },
  ];

  const handleSelectCompany = (value) => {
    setCompanyId(value);
  };

  const handleSelectOwner = (value) => {
    setOwnerId(value);
  };

  const handleConfirmChangeOwner = () => {
    const payload = {
      projectId: projectId,
      ownerId: ownerId,
    };

    changeOwner.reset();
    changeOwner.mutate(payload, {
      onSuccess: () => {
        setShowOwnerModal(false);
        setProjectId("");
        setProjectName("");
        setCompanyId(null);
        setOwnerId(null);
        queryClient.invalidateQueries("getProjects");
      },
      onError: (err) => {
        toast.error(
          t(
            `common:${
              err.response.data.result.code.message ||
              err.response.data.result.code
            }`
          )
        );
      },
    });
  };

  return (
    <Div direction="column">
      <ModalChangeOwner
        showModal={showOwnerModal}
        handleCloseModal={handleClose}
        companiesOptions={companiesOptions}
        usersOptions={usersOptions}
        companyId={companyId}
        ownerId={ownerId}
        handleSelectCompany={handleSelectCompany}
        handleSelectOwner={handleSelectOwner}
        projectName={projectName}
        handleConfirmChangeOwner={handleConfirmChangeOwner}
        loading={changeOwner.isLoading}
      />
      {projects.map((project, index) => (
        <CardContainer key={index}>
          <Card p={"0px"} m="1rem 0 0 0">
            {activeKey === "1" && project.projectStatus?.length > 0 && (
              <Row style={{ textAlign: "-webkit-right", marginBottom: "1px" }}>
                <Col md={24}>
                  <StatusLabel
                    color={
                      project.projectStatus?.length > 0 &&
                      getStatusColor(
                        project.projectStatus[project.projectStatus?.length - 1]
                          ?.type
                      )
                    }
                    textColor={getTextColor(
                      getStatusColor(
                        project.projectStatus[project.projectStatus?.length - 1]
                          ?.type
                      )
                    )}
                  >
                    {project.projectStatus?.length > 0 &&
                      t(
                        projectStatusManager[
                          project.projectStatus[
                            project.projectStatus?.length - 1
                          ]?.type
                        ]
                      )}
                  </StatusLabel>
                </Col>
              </Row>
            )}
            <Div
              direction="column"
              p={
                project.projectStatus?.length > 0
                  ? "0px 20px 12px 20px"
                  : activeKey === "1"
                  ? "28px 20px 12px 20px"
                  : "15px 20px 12px 20px"
              }
            >
              <Row>
                {project.teaserName !== "" && (
                  <Col md={24} style={{ marginBottom: "8px" }}>
                    <Text
                      color={theme.colors.blue}
                      size={theme.fonts.size.h6}
                      weight={theme.fonts.weight.medium}
                    >
                      {project.teaserName}
                    </Text>
                  </Col>
                )}
                <Col md={7}>
                  <Text
                    color={theme.colors.blue}
                    size={theme.fonts.size.sm}
                    weight={theme.fonts.weight.semibold}
                  >
                    {formatName(project.name)}
                  </Text>
                  <Text
                    color={theme.colors.green}
                    size={theme.fonts.size.sm}
                    weight={theme.fonts.weight.medium}
                    title={project.name}
                  >
                    <img
                      alt="type"
                      src={
                        project.projectType?.type.name === "solar" ? sun : windy
                      }
                    />
                    {project.projectType?.type.name === "solar"
                      ? "Solar"
                      : "Eólico"}
                  </Text>
                </Col>
                <Col md={7}>
                  <Text
                    color={theme.colors.gray300}
                    size={theme.fonts.size.sm}
                    weight={theme.fonts.weight.semibold}
                  >
                    {t("type")}
                  </Text>
                  <Text color={theme.colors.gray300} size={theme.fonts.size.sm}>
                    {typeLabel(project.projectType?.instance?.name)}
                  </Text>
                </Col>
                <Col md={6}>
                  <Text
                    color={theme.colors.gray300}
                    size={theme.fonts.size.sm}
                    weight={theme.fonts.weight.semibold}
                  >
                    {t("plants")}
                  </Text>
                  <Text color={theme.colors.gray300} size={theme.fonts.size.sm}>
                    {project.plants.length > 0
                      ? `${project.plants.length}  ${
                          project.plants.length === 1 ? "Planta" : "Plantas"
                        }`
                      : "-"}
                  </Text>
                </Col>
                <Col md={4}>
                  {activeKey === "2" ? (
                    <Div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "14px",
                      }}
                    >
                      <div>
                        <Text
                          color={theme.colors.gray300}
                          size={theme.fonts.size.sm}
                          weight={theme.fonts.weight.semibold}
                        >
                          {t("contactDate")}
                        </Text>
                        <Text
                          color={theme.colors.gray300}
                          size={theme.fonts.size.sm}
                        >
                          {project.expectedClosingDate
                            ? project.expectedClosingDate
                            : t("notContacted")}
                        </Text>
                      </div>
                      <IconButton
                        icon={Delete}
                        width={"45px"}
                        height={"45px"}
                        disabled
                      />
                    </Div>
                  ) : (
                    <Div gap="14px">
                      {actions.map((ele, i) => (
                        <Tooltip
                          key={i}
                          title={ele.tooltip}
                          color={theme.colors.white}
                          placement="topRight"
                          overlayInnerStyle={{
                            color: theme.colors.gray300,
                            fontFamily: theme.fonts.family,
                            fontSize: theme.fonts.size.sm,
                          }}
                        >
                          <IconButton
                            icon={ele.icon}
                            width={ele.long}
                            height={ele.long}
                            disabled={ele.disabled}
                            onClick={() =>
                              ele.handleClick(project.name, project._id)
                            }
                          />
                        </Tooltip>
                      ))}
                    </Div>
                  )}
                </Col>
              </Row>
              <hr
                style={{
                  width: "100%",
                  border: `1px solid ${theme.colors.gray100}`,
                  opacity: 0.82,
                }}
              />
              <Row>
                <Col md={7}>
                  {activeKey === "2" ? (
                    <Text
                      color={theme.colors.gray300}
                      size={theme.fonts.size.sm}
                      weight={theme.fonts.weight.medium}
                      style={{ marginRight: "0.5rem" }}
                    >
                      {t("draft")}
                    </Text>
                  ) : (
                    <Text
                      color={theme.colors.gray300}
                      size={theme.fonts.size.sm}
                      weight={theme.fonts.weight.medium}
                      style={{ marginRight: "0.5rem" }}
                    >
                      {t("phase")} {theme.projectStage}
                    </Text>
                  )}
                </Col>
                <Col md={7}>
                  <Text
                    color={theme.colors.gray300}
                    size={theme.fonts.size.sm}
                    weight={theme.fonts.weight.medium}
                    style={{ marginRight: "0.5rem" }}
                  >
                    {t("assetAcquisition")} {theme.assetAcquisition}
                  </Text>
                </Col>
                <Col md={6}>
                  <Text
                    color={theme.colors.gray300}
                    size={theme.fonts.size.sm}
                    weight={theme.fonts.weight.medium}
                    style={{ marginRight: "0.5rem" }}
                  >
                    {t("owner")} {project.owner}
                  </Text>
                </Col>
                <Col md={4}>
                  {activeKey === "2" ? (
                    <Button
                      width={"189px"}
                      height={"30px"}
                      icon={<img alt="Email" src={Email} />}
                      onClick={() => handleSendEmail(project._id)}
                    >
                      {t("contactOwner")}
                    </Button>
                  ) : (
                    <Link
                      onClick={() => handleToggleContent(index)}
                      style={{ marginTop: "4.5px" }}
                    >
                      {t("otherActions")}
                      <IconButton
                        icon={Arrow}
                        width={"32px"}
                        height={"32px"}
                        background={theme.colors.white}
                        rotate={visibleContent[index]}
                      />
                    </Link>
                  )}
                </Col>
              </Row>
              <ToggleContent
                isVisible={visibleContent[index]}
                activeKey={activeKey !== "2"}
              >
                <hr
                  style={{
                    width: "100%",
                    border: `1px solid ${theme.colors.gray100}`,
                    opacity: 0.82,
                    margin: "8.5px 0 20.5px 0",
                  }}
                />
                <Div m="19px 0 0 0" justify="space-between" align="center">
                  <Div align="center">
                    <Text
                      color={theme.colors.yellow700}
                      size={theme.fonts.size.default}
                      weight={theme.fonts.weight.medium}
                      style={{ marginRight: "16px", cursor: "pointer" }}
                      onClick={() =>
                        handleProjectEdit(
                          project._id,
                          project.projectType.instance.name,
                          project.projectType.type.name,
                          project.projectType.kind.name,
                          project.name
                        )
                      }
                    >
                      {t("rfaAlert")}
                    </Text>
                    <hr
                      style={{
                        height: "23px",
                        border: `1px solid ${theme.colors.gray100}`,
                        margin: "0 11px 0 9px",
                      }}
                    />
                    {currentPanel(project.projectType.instance.name).map(
                      (ele, indx) => (
                        <React.Fragment key={indx}>
                          <Div
                            gap="5px"
                            align="center"
                            onClick={() => ele.onClick(project)}
                            style={{ cursor: "pointer" }}
                          >
                            <Text
                              color={ele.color}
                              size={theme.fonts.size.default}
                              weight={theme.fonts.weight.medium}
                            >
                              {`${t(ele.label)}`}
                            </Text>
                            {ele.value !== 0 && (
                              <Text
                                color={theme.colors.green}
                                weight={theme.fonts.weight.semibold}
                              >
                                {`(${ele.value})`}
                              </Text>
                            )}
                          </Div>
                          {indx !==
                            currentIndex(project.projectType.instance.name) && (
                            <hr
                              style={{
                                height: "23px",
                                border: `1px solid ${theme.colors.gray100}`,
                                margin: "0 11px 0 9px",
                              }}
                            />
                          )}
                        </React.Fragment>
                      )
                    )}
                  </Div>
                  <Div>
                    <Button
                      width={"135px"}
                      height={"32px"}
                      m="0 1rem 0 0"
                      variant={"outlined"}
                      icon={<img alt="Document" src={Document} />}
                      onClick={() =>
                        navigate(
                          `deal-intel?projectId=${project._id}&projectName=${project.name}`
                        )
                      }
                    >
                      {t("dealIntel")}
                    </Button>
                    <Button
                      width={"145px"}
                      height={"32px"}
                      m="0 0 0 0"
                      variant={"outlined"}
                      icon={<img alt="Upload" src={Upload} />}
                      onClick={() => handleDataRoom(project.name, project.id)}
                    >
                      {t("dataRoom")}
                    </Button>
                  </Div>
                </Div>
              </ToggleContent>
            </Div>
          </Card>
        </CardContainer>
      ))}

      <Pagination
        total={totalProjects}
        onChange={onPageChange}
        pageSize={pageSize}
        defaultPageSize={5}
        current={currentPage}
      />
    </Div>
  );
};

ProjectsTableManager.propTypes = {
  projects: PropTypes.array,
  activeKey: PropTypes.string,
  totalProjects: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  setShowDocumentsDrawer: PropTypes.func,
  setShowModalDistribution: PropTypes.func,
  setSelectedProject: PropTypes.func,
  setShowDrawerA5Modeling: PropTypes.func,
  companiesOptions: PropTypes.array,
};
