import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { theme } from "../../../../styles/theme";
import { Row } from "../../../../styles/Common";
import { Input } from "../../../../components/Input";

export const Alter5 = ({
  strategyData,
  handleChangeStrategy,
  tabActiveKey,
}) => {
  const { t } = useTranslation("teaserDocumentation");
  return (
    <>
      <Row gap="23px" m="33.5px 0 24px 0">
        <Input
          label={t("openingFee")}
          id="openingFee"
          type="number"
          width="30%"
          suffix="%"
          sizeLabel={theme.fonts.size.default}
          value={strategyData[Number(tabActiveKey) - 1].platformFee.openingFee}
          onChange={(event) => handleChangeStrategy(event, "platformFee")}
        />
        <Input
          label={t("runningFee")}
          id="runningFee"
          width="30%"
          type="number"
          suffix="%"
          sizeLabel={theme.fonts.size.default}
          value={strategyData[Number(tabActiveKey) - 1].platformFee.runningFee}
          onChange={(event) => handleChangeStrategy(event, "platformFee")}
        />
      </Row>
      <Row gap="23px">
        <Input
          label={t("alter5PlatformFeeText")}
          id="alter5PlatformFeeText"
          width="100%"
          height="138px"
          sizeLabel={theme.fonts.size.default}
          value={
            strategyData[Number(tabActiveKey) - 1].platformFee
              .alter5PlatformFeeText
          }
          onChange={(event) => handleChangeStrategy(event, "platformFee")}
        />
      </Row>
    </>
  );
};

Alter5.propTypes = {
  strategyData: PropTypes.array,
  handleChangeStrategy: PropTypes.func,
  tabActiveKey: PropTypes.string,
};
