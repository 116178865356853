import React, { useState } from "react";
import { Div, ModuleContainer, Text } from "../../styles/Common";
import { Modal } from "../../components/Modal";
import { LikeIcon } from "../../assets/icons";
import { theme } from "../../styles/theme";
import { useTranslation } from "react-i18next";
import { Link } from "../../components/Link";
import arrowBack from "../../assets/icons/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import { Card } from "../Sources/styles";
import Tabs from "../../components/Tabs";
import { Button } from "../../components/Button";

export const UpdateSource = () => {
  const { t } = useTranslation("strategies");
  const navigate = useNavigate();
  const [showModalSuccess, ] = useState(false);
  const [activeKey, setActiveKey] = useState("1");

  const handleBack = () => {
    navigate("/strategies");
  };

  const onChange = (key) => {
    setActiveKey(key);
  };

  const handleClose = () => {};

  const type = 1;

  const panels = [
    {
      key: '1',
      tab: t("basicInfo"),
      children: "Form 1",
      disabled: false,
    },
    {
      key: '2',
      tab: type === "Junior Debt" ? t("juniorDebt") : t("seniorDebt"),
      children: "Form 2",
      disabled: false,
    },
    {
      key: '3',
      tab: t("intermediation"),
      children: "Form 3",
      disabled: false,
    },
  ];

  return (
    <ModuleContainer direction="column" padding="0px 69px 52px 32px">
      <Modal open={showModalSuccess} onCancel={handleClose}>
        <Modal.Header
          icon={
            <LikeIcon
              stroke={theme.colors.green}
              width={"50px"}
              height={"50px"}
            />
          }
          iconBackground={theme.colors.green100}
          iconWidth={"50px"}
          iconHeight={"50px"}
          align="-webkit-center"
          padding={"20px 63px 0px 63px"}
          title={t("sourceSuccessTitle")}
          weightTitle={theme.fonts.weight.medium}
        />
        <Modal.Body
          margin="28px 0 32px 0"
        >
          <Text size={theme.fonts.size.default} color={theme.colors.gray500} style={{ textAlign: "center" }}>
            {t("sourceSuccessMessage")}
          </Text>
        </Modal.Body>
      </Modal>
      <Div m="16px 0 36px 0">
        <Link icon={<img alt="back" src={arrowBack} />} onClick={handleBack}>
          {t("goBack")}
        </Link>
      </Div>
      <Div>
        <Card>
          <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onChange}>
            {panels.map((panel) => (
              <Tabs.Panel tab={panel.tab} key={panel.key} disabled={panel.disabled}>
                <Div width="100%" direction="column" p="28px 37px 0px 22px">
                  {panel.children}
                </Div>
              </Tabs.Panel>
            ))}
          </Tabs>
          <Div m="32px 0 0 0" gap="16px">
            <Button
              variant={"outlined"}
              width="177px"
              onClick={handleBack}
            >
                {t("cancel")}
              </Button>
            <Button width="207px">
              {t("saveInfo")}
            </Button>
          </Div>
        </Card>
      </Div>
    </ModuleContainer>
  )
};