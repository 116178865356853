import React, { useState } from "react";
import { Collapse } from "../../../../components/Collapse";
import { Text, Row, Col } from "../../../../styles/Common";
import DatePicker from "../../../../components/DatePicker";
import { theme } from "../../../../styles/theme";
import { Radio } from "../../../../components/Radio";
import { Button } from "../../../../components/Button";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { TransactionSummary } from "./transactionSummary";
import { PriceConsideration } from "./priceConsideration";
import { Platform } from "./platform";
import { Other } from "./other";

export const Strategy = ({
  tabActiveKey,
  strategyData,
  setStrategyData,
  handleChangeNumberStrategy,
  handleChangeStrategy,
  handleRadioStrategy,
  handleDateStrategy,
  handleDeleteStrategy,
}) => {
  const { t } = useTranslation("teaserDocumentation");

  const [activeKey, setActiveKey] = useState(["0"]);

  const handlePanelChange = (key) => {
    setActiveKey(key);
  };

  const itemsStrategy = [
    {
      key: "1",
      header: (
        <Row gap="10px">
          <Text
            color={theme.colors.green}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            1.
          </Text>
          <Text
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            {t("transactionSummary")}
          </Text>
        </Row>
      ),
      panel: (
        <TransactionSummary
          strategyData={strategyData}
          setStrategyData={setStrategyData}
          tabActiveKey={tabActiveKey}
        />
      ),
    },
    {
      key: "2",
      header: (
        <Row gap="10px">
          <Text
            color={theme.colors.green}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            2.
          </Text>
          <Text
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            {t("priceConsideration")}
          </Text>
        </Row>
      ),
      panel: (
        <PriceConsideration
          strategyData={strategyData}
          setStrategyData={setStrategyData}
          handleChangeNumber={handleChangeNumberStrategy}
          handleChange={handleChangeStrategy}
          tabActiveKey={tabActiveKey}
        />
      ),
    },
    {
      key: "3",
      header: (
        <Row gap="10px">
          <Text
            color={theme.colors.green}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            3.
          </Text>
          <Text
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            {t("fee")}
          </Text>
        </Row>
      ),
      panel: (
        <Platform
          strategyData={strategyData}
          setStrategyData={setStrategyData}
          handleChangeNumber={handleChangeNumberStrategy}
          handleChange={handleChangeStrategy}
          tabActiveKey={tabActiveKey}
        />
      ),
    },
    {
      key: "4",
      header: (
        <Row gap="10px">
          <Text
            color={theme.colors.green}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            4.
          </Text>
          <Text
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            {t("other")}
          </Text>
        </Row>
      ),
      panel: (
        <Other
          strategyData={strategyData}
          setStrategyData={setStrategyData}
          handleChange={handleChangeStrategy}
          tabActiveKey={tabActiveKey}
        />
      ),
    },
  ];

  return (
    <>
      <Collapse
        activeKey={activeKey}
        handlePanelChange={handlePanelChange}
        panels={itemsStrategy}
        group={true}
        marginBottom={"0px"}
        headerHeight="50px"
      />
      <Col m=" 0 0 24px 0">
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.h6}
          color={theme.colors.blue}
          mt="31.5px"
          mb="23.5px"
        >
          {t("additionalInformation")}
        </Text>
        <DatePicker
          label={t("dateClosing")}
          width="310px"
          colorPlaceholder={theme.colors.blue}
          style={{ width: "100%" }}
          value={
            strategyData[Number(tabActiveKey) - 1]?.dateClosing
              ? strategyData[Number(tabActiveKey) - 1].dateClosing
              : null
          }
          onChange={(date) => handleDateStrategy(date, "dateClosing")}
        />
      </Col>
      <Row justify="space-between" p="0 100px 0 0 " m=" 0 0 47px 0">
        <Col>
          <Text color={theme.colors.gray200} align="left" mb="10px">
            {t("hideTeaser")}
          </Text>
          <Radio.Group
            name="hideToSponsor"
            gap="48px"
            value={strategyData[Number(tabActiveKey) - 1]?.hideToSponsor}
            onChange={handleRadioStrategy}
          >
            <Radio label={t("yes")} value={true}></Radio>
            <Radio label="No" value={false}></Radio>
          </Radio.Group>
        </Col>
        <Col>
          <Text color={theme.colors.gray200} align="left" mb="10px">
            {t("hideAnnex1")}
          </Text>
          <Radio
            name="hideAnnexed"
            label={t("yes")}
            value={true}
            checked={strategyData[Number(tabActiveKey) - 1]?.hideAnnexed === true}
            onChange={handleRadioStrategy}
          />
          <Radio
            name="hideAnnexed"
            label="No"
            value={false}
            checked={strategyData[Number(tabActiveKey) - 1]?.hideAnnexed === false}
            onChange={handleRadioStrategy}
          />
        </Col>
      </Row>
      <Row m="0 0 54.5px 0" gap="16px">
        <Button
          width="206px"
          height="45px"
          variant={"outlined"}
          onClick={handleDeleteStrategy}
        >
          {t("deleteStrategy")}
        </Button>
      </Row>
    </>
  );
};

Strategy.propTypes = {
  tabActiveKey: PropTypes.string,
  strategyData: PropTypes.array,
  setStrategyData: PropTypes.func,
  handleChangeNumberStrategy: PropTypes.func,
  handleChangeStrategy: PropTypes.func,
  handleRadioStrategy: PropTypes.func,
  handleDateStrategy: PropTypes.func,
  handleDeleteStrategy: PropTypes.func,
};
