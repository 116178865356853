import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { Div, Text } from "../../../../styles/Common";
import { Input } from "../../../../components/Input";
import { theme } from "../../../../styles/theme";
import search from "../../../../assets/icons/Search.svg";
//import filterIcon from "../../../../assets/icons/Filtrar.svg";
import { useTranslation } from "react-i18next";
//import { Button } from "../../../../components/Button";
import companyIcon from "../../../../assets/images/testImage.png";
import juliusLogo from "../../../../assets/images/logo_julius.png";
import eafitLogo from "../../../../assets/images/logo_eafit.png";
import eolicImg from "../../../../assets/images/eolicImage.png";
import solarImg from "../../../../assets/images/solar.png";
import useGetProjects from "../../hooks/useGetProjects";
import { Link } from "../../../../components/Link";
import {
  InitInvestorFilters,
  getUserInfo,
} from "../../../../utilities/helpers";
import { FilterDrawer } from "./filterDrawer";
import { FinancingProjects } from "./financingProjects";
import { SaleProjects } from "./saleProjects";
import useSetActivityLog from "../../../InvestorProjectDetail/hooks/useSetActivityLog";

export const InvestorProjectList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("projectsTabsView");
  const { id } = getUserInfo();
  const { projectType } = useSelector((state) => state?.common);
  const setLog = useSetActivityLog();
  const [searchValue, setSearchValue] = useState("");
  const [openFilters, setOpenFilters] = useState(false);
  const [totalCreated, setTotalCreated] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [fetchAction, setFetchAction] = useState(false);
  const [pageSize, setPageSize] = useState(5);

  const [payload, setPayload] = useState({
    instance: [],
    limit: pageSize,
    name: "",
    offset: 0,
    projectType: [],
    selectedTab: "created",
    groupBy: "invitations",
  });

  const getProjects = useGetProjects(payload);
  const [projectsData, setProjectsData] = useState([]);
  const { data: dataProjects, isLoading, isFetching } = getProjects;

  useEffect(() => {
    if (!isLoading && !isFetching && dataProjects?.data) {
      setProjectsData(dataProjects?.data?.data);
      setTotalCreated(dataProjects?.data?.totalCreated);
      setInitialLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProjects, isLoading, isFetching]);

  const [filtersOptions, setFilterOptions] = useState({
    "filters.type": [
      { label: "filters.solar", value: "solar", checked: false },
      { label: "filters.eolic", value: "eolic", checked: false },
    ],
    "filters.capacity": [
      { label: "- 10MW", value: "-10", checked: false },
      { label: "10MW - 50MW", value: "10-50", checked: false },
      { label: "+ 50MW", value: "+50", checked: false },
    ],
    "filters.countries": [
      { label: "España", value: "es", checked: false },
      { label: "Reino Unido", value: "uk", checked: false },
      { label: "Italia", value: "it", checked: false },
      { label: "USA", value: "us", checked: false },
    ],
    "filters.phase": [
      { label: "Pre-development", value: 1, checked: false },
      { label: "Development", value: 2, checked: false },
      { label: "RtB or Rtf Imminent", value: 3, checked: false },
      { label: "Construction", value: 4, checked: false },
      { label: "Operation", value: 5, checked: false },
    ],
    "filters.strategy": [
      { label: "Minority Equity Shareholding", value: 1, checked: false },
      { label: "Co-develpment", value: 2, checked: false },
      { label: "Assest Acquisition", value: 3, checked: false },
      { label: "Construction Bridge Debt - DI", value: 4, checked: false },
      { label: "Development Bridge Debt - DI", value: 5, checked: false },
    ],
  });

  useEffect(() => {
    setPayload({
      ...payload,
      limit: pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, fetchAction]);

  useEffect(() => {
    const instance = projectType === "debt" ? "financing" : "sale";
    setPayload({
      ...payload,
      instance: [instance],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectType]);

  const returnImg = (type) => {
    switch (type) {
      case "solar":
        return solarImg;
      case "eolic":
        return eolicImg;
      default:
        break;
    }
  };

  const handleFilterChange = (event, category, indx) => {
    const { id, checked } = event.target;
    if (category === "filters.type") {
      const updatedOptions = { ...filtersOptions };
      updatedOptions["filters.type"][indx].checked = checked;
      setFilterOptions({ ...updatedOptions });
      setPayload({ ...payload, projectType: [...payload.projectType, id] });
    }
  };

  const handleReFetch = () => {
    setPageSize(5);
    setFetchAction(!fetchAction);
  };

  const handleSaveFilters = () => {
    handleReFetch();
    setOpenFilters(false);
  };

  const cleanFilters = () => {
    setFilterOptions(InitInvestorFilters);
    handleReFetch();
    setOpenFilters(false);
  };

  const handleSearch = () => {
    handleReFetch();
  };

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleDetail = (projectId) => {
    const payload = {
      action: "visit-opportunity",
      projectId: projectId,
    };

    navigate(`/projects/investor-project-detail?projectId=${projectId}`);
    setLog.reset();
    setLog.mutate(payload);
  };

  /*   const openDrawer = () => {
    setOpenFilters(true);
  }; */

  const handleLoadProjects = () => {
    setPageSize(pageSize + 5);
  };

  if (initialLoading) {
    return (
      <Div width="100%" height="100%" justify="center" align="center">
        <ReactLoading color={theme.colors.green} />
      </Div>
    );
  }

  const logo = (userId) => {
    switch (userId) {
      case "6681754f87a2ec6deb7d997c":
        return eafitLogo;
      case "66817bda87a2ec6deb7d9986":
        return juliusLogo;
      default:
        return companyIcon;
    }
  };

  return (
    <>
      <Div height="min-content" justify="space-between" align="center">
        <FilterDrawer
          open={openFilters}
          onClose={() => setOpenFilters(false)}
          filtersOptions={filtersOptions}
          handleFilterChange={handleFilterChange}
          handleSaveFilters={handleSaveFilters}
          cleanFilters={cleanFilters}
        />
        <Div direction="column" gap="16px">
          <img alt="icon" src={logo(id)} width="264px" />
          <Text size={theme.fonts.size.h6} color={theme.colors.gray300}>
            {t("projectsSubtitle")}
          </Text>
        </Div>
        <Div gap="24px">
          <Input
            sizeLabel={theme.fonts.size.xs}
            suffix={
              <img
                alt="search"
                src={search}
                style={{ cursor: "pointer" }}
                onClick={handleSearch}
              />
            }
            width={"323px"}
            placeholder={`${t("common:search")}...`}
            style={{ borderRadius: "23px", marginTop: "-10px" }}
            mt={"-8px"}
            value={searchValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          {/* <Button width={"110px"} variant={"outlined"} onClick={openDrawer}>
            {t("filter")}
            <img alt="icon" src={filterIcon} style={{ marginLeft: "7px" }} />
          </Button> */}
        </Div>
      </Div>
      <Div direction="column" m="42px 0 52px 0" gap="26px">
        <Text
          color={theme.colors.blue}
          size={theme.fonts.size.h5}
          weight={theme.fonts.weight.medium}
        >
          {t("allProjects")}
        </Text>
        <Text color={theme.colors.gray300}>{t("projectsMessage")}</Text>
      </Div>
      {projectType === "debt" ? (
        <FinancingProjects
          projectsData={projectsData}
          returnImg={returnImg}
          handleDetail={handleDetail}
        />
      ) : (
        <SaleProjects
          projectsData={projectsData}
          returnImg={returnImg}
          handleDetail={handleDetail}
        />
      )}
      {payload.limit < totalCreated && (
        <Div
          width="100%"
          m="40px 0 0 0"
          justify="center"
          onClick={handleLoadProjects}
        >
          <Link>{t("loadProjects")}</Link>
        </Div>
      )}
    </>
  );
};
