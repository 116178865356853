/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Div, Text } from "../../../styles/Common";
import { Input } from "../../../components/Input";
import { theme } from "../../../styles/theme";
import { formatNumberOpex } from "../../../utilities/helpers";

const inputFields = [
  { key: "O&M", i18nKey: "opex.O&M", disabled: true },
  { key: "assetManagement", i18nKey: "opex.assetManagement", disabled: true },
  { key: "insurance", i18nKey: "opex.insurance", disabled: true },
  { key: "otherCosts", i18nKey: "opex.otherCosts", disabled: true },
  { key: "marketAgencyCost", i18nKey: "opex.marketAgencyCost", disabled: true },
  {
    key: "networkAccessCost",
    i18nKey: "opex.networkAccessCost",
    disabled: true,
  },
  { key: "comparableTaxes", i18nKey: "opex.comparableTaxes", disabled: true },
  { key: "costeRefuerzos", i18nKey: "opex.O&M", disabled: false },
  { key: "costeLinea", i18nKey: "opex.assetManagement", disabled: false },
  { key: "costeTotal", i18nKey: "opex.insurance", disabled: false },
  { key: "costeTotaltubinas", i18nKey: "opex.otherCosts", disabled: false },
  {
    key: "otrosCostesMarket",
    i18nKey: "opex.marketAgencyCost",
    disabled: false,
  },
  {
    key: "otrosCostesNetwork",
    i18nKey: "opex.networkAccessCost",
    disabled: false,
  },
  { key: "otrosCostesTaxes", i18nKey: "opex.comparableTaxes", disabled: false },
];

export const Opex = ({ opexInfo, setOpexInfo, handleChangeFormat }) => {
  const { t, i18n } = useTranslation("newProject");
  const locale = i18n.language;

  const renderInputs = useCallback(
    (isSelected) => {
      return inputFields
        .filter((field) => field.disabled === isSelected)
        .map((field) => (
          <Input
            key={field.key}
            sizeLabel={theme.fonts.size.default}
            weightLabel={theme.fonts.weight.regular}
            label={t(field.i18nKey)}
            id={field.key}
            value={opexInfo[field.key] === "" ? "" : formatNumberOpex(opexInfo[field.key], locale)}
            disabled={isSelected ? "true" : undefined}
            suffix={
              field.key === "otrosCostesNetwork" ||
              field.key === "otrosCostesMarket"
                ? "€ / MWh"
                : "€ / MW"
            }
            onChange={(e) => handleChangeFormat(e, opexInfo, setOpexInfo)}
            min={!isSelected ? "0" : undefined}
          />
        ));
    },
    [t, handleChangeFormat, opexInfo, setOpexInfo, locale]
  );

  return (
    <>
      <Text
        weight={theme.fonts.weight.medium}
        size={theme.fonts.size.h6}
        color={theme.colors.blue}
        mb="32px"
      >
        {t("opex.title").toUpperCase()}
      </Text>
      <Div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "16px",
        }}
      >
        {renderInputs(false)}
      </Div>
    </>
  );
};

Opex.propTypes = {
  opexInfo: PropTypes.array,
  setOpexInfo: PropTypes.func,
  handleChangeFormat: PropTypes.func,
};
