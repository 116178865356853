import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import { Card } from "../../../components/Card";
import { useTranslation } from "react-i18next";
import { Div, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { BackgroundImg, BackgroundImg2, ColorCard, GreyCard, MiniCard, MiniColorCard } from "../styles";
import back1 from "../../../assets/images/back1.png";
import back2 from "../../../assets/images/back2.png";

export const Content = ({
  source,
  generalCharacteristics,
  dates,
  originFunds,
  totals,
  financing,
  sizingParameters,
  refinancingHypothesisTime,
  refinancingHypothesis,
  sizingParameters2,
}) => {
  const { t } = useTranslation(["financialModel", "common"]);

  const titleProps = {
    color: theme.colors.blue,
    size: theme.fonts.size.h6,
    weight: theme.fonts.weight.medium
  };

  const labelsProps = {
    color: theme.colors.gray500,
    size: theme.fonts.size.sm,
    weight: theme.fonts.weight.medium
  };

  const valuesProps = {
    color: theme.colors.gray300,
    size: theme.fonts.size.sm,
  };

  return (
    <Row gutter={[16, 24]}>
      <Col md={9}>
        <Card
          shadow={"none"}
          padding={"17px 22px 10px 22px"}
        >
          <Text {...titleProps} mb="16px">
            {t("generalCharacteristics")}
          </Text>
          {generalCharacteristics.map((ele, indx) => (
            <Div key={indx} justify="space-between" m="0 0 12px 0">
              <Text {...labelsProps}>{ele.label}</Text>
              <Text {...valuesProps}>{ele.value}</Text>
            </Div>
          ))}
          <Text {...titleProps} mt="24px" mb="16px">
            {t("dates")}
          </Text>
          {dates.map((ele, indx) => (
            <Div key={indx} justify="space-between" m="0 0 12px 0">
              <Text {...labelsProps}>{ele.label}</Text>
              <Text {...valuesProps}>{ele.value}</Text>
            </Div>
          ))}
        </Card>
      </Col>
      <Col md={15}>
        <BackgroundImg>
          <img alt="back1" src={back1} width={"98%"} />
        </BackgroundImg>
      </Col>
      <Col md={9}>
        <Card
          shadow={"none"}
          padding={"18px 24px 8px 20px"}
        >
          <Text {...titleProps} mb="16px">
            {t("originFunds")}
          </Text>
          {originFunds.map((ele, indx) => (
            <Div key={indx} justify="space-between" m="0 0 12px 0">
              <Text {...labelsProps}>{ele.label}</Text>
              <Text {...valuesProps}>{ele.value}</Text>
            </Div>
          ))}
          <hr 
            style={{ 
              width: "100%", 
              border: `1px solid ${theme.colors.gray100}`,
              margin: "22px 0 23px 0"
            }} 
          />
          {totals.map((ele, indx) => (
            <Div key={indx} justify="space-between" m="0 0 12px 0">
              <Text 
                {...labelsProps} 
                color={indx === 0 ? theme.colors.green : theme.colors.gray500}
              >
                {ele.label}
              </Text>
              <Text {...valuesProps}>{ele.value}</Text>
            </Div>
          ))}
        </Card>
      </Col>
      <Col md={15}>
        <BackgroundImg2>
          <img alt="back2" src={back2} width={"98%"} />
        </BackgroundImg2>
      </Col>
      <Col md={24}>
        <Card
          shadow={"none"}
          padding={"32px 39px 34px 32px"}
          style={{ margin: source !== "page" ? "0 0 46px 0" : "0px" }}
        >
          <Row gutter={[25, 0]}>
            <Col md={12}>
              <Text {...titleProps} mb="24px">
                {t("financing")}
              </Text>
              <Row gutter={[18, 16]}>
                {financing.map((ele, indx) => (
                  <Col key={indx} md={12}>
                    <ColorCard style={{ background: ele.color }}>
                      <MiniCard>
                        {ele.icon}
                      </MiniCard>
                      <Div direction="column" gap="6px">
                        <Text 
                          weight={theme.fonts.weight.medium}
                          color={theme.colors.white}
                        >
                          {ele.label}
                        </Text>
                        <Text
                          color={theme.colors.white}
                          size={theme.fonts.size.sm}
                        >
                          {ele.value}
                        </Text>
                      </Div>
                    </ColorCard>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col md={12}>
              <Text {...titleProps} mb="24px">
                {t("sizingParameters")}
              </Text>
              <GreyCard>
                {sizingParameters.map((ele, indx) => (
                  <>
                    {indx < 4 &&                
                      <Div key={indx} justify="space-between" m="0 0 12px 0">
                        <Text {...labelsProps}>{ele.label}</Text>
                        <Text {...valuesProps}>{ele.value}</Text>
                      </Div>
                    }
                    {indx === 4 && 
                      <>
                        <hr style={{ width: "100%", border: `1px solid ${theme.colors.gray100}`, marginBottom: "12px" }} />
                        <Div key={indx} justify="space-between">
                          <Text {...labelsProps}>{ele.label}</Text>
                          <Text {...valuesProps}>{ele.value}</Text>
                        </Div>
                      </>
                    }
                  </>
                ))}
              </GreyCard>
            </Col>
          </Row>
        </Card>
      </Col>
      {source === "page" && (
        <>
          <Col md={24}>
            <Card
              shadow={"none"}
              padding={"28px 39px 35px 32px"}
              style={{ marginBottom: "57px" }}
            >
              <Row gutter={[21, 0]}>
                <Col md={14}>
                  <Text {...titleProps} mb="24px">
                    {t("refinancingHypothesis")}
                  </Text>
                  <Div gap="16px">
                    <Div direction="column" gap="7px">
                      {refinancingHypothesisTime.map((ele, indx) => (
                        <MiniColorCard key={indx} style={{ background: ele.color }}>
                          <Text
                            color={theme.colors.white}
                            size={theme.fonts.size.sm}
                          >
                            {ele.label}
                          </Text>
                          <Text
                            color={theme.colors.white}
                            size={theme.fonts.size.h5}
                            weight={theme.fonts.weight.semibold}
                          >
                            {ele.value}
                          </Text>
                        </MiniColorCard>
                      ))}
                    </Div>
                    <GreyCard style={{ width: "100%" }}>
                      {refinancingHypothesis.map((ele, indx) => (
                        <Div key={indx} justify="space-between" m="0 0 12px 0">
                          <Text {...labelsProps}>{ele.label}</Text>
                          <Text {...valuesProps}>{ele.value}</Text>
                        </Div>
                      ))}
                    </GreyCard>
                  </Div>
                </Col>
                <Col md={10}>
                  <Text {...titleProps} mb="24px">
                    {t("sizingParameters")}
                  </Text>
                  <GreyCard>
                    {sizingParameters2.map((ele, indx) => (
                      <Div key={indx} justify="space-between" m="0 0 12px 0">
                        <Text {...labelsProps}>{ele.label}</Text>
                        <Text {...valuesProps}>{ele.value}</Text>
                      </Div>
                    ))}
                  </GreyCard>
                </Col>
              </Row>
            </Card>
          </Col>
          <Div width="100%" justify="center">
            <Text size={theme.fonts.size.sm} color={theme.colors.gray300}>
              {t("common:poweredBy")}
            </Text>
          </Div>
        </>
      )}
    </Row>
  )
};

Content.propTypes = {
  source: PropTypes.string,
  generalCharacteristics: PropTypes.array,
  dates: PropTypes.array,
  originFunds: PropTypes.array,
  totals: PropTypes.array,
  financing: PropTypes.array,
  sizingParameters: PropTypes.array,
  refinancingHypothesisTime: PropTypes.array,
  refinancingHypothesis: PropTypes.array,
  sizingParameters2: PropTypes.array,
};
