import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Col, Row, Text } from "../../../../styles/Common";
import { Modal } from "../../../../components/Modal";
import { theme } from "../../../../styles/theme";
import { AlertIcon } from "../../../../assets/icons";
import { Button } from "../../../../components/Button";

const ModalTerms = ({ showModal, handleCloseModal, handleTerms }) => {
  const { t } = useTranslation("investorProjectDetail");

  return (
    <Modal open={showModal} onCancel={handleCloseModal}>
      <Modal.Header
        icon={
          <AlertIcon width={"50px"} height={"50px"} fill={theme.colors.green} />
        }
        iconBackground={theme.colors.green100}
        iconWidth={"90px"}
        iconHeight={"90px"}
        align="-webkit-center"
        padding={"22px 50px 0px 50px"}
        title={t("information")}
        weightTitle={theme.fonts.weight.semibold}
      />
      <Modal.Body
        margin="28px 0 32px 0"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col width="100%" height="100%" align="center" justify="center">
          <Row width="100%" m="0 0 32px 0">
            <Text size={theme.fonts.size.default} color={theme.colors.gray500}>
              {t("access")}
            </Text>
          </Row>
          <Button
            width="281px"
            height="45px"
            m="0 0 3px 0"
            onClick={handleTerms}
          >
            <Text
              weight={theme.fonts.weight.medium}
              color={theme.colors.white}
              size={theme.fonts.size.sm}
            >
              {t("terms")}
            </Text>
          </Button>
          <Button
            width="281px"
            height="45px"
            hBackground={theme.colors.white}
            background={theme.colors.white}
            onClick={handleCloseModal}
          >
            <Text
              weight={theme.fonts.weight.medium}
              color={theme.colors.green}
              size={theme.fonts.size.sm}
            >
              {t("cancel")}
            </Text>
          </Button>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

ModalTerms.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleTerms: PropTypes.func,
};

export default ModalTerms;
