import React from "react";
import PropTypes from "prop-types";
import { Row, Text, Col } from "../../../../styles/Common";
import { theme } from "../../../../styles/theme";
import { Input } from "../../../../components/Input";
import { useTranslation } from "react-i18next";
import { Radio } from "../../../../components/Radio";

export const PriceConsideration = ({
  strategyData,
  setStrategyData,
  handleChangeNumber,
  handleChange,
  tabActiveKey,
}) => {
  const { t } = useTranslation("teaserDocumentation");

  const handleRadio = (event) => {
    const { value, name } = event.target;
    setStrategyData((prevItems) => {
      const updatedItems = [...prevItems];
      if (name === "targetPricePerMWpChecked") {
        updatedItems[Number(tabActiveKey) - 1].pricePerMWpChecked = false;
        updatedItems[Number(tabActiveKey) - 1].pricePerMWpValue = null;
        updatedItems[Number(tabActiveKey) - 1].negotiablePrice = false;
      } else if (name === "pricePerMWpChecked") {
        updatedItems[Number(tabActiveKey) - 1].targetPricePerMWpChecked = false;
        updatedItems[Number(tabActiveKey) - 1].targetPricePerMWpValue = null;
        updatedItems[Number(tabActiveKey) - 1].negotiablePrice = false;
      } else {
        updatedItems[Number(tabActiveKey) - 1].targetPricePerMWpChecked = false;
        updatedItems[Number(tabActiveKey) - 1].targetPricePerMWpValue = null;
        updatedItems[Number(tabActiveKey) - 1].pricePerMWpChecked = false;
        updatedItems[Number(tabActiveKey) - 1].pricePerMWpValue = null;
        updatedItems[Number(tabActiveKey) - 1].pricePerMWpTo = null;
      }
      updatedItems[Number(tabActiveKey) - 1][name] = value;
      return updatedItems;
    });
  };

  return (
    <>
      <Row p="0 100px 0 0 " m=" 33.5px 0 24px 0" gap="49px">
        <Col>
          <Radio
            label={t("pricePerMwp")}
            name="targetPricePerMWpChecked"
            value={true}
            checked={
              strategyData[Number(tabActiveKey) - 1].targetPricePerMWpChecked
            }
            colorLabel={theme.colors.gray200}
            onChange={handleRadio}
          ></Radio>
          <Input
            id="targetPricePerMWpValue"
            type="number"
            width="310px"
            suffix="€/Mwp"
            colorPlaceholder={theme.colors.gray200}
            value={
              strategyData[Number(tabActiveKey) - 1].targetPricePerMWpValue
            }
            onChange={(event) =>
              handleChangeNumber(event, strategyData, setStrategyData)
            }
            disabled={
              !strategyData[Number(tabActiveKey) - 1].targetPricePerMWpChecked
            }
          />
        </Col>

        <Col>
          <Row>
            <Radio
              label={t("priceMwp")}
              name="pricePerMWpChecked"
              value={true}
              checked={
                strategyData[Number(tabActiveKey) - 1].pricePerMWpChecked
              }
              colorLabel={theme.colors.gray200}
              onChange={handleRadio}
            />
          </Row>
          <Row align="center">
            <Input
              id="pricePerMWpValue"
              type="number"
              width="149px"
              suffix="MWp"
              value={strategyData[Number(tabActiveKey) - 1].pricePerMWpValue}
              onChange={(event) =>
                handleChangeNumber(event, strategyData, setStrategyData)
              }
              disabled={
                !strategyData[Number(tabActiveKey) - 1].pricePerMWpChecked
              }
            />
            <Text>-</Text>
            <Input
              id="pricePerMWpTo"
              type="number"
              width="149px"
              suffix="MWp"
              value={strategyData[Number(tabActiveKey) - 1].pricePerMWpTo}
              onChange={(event) =>
                handleChangeNumber(event, strategyData, setStrategyData)
              }
              disabled={
                !strategyData[Number(tabActiveKey) - 1].pricePerMWpChecked
              }
            />
          </Row>
        </Col>

        <Col>
          <Radio
            label={t("negotiable")}
            name="negotiablePrice"
            value={true}
            checked={strategyData[Number(tabActiveKey) - 1]?.negotiablePrice}
            colorLabel={theme.colors.gray200}
            onChange={handleRadio}
          ></Radio>
        </Col>
      </Row>
      <Row>
        <Input
          id="priceConsiderations"
          label={t("priceConsideration")}
          width="100%"
          height="138px"
          value={strategyData[Number(tabActiveKey) - 1].priceConsiderations}
          onChange={(event) =>
            handleChange(event, strategyData, setStrategyData)
          }
        />
      </Row>
    </>
  );
};

PriceConsideration.propTypes = {
  strategyData: PropTypes.array,
  setStrategyData: PropTypes.func,
  handleChange: PropTypes.func,
  handleChangeNumber: PropTypes.func,
  handleRadio: PropTypes.func,
  tabActiveKey: PropTypes.string,
};
