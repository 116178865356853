import React from "react";
import PropTypes from "prop-types";
import Drawer from "../../../components/Drawer";
import { useTranslation } from "react-i18next";
import { Div, Text } from "../../../styles/Common";
import { Button } from "../../../components/Button";
import { Link } from "../../../components/Link";
import { theme } from "../../../styles/theme";
import { TransparentScroll } from "./styles";
import { Row, Col } from "antd";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select2";
import { TextArea } from "../../../components/TextArea";
import { InversionTypeOptions, InversionTypeSaleOptions, VerticalOptions } from "../../../utilities/strategiesOptions";
import CheckboxGroup from "../../../components/Checkbox/Group";
import Checkbox from "../../../components/Checkbox";
import { Radio } from "../../../components/Radio2";

export const DrawerNewStrategy = ({ 
  open, 
  handleClose, 
  form,
  assetsOptions,
  countriesOptions,
  handleChange,
  handleSelect,
  handleRadio,
  handleCheckAssets,
  handleCheckCountries,
  handleCreate,
  type 
}) => {
  const { t } = useTranslation("strategies");

  const disableButton = form.name !== "" && form.typeOfInvestment !== null && form.description !== "" && form.vertical !== null;

  return (
    <Drawer
      open={open} 
      title={t("newStrategy")} 
      onClose={handleClose}
      width={"332px"}
      padding={"0px 36px 24px 36px"}
    >
      <Div direction="column" height="100%" justify="space-between">
        <TransparentScroll>
          <Row gutter={[24, 24]}>
            <Col md={24}>
              <Input
                id="name"
                label={`${t("strategyName")}*`}
                value={form.name}
                onChange={handleChange}
              />
            </Col>
            <Col md={24}>
              <Select
                label={`${t("inversionType")}*`}
                placeholder={t("select")}
                options={type === 2 ? InversionTypeSaleOptions : InversionTypeOptions}
                width="100%"
                value={form.typeOfInvestment}
                onChange={(e) => handleSelect(e, "typeOfInvestment")}
                arrowColor={theme.colors.gray200}
              />
            </Col>
            <Col md={24}>
              <TextArea
                id="description"
                label={`${t("description")}*`}
                value={form.description}
                rows={6}
                onChange={handleChange}
              />
            </Col>
            {type === 1 && (
              <Col md={24}>
                <Text size={theme.fonts.size.sm} color={theme.colors.gray200} align="left" mb="10px">
                  {t("financingFormat")}
                </Text>
                <Radio.Group name="investmentInstrument" gap="48px" onChange={handleRadio} value={form.investmentInstrument}>
                  <Radio label="LOAN" value={1}></Radio>
                  <Radio label="ESI BOND" value={2}></Radio>
                </Radio.Group>
              </Col>
            )}
            <Col md={24}>
              <Select
                label={`${t("vertical")}*`}
                placeholder={t("select")}
                options={VerticalOptions}
                width="100%"
                value={form.vertical}
                onChange={(e) => handleSelect(e, "vertical")}
                arrowColor={theme.colors.gray200}
              />
            </Col>
          </Row>
          <hr style={{ width: "100%", border: `1px solid ${theme.colors.gray100}`, margin: "24px 0 18px 0" }} />
          <Div direction="column" gap="12px">
            <Text
              color={theme.colors.blue}
              size={theme.fonts.size.h5i}
              weight={theme.fonts.weight.medium}
            >
              {t("assets")}
            </Text>
            <CheckboxGroup direction="column">
              {assetsOptions.map((opt, index) => (
                <Checkbox 
                  key={index} 
                  id={opt.value}
                  label={`${t(opt.label)}`} 
                  checked={opt.checked}
                  onChange={(e) => handleCheckAssets(e, index)}
                />
              ))}
            </CheckboxGroup>
          </Div>
          <hr style={{ width: "100%", border: `1px solid ${theme.colors.gray100}`, margin: "24px 0 32px 0" }} />
          <Div direction="column" gap="12px">
            <Text
              color={theme.colors.blue}
              size={theme.fonts.size.h5i}
              weight={theme.fonts.weight.medium}
            >
              {t("countries")}
            </Text>
            <CheckboxGroup direction="column">
              {countriesOptions.map((opt, index) => (
                <Checkbox 
                  key={index} 
                  id={opt.value}
                  label={`${t(opt.label)}`} 
                  checked={opt.checked}
                  onChange={(e) => handleCheckCountries(e, index)}
                />
              ))}
            </CheckboxGroup>
          </Div>
        </TransparentScroll>
        <Div direction="column" align="center" justify="center" gap="24px">
          <Button
            width={"169px"}
            disabled={!disableButton}
            onClick={handleCreate}
          >
            {t("createStrategy")}
          </Button>
          <Link onClick={handleClose}>{t("cancel")}</Link>
        </Div>
      </Div>
    </Drawer>
  )
};

DrawerNewStrategy.propTypes = {
  open: PropTypes.bool, 
  handleClose: PropTypes.func, 
  form: PropTypes.object, 
  handleChange: PropTypes.func,
  handleSelect: PropTypes.func,
  handleRadio: PropTypes.func,
  handleCheckAssets: PropTypes.func, 
  handleCheckCountries: PropTypes.func, 
  assetsOptions: PropTypes.array,
  countriesOptions: PropTypes.array,
  handleCreate: PropTypes.func,
  type: PropTypes.number,
};
