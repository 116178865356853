import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { ModuleContainer } from "../../styles/Common";
import { Card } from "../../components/Card";
import { Row, Text, Div } from "../../styles/Common";
import { ArrowLeftIcon, WindyFillIcon, SunIcon } from "../../assets/icons";
import { theme } from "../../styles/theme";
import { Button } from "../../components/Button";
import { SummaryProject } from "./components/SummaryProject";
import { InfoDetailInvestor } from "./components/InfoDetailInvestor";
import { DocumentationInvestor } from "./components/DocumentationInvestor";
import { useTranslation } from "react-i18next";
import useGetProjectInfo from "../../core/hooks/useGetProject";
import { ProjectQuestions } from "./components/Questions/ProjectQuestions";
import useAcceptTyC from "./hooks/useAcceptTyC";
import ModalTerms from "./components/ModalTerms";
import useGetTyC from "./hooks/useGetTyC";
import { IndicativeTermSheet } from "./components/DebtProject/IndicativeTermsheet";
import { ExecutionDocs } from "./components/DebtProject/ExecutionDocs";
import { FinancialDashboard } from "./components/DebtProject/FinancialDashboard";
import { DrawerIndicativeOffer } from "./components/DrawerIndicativeOffer";
import { dateFormat } from "../../utilities/helpers";
import useSetActivityLog from "./hooks/useSetActivityLog";

export const InvestorProjectDetail = () => {
  const { t } = useTranslation(["investorProjectDetail"]);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const setLog = useSetActivityLog();
  const acceptTerm = useAcceptTyC();
  const { projectType } = useSelector((state) => state.common);

  const { search } = useLocation();

  const projectId = new URLSearchParams(search).get("projectId");

  const { data: dataTerms } = useGetTyC(projectId);

  const [showModal, setShowModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const projectDataInfo = useGetProjectInfo(projectId);
  const { data: dataProject, isSuccess: isSuccessDataProject } =
    projectDataInfo;
  const [project, setProject] = useState();
  const [debt, setDebt] = useState(true); // Estado para debt

  useEffect(() => {
    setDebt(projectType === "debt");
  }, [projectType]);

  useEffect(() => {
    if (isSuccessDataProject && dataProject) {
      const info = dataProject?.data?.result?.data;

      setProject({
        id: info?._id,
        name: info?.teaserName,
        projectType: info?.projectType?.type?.name,
        financingType: info?.projectType?.kind?.name,
        objective: info?.projectType?.instance.name,
        projectTotalMW: info?.projectTotalMW,
        plants: info?.plants,
        sponsor: info?.sponsor,
        debt: true,
        teaserProjectSize: info.teaserProjectSize,
        teaserProjectStatus: info.teaserProjectStatus,
        teaserTargetPrice: info.teaserTargetPrice,
        teasercloseDate: info.teasercloseDate,
        isTermSheet: info.isTermSheet,
        teaserId: info.teaserId
      });
    }
  }, [dataProject, isSuccessDataProject]);

  const menuTabs = debt
    ? [
        { title: t("summary") },
        { title: t("financialDashboard") },
        { title: "Q&A" },
        { title: t("documentation") },
        { title: t("executionDocs") },
      ]
    : [
        { title: t("summary") },
        { title: t("information") },
        { title: "Q&A" },
        { title: t("documentation") },
      ];

  if (project?.isTermSheet) {
    menuTabs.splice(1, 0, { title: t("indicativeTermSheet") });
  }

  const [selectedMenu, setSelectedMenu] = useState(0);

  const handleBack = () => {
    navigate("/projects");
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  const handleMenu = (index) => {
    if (debt && index === 3) {
      const payload = {
        action: "access-data-room",
        projectId: projectId,
      };

      setLog.reset();
      setLog.mutate(payload);
      setSelectedMenu(index);
    }

    if (index === 2) {
      const payload = {
        action: "access-qa",
        projectId: projectId,
      };

      setLog.reset();
      setLog.mutate(payload);
      setSelectedMenu(index);
    }

    if ((!debt && index === 3) || (debt && index === 4)) {
      if (!dataTerms?.data?.data?.isApproved) {
        setShowModal(true);
      } else {
        const payload = {
          action: "access-data-room",
          projectId: projectId,
        };

        setLog.reset();
        setLog.mutate(payload);
        setSelectedMenu(index);
      }
    } else {
      setSelectedMenu(index);
    }
  };

  const handleTerms = () => {
    const formData = {
      projectId: projectId,
      isActive: true,
    };
    acceptTerm.reset();
    acceptTerm.mutate(formData, {
      onSuccess: () => {
        setShowModal(false);
        queryClient.invalidateQueries("getTermsAndConditions");
        toast.success("Términos y condiciones enviados por correo electrónico");
      },
      onError: (err) => {
        err.response.data.result.code.message.map((error) =>
          toast.error(t(`:${error}`))
        );
      },
    });
  };

  const handleIndicativeOffer = () => {
    setOpenDrawer(true);
    const payload = {
      action: "access-indicative-offer",
      projectId: projectId,
    };

    setLog.reset();
    setLog.mutate(payload);
  };

  const renderMenuComponents = () => {
    const components = [
      <SummaryProject
        key={1}
        projectInfo={project}
        debt={debt}
        termsApproved={dataTerms?.data?.data?.isApproved}
        setShowModal={setShowModal}
      />,
      project?.isTermSheet && (
        <IndicativeTermSheet key={2} projectId={projectId} />
      ),
      <FinancialDashboard key={3} projectId={projectId} />,
      <ProjectQuestions key={4} projectId={projectId} />,
      <DocumentationInvestor key={5} projectId={projectId} />,
      <ExecutionDocs key={6} projectId={projectId} />,
    ];

    return components.filter(Boolean)[selectedMenu];
  };

  return (
    <ModuleContainer direction="column" padding="32px 69px 0px 32px">
      <DrawerIndicativeOffer
        openDrawer={openDrawer}
        handleClose={handleCloseDrawer}
        projectId={projectId}
      />
      <ModalTerms
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleTerms={handleTerms}
      />
      <Card
        width="100%"
        height="216px"
        background={theme.colors.blue}
        padding="31px 29px 28px 32px"
        margin="0 0 41px 0"
      >
        <Row
          align="center"
          m="0 0 12px 0"
          style={{ cursor: "pointer" }}
          gap="6px"
          onClick={handleBack}
        >
          <ArrowLeftIcon />
          <Text weight={theme.fonts.weight.medium} color={theme.colors.green}>
            {t("goBack")}
          </Text>
        </Row>
        <Row align="center" justify="space-between" m="0 0 4px 0">
          <Text
            weight={theme.fonts.weight.medium}
            color={theme.colors.white}
            size={theme.fonts.size.h4}
          >
            {project?.name}
          </Text>
          <Button width="222px" height="45px">
            <Text
              weight={theme.fonts.weight.medium}
              color={theme.colors.white}
              size={theme.fonts.size.sm}
              onClick={handleIndicativeOffer}
            >
              {t("submit")}
            </Text>
          </Button>
        </Row>
        <Row m="0 0 12px 0">
          <Text color={theme.colors.yellow100}>pending</Text>
        </Row>
        <Row justify="space-between">
          <Div
            width="auto"
            height="40px"
            background={`${theme.colors.white}26`}
            radius="20px"
            align="center"
            justify="center"
            p="8px 12px"
          >
            {project?.projectType === "solar" ? (
              <SunIcon fill={theme.colors.white} width="24px" height="24px" />
            ) : (
              <WindyFillIcon
                fill={theme.colors.white}
                width="24px"
                height="24px"
              />
            )}
            <Text color={theme.colors.white} size={theme.fonts.size.sm}>
              {t(project?.projectType)}
            </Text>
          </Div>
          <Div
            width="auto"
            height="40px"
            background={`${theme.colors.white}26`}
            radius="20px"
            align="center"
            justify="center"
            p="8px 12px"
          >
            <Text color={theme.colors.white} size={theme.fonts.size.sm}>
              {" "}
              {`${t("size")}: ${project?.teaserProjectSize || "-"} MWp`}
            </Text>
          </Div>
          <Div
            width="auto"
            height="40px"
            background={`${theme.colors.white}26`}
            radius="20px"
            align="center"
            justify="center"
            p="8px 12px"
          >
            <Text color={theme.colors.white} size={theme.fonts.size.sm}>
              {" "}
              {debt
                ? `${t("targetDebt")}: € ${project?.teaserInvestment || "-"}`
                : `${t("price")}: € ${project?.teaserTargetPrice || "-"}`}
            </Text>
          </Div>
          <Div
            width="auto"
            height="40px"
            background={`${theme.colors.white}26`}
            radius="20px"
            align="center"
            justify="center"
            p="8px 12px"
          >
            <Text color={theme.colors.white} size={theme.fonts.size.sm}>
              {" "}
              {`${t("closing")}: ${
                project?.teasercloseDate
                  ? dateFormat(project?.teasercloseDate)
                  : "-"
              }`}
            </Text>
          </Div>
          <Div
            width="auto"
            height="40px"
            background={`${theme.colors.white}26`}
            radius="20px"
            align="center"
            justify="center"
            p="8px 12px"
          >
            <Text color={theme.colors.white} size={theme.fonts.size.sm}>
              {debt
                ? `${t("term")} ${project?.teaserTargetTenor || "-"}`
                : `${t("phase")} ${project?.teaserProjectStatus || "-"}`}
            </Text>
          </Div>
        </Row>
      </Card>
      <Row m=" 0 0 34px 0">
        {menuTabs.map((tab, index) => (
          <Div
            key={index}
            height="40px"
            p="0 20px 0 20px"
            justify="space-between"
            background={theme.colors.green100}
            direction="column"
            style={{
              cursor: "pointer",
            }}
            onClick={() => handleMenu(index)}
          >
            <Text
              width="164px"
              weight={theme.fonts.weight.regular}
              size={theme.fonts.size.default}
              color={
                selectedMenu === index
                  ? theme.colors.green
                  : theme.colors.gray200
              }
              minheight="53px"
              align="left"
            >
              {tab.title}
            </Text>
            {selectedMenu === index && (
              <Div background={theme.colors.green} height="2px" width="100%" />
            )}
          </Div>
        ))}
      </Row>

      {!debt && selectedMenu === 0 && (
        <SummaryProject
          projectInfo={project}
          termsApproved={dataTerms?.data?.data?.isApproved}
          setShowModal={setShowModal}
        />
      )}
      {!debt && selectedMenu === 1 && (
        <InfoDetailInvestor
          projectInfo={project}
          termsApproved={dataTerms?.data?.data?.isApproved}
          setShowModal={setShowModal}
        />
      )}
      {!debt && selectedMenu === 2 && (
        <ProjectQuestions projectId={projectId} />
      )}
      {!debt && selectedMenu === 3 && (
        <DocumentationInvestor projectId={projectId} />
      )}
      {debt && renderMenuComponents()}
      {/* 
      {debt && selectedMenu === 0 && <SummaryProject projectInfo={project} debt={debt} />}
      {debt && selectedMenu === 1 && <IndicativeTermSheet projectId={projectId} />}
      {debt && selectedMenu === 2 && <FinancialDashboard projectId={projectId} />}
      {debt && selectedMenu === 3 && <ProjectQuestions projectId={projectId} />}
      {debt && selectedMenu === 4 && <DocumentationInvestor projectId={projectId} />}
      {debt && selectedMenu === 5 && <ExecutionDocs projectId={projectId} />} */}

      <Text
        size={theme.fonts.size.sm}
        color={theme.colors.gray300}
        align="center"
        mb="12px"
      >
        {t("alter")}
      </Text>
    </ModuleContainer>
  );
};
