import React from "react";
import PropTypes from "prop-types";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select2";
import { useTranslation } from "react-i18next";
import { LandsOptions } from "../../../utilities/plantsOptions";
import { Col } from "antd";
import mwn from "../../../assets/icons/mwn.svg";

export const CommonFields = ({
  projectType,
  financingType,
  plantsInfo,
  mwp,
  years,
  anual,
  ha,
  handleChange,
  handleSelect,
  handleChangeNumber,
  formError,
  provincesOptions,
  municipalityOptions,
}) => {
  const { t } = useTranslation("newPlant");
  const productionP50 =
    projectType === "solar" && financingType === "construction";
  const lands =
    plantsInfo.estadoTerrenoPlanta === 2 ||
    plantsInfo.estadoTerrenoPlanta === 3;

  return (
    <>
      <Col md={8}>
        <Input
          id={"name"}
          label={t("plantName")}
          value={plantsInfo.name}
          onChange={handleChange}
          error={formError.name.error}
          helper={formError.name.error ? formError.name.message : ""}
        />
      </Col>
      <Col md={8}>
        <Select
          id={"location"}
          label={t("province")}
          options={provincesOptions}
          placeholder={`${t("choose")}...`}
          width={"100%"}
          value={plantsInfo.location}
          onChange={(event) => handleSelect(event, "location")}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          showSearch
        />
      </Col>
      <Col md={8}>
        <Select
          id={"municipio"}
          label={t("municipality")}
          options={municipalityOptions}
          placeholder={`${t("choose")}...`}
          width={"100%"}
          value={plantsInfo.municipio}
          onChange={(event) => handleSelect(event, "municipio")}
          disabled={plantsInfo.location === null}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          showSearch
        />
      </Col>
      <Col md={8}>
        <Input
          id={"potenciaPico"}
          label={t("peakPower")}
          suffix={<img alt="mwp" src={mwp} />}
          value={plantsInfo.potenciaPico}
          onChange={handleChangeNumber}
          error={formError.potenciaPico.error}
          helper={
            formError.potenciaPico.error ? formError.potenciaPico.message : ""
          }
        />
      </Col>
      <Col md={8}>
        <Input
          id="potenciaNominal"
          label={t("ratedPower")}
          suffix={<img alt="mwn" src={mwn} />}
          value={plantsInfo.potenciaNominal}
          onChange={handleChangeNumber}
        />
      </Col>
      {productionP50 && (
        <Col md={8}>
          <Input
            id={"horasEquivalentes"}
            label={t("productionP50")}
            value={plantsInfo.horasEquivalentes}
            onChange={handleChangeNumber}
            error={formError.horasEquivalentes.error}
            helper={
              formError.horasEquivalentes.error
                ? formError.horasEquivalentes.message
                : ""
            }
          />
        </Col>
      )}
      <Col md={8}>
        <Input
          id={"horasEquivalentes90"}
          label={t("productionP90")}
          value={plantsInfo.horasEquivalentes90}
          onChange={handleChangeNumber}
          error={formError.horasEquivalentes90.error}
          helper={
            formError.horasEquivalentes90.error
              ? formError.horasEquivalentes90.message
              : ""
          }
        />
      </Col>
      {productionP50 && (
        <>
          <Col md={8}></Col>
          <Col md={8}></Col>
        </>
      )}
      <Col md={8}>
        <Select
          label={t("land")}
          placeholder={`${t("choose")}...`}
          width={"100%"}
          options={LandsOptions}
          value={plantsInfo.estadoTerrenoPlanta}
          onChange={(event) => handleSelect(event, "estadoTerrenoPlanta")}
        />
      </Col>
      {lands && (
        <>
          <Col md={8}>
            <Input
              id={"plazoContratoArrendamiento"}
              label={t("leaseTerm")}
              suffix={<img alt="years" src={years} />}
              value={plantsInfo.plazoContratoArrendamiento}
              onChange={handleChangeNumber}
            />
          </Col>
          <Col md={8}>
            <Input
              id={"costeArrendamiento"}
              label={t("annualLeaseCost")}
              suffix={<img alt="anual" src={anual} />}
              value={plantsInfo.costeArrendamiento}
              onChange={handleChangeNumber}
            />
          </Col>
        </>
      )}
      <Col md={8}>
        <Input
          id={"superficie"}
          label={t("plantSurface")}
          suffix={<img alt="ha" src={ha} />}
          value={plantsInfo.superficie}
          onChange={handleChangeNumber}
        />
      </Col>
    </>
  );
};

CommonFields.propTypes = {
  plantsInfo: PropTypes.object,
  projectType: PropTypes.string,
  financingType: PropTypes.string,
  mwp: PropTypes.string,
  years: PropTypes.string,
  anual: PropTypes.string,
  ha: PropTypes.string,
  m: PropTypes.string,
  kv: PropTypes.string,
  handleChange: PropTypes.func,
  handleSelect: PropTypes.func,
  handleDate: PropTypes.func,
  handleRadio: PropTypes.func,
  handleChangeNumber: PropTypes.func,
  formError: PropTypes.object,
  provincesOptions: PropTypes.array,
  municipalityOptions: PropTypes.array,
};
