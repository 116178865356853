import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Div, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { useTranslation } from "react-i18next";
import { List, Notification, SearchIcon } from "../../../assets/icons";
import { Input } from "../../../components/Input";
import { Link } from "../../../components/Link";
import filterIcon from "../../../assets/icons/Filtrar.svg";
import { Button } from "../../../components/Button";
import { TableContent } from "./TableContent";

export const TabsContent = ({ source, projectsInfo }) => {
  const { t } = useTranslation(["investorActivity", "common"]);
  const [pages, setPages] = useState({ pageNumber: 1, pageSize: 10 });
  const [totalItems] = useState(10);
  const [search, setSearch] = useState("");
  const [projectsData, setProjectsData] = useState([]);

  const cardsInfo = [
    {
      title: t("totalInvitations"),
      quantity: projectsInfo.totalProjectInvitations,
      color: theme.colors.blue,
    },
    {
      title: t("discardedProjects"),
      quantity: projectsInfo.discardedProjects,
      color: theme.colors.green,
    },
    {
      title: t("closedOperations"),
      quantity: projectsInfo.closedOperations,
      color: theme.colors.gray300,
    },
  ];

  const data = [
    {
      projectName: "Castellana norte",
      teaserAccessed: "Si",
      tearmsheetAccessed: "Si",
      modelDownloaded: "No",
      comments: "Si",
      signNDA: "Si",
      dataRoomAccess: "Si",
      dueDiligenciesAccess: "Si",
      lastReminderDate: "12/03/2024",
      lastActionDate: "12/03/2024",
      teaserDownloaded: "Si",
      tcRequest: "No",
      signTC: "Si",
      nboSended: 2,
      questions: 14,
    },
    {
      projectName: "Mérida",
      teaserAccessed: "No",
      tearmsheetAccessed: "No",
      modelDownloaded: "Si",
      comments: "No",
      signNDA: "No",
      dataRoomAccess: "No",
      dueDiligenciesAccess: "No",
      lastReminderDate: "12/03/2024",
      lastActionDate: "12/03/2024",
      teaserDownloaded: "No",
      tcRequest: "Si",
      signTC: "No",
      nboSended: 0,
      questions: 4,
    },
    {
      projectName: "Castellana norte",
      teaserAccessed: "Si",
      tearmsheetAccessed: "Si",
      modelDownloaded: "No",
      comments: "Si",
      signNDA: "Si",
      dataRoomAccess: "Si",
      dueDiligenciesAccess: "Si",
      lastReminderDate: "12/03/2024",
      lastActionDate: "12/03/2024",
      teaserDownloaded: "Si",
      tcRequest: "No",
      signTC: "Si",
      nboSended: 2,
      questions: 14,
    },
    {
      projectName: "Mérida",
      teaserAccessed: "No",
      tearmsheetAccessed: "No",
      modelDownloaded: "Si",
      comments: "No",
      signNDA: "No",
      dataRoomAccess: "No",
      dueDiligenciesAccess: "No",
      lastReminderDate: "12/03/2024",
      lastActionDate: "12/03/2024",
      teaserDownloaded: "No",
      tcRequest: "Si",
      signTC: "No",
      nboSended: 0,
      questions: 4,
    },
    {
      projectName: "Castellana norte",
      teaserAccessed: "Si",
      tearmsheetAccessed: "Si",
      modelDownloaded: "No",
      comments: "Si",
      signNDA: "Si",
      dataRoomAccess: "Si",
      dueDiligenciesAccess: "Si",
      lastReminderDate: "12/03/2024",
      lastActionDate: "12/03/2024",
      teaserDownloaded: "Si",
      tcRequest: "No",
      signTC: "Si",
      nboSended: 2,
      questions: 14,
    },
    {
      projectName: "Mérida",
      teaserAccessed: "No",
      tearmsheetAccessed: "No",
      modelDownloaded: "Si",
      comments: "No",
      signNDA: "No",
      dataRoomAccess: "No",
      dueDiligenciesAccess: "No",
      lastReminderDate: "12/03/2024",
      lastActionDate: "12/03/2024",
      teaserDownloaded: "No",
      tcRequest: "Si",
      signTC: "No",
      nboSended: 0,
      questions: 4,
    },
    {
      projectName: "Castellana norte",
      teaserAccessed: "Si",
      tearmsheetAccessed: "Si",
      modelDownloaded: "No",
      comments: "Si",
      signNDA: "Si",
      dataRoomAccess: "Si",
      dueDiligenciesAccess: "Si",
      lastReminderDate: "12/03/2024",
      lastActionDate: "12/03/2024",
      teaserDownloaded: "Si",
      tcRequest: "No",
      signTC: "Si",
      nboSended: 2,
      questions: 14,
    },
    {
      projectName: "Mérida",
      teaserAccessed: "No",
      tearmsheetAccessed: "No",
      modelDownloaded: "Si",
      comments: "No",
      signNDA: "No",
      dataRoomAccess: "No",
      dueDiligenciesAccess: "No",
      lastReminderDate: "12/03/2024",
      lastActionDate: "12/03/2024",
      teaserDownloaded: "No",
      tcRequest: "Si",
      signTC: "No",
      nboSended: 0,
      questions: 4,
    },
    {
      projectName: "Castellana norte",
      teaserAccessed: "Si",
      tearmsheetAccessed: "Si",
      modelDownloaded: "No",
      comments: "Si",
      signNDA: "Si",
      dataRoomAccess: "Si",
      dueDiligenciesAccess: "Si",
      lastReminderDate: "12/03/2024",
      lastActionDate: "12/03/2024",
      teaserDownloaded: "Si",
      tcRequest: "No",
      signTC: "Si",
      nboSended: 2,
      questions: 14,
    },
    {
      projectName: "Mérida",
      teaserAccessed: "No",
      tearmsheetAccessed: "No",
      modelDownloaded: "Si",
      comments: "No",
      signNDA: "No",
      dataRoomAccess: "No",
      dueDiligenciesAccess: "No",
      lastReminderDate: "12/03/2024",
      lastActionDate: "12/03/2024",
      teaserDownloaded: "No",
      tcRequest: "Si",
      signTC: "No",
      nboSended: 0,
      questions: 4,
    },
  ];

  useEffect(() => {
    setProjectsData(
      data.map((ele, i) => ({
        key: i,
        ...ele,
        actions: [
          {
            id: ele._id,
            name: ele.name,
            tooltip: t("sendReminder"),
            icon: (
              <Notification
                width="32px"
                height="32px"
                fill={theme.colors.green}
              />
            ),
          },
          {
            id: ele._id,
            name: ele.name,
            tooltip: t("projectActivity"),
            icon: <List width="24px" height="24px" fill={theme.colors.green} />,
          },
        ],
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChangeTable = (pagination) => {
    setPages({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  return (
    <Div direction="column" width="100%">
      <Div justify="space-around" m="0 0 36px 0" width="100%">
        {cardsInfo.map((ele, indx) => (
          <Div
            key={indx}
            height="118px"
            width="314px"
            p="0 26px 0 31px"
            justify="space-around"
            gap="25px"
            align="center"
            style={{ borderRadius: "18px", background: ele.color }}
          >
            <Text
              color={theme.colors.white}
              size={theme.fonts.size.h6}
              style={{ textAlign: "start" }}
            >
              {ele.title}
            </Text>
            <Text
              color={theme.colors.white}
              size={theme.fonts.size.h1i}
              weight={theme.fonts.weight.bold}
            >
              {ele.quantity}
            </Text>
          </Div>
        ))}
      </Div>
      <Div justify="space-between" align="center" width="100%">
        <Text
          color={theme.colors.gray500}
          size={theme.fonts.size.h5}
          weight={theme.fonts.weight.semibold}
        >
          {t("allProjects")}
        </Text>
        <Div gap="32px" align="center" m="0 0 39px 0">
          <Input
            width="254px"
            placeholder={t("common:search")}
            suffix={<SearchIcon />}
            colorPlaceholder={theme.colors.gray200}
            radius="23px"
            value={search}
            onChange={handleChange}
          />
          <Link icon={<Notification fill={theme.colors.green} />}>
            {t("reminders")}
          </Link>
          <Button width={"112px"} variant={"outlined"} icon>
            {t("filter")}
            <img alt="icon" src={filterIcon} style={{ marginLeft: "7px" }} />
          </Button>
        </Div>
      </Div>
      <TableContent
        source={source}
        pages={pages}
        totalItems={totalItems}
        handleChangeTable={handleChangeTable}
        data={projectsData}
      />
    </Div>
  );
};

TabsContent.propTypes = {
  source: PropTypes.string,
  projectsInfo: PropTypes.object,
};
