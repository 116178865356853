/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { ModuleContainer } from "../../styles/Common";
import { useLocation, useNavigate } from "react-router-dom";
import useGetPlantInfo from "../NewProject/hooks/useGetPlantInfo";
import useUpdatePlant from "../NewPlants/hooks/useUpdatePlant";
import { PlantForm } from "../NewPlants/components/plantForm";
import dayjs from "dayjs";
import { validatePlantForm } from "../../utilities/validations";
import useGetProvinces from "../NewPlants/hooks/useGetProvinces";
import useGetMunicipality from "../NewPlants/hooks/useGetMunicipality";
import { formatNumberOpex } from "../../utilities/helpers";

export const UpdatePlant = () => {
  const { t, i18n } = useTranslation("newPlant");
  const locale = i18n.language;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const update = useUpdatePlant();
  const { search } = useLocation();
  const objective = new URLSearchParams(search).get("objective");
  const projectType = new URLSearchParams(search).get("projectType");
  const financingType = new URLSearchParams(search).get("financingType");
  const plantId = new URLSearchParams(search).get("plantId");
  const projectId = new URLSearchParams(search).get("projectId");
  const isNewProject = new URLSearchParams(search).get("isNewProject");
  const [forceUpdate, setForceUpdate] = useState(false);
  const { data, isSuccess } = useGetPlantInfo(plantId);
  const { data: provincesData, isSuccess: isSuccessProvinces } =
    useGetProvinces();
  const [provincesOptions, setProvincesOptions] = useState([]);
  const [formError, setFormError] = useState({
    name: {
      error: false,
      message: "",
    },
    potenciaPico: {
      error: false,
      message: "",
    },
    horasEquivalentes: {
      error: false,
      message: "",
    },
    horasEquivalentes90: {
      error: false,
      message: "",
    },
  });

  const [plantsInfo, setPlantsInfo] = useState({
    id: "",
    _id: "",
    projectId: projectId,
    potenciaPico: "",
    inReview: false,
    AAC: null,
    plazoContratoArrendamiento: "",
    interconexion: null,
    workLicenses: null,
    rejected: "",
    costeArrendamiento: "",
    accepted: "",
    currentStep: "",
    AAP: null,
    potenciaNominal: "",
    estadoTerrenoPlanta: null,
    superficie: "",
    admisionOSCA: null,
    name: "",
    location: "",
    fechaEstimadaRtBPlanta: "",
    DIA: null,
    DUP: null,
    horasEquivalentes90: "",
    horasEquivalentes: "",
    actaPuestaEnMarcha: null,
    codigoIT: "",
    costesEstimadosOM: "",
    costesImpuestos: "",
    costesSeguros: "",
    estadoContratoTecnicoCTA: null,
    fechaPuestaEnMarcha: "",
    municipio: "",
    otrosGastos: "",
    longitudLineaElectrica: "",
    equiposMedicion: null,
    documentoInicio: "",
    informacionPublica: "",
    estadoTerrenoInfraestructurasElectricas: "",
    fechaInicioPagoArrendamiento: "",
    lineaCompartida: "",
    fechaIVA: "",
    tensionConexion: "",
    tensionSTREE: "",
    longitud: "",
    typeLine: null,
    tensionLine: "",
    fechaEstimadaCoDPlanta: "",
  });

  const { data: municipalityData, isSuccess: isSuccessMunicipality } =
    useGetMunicipality(plantsInfo.location);
  const [municipalityOptions, setMunicipalityOptions] = useState([]);

  useEffect(() => {
    if (isSuccessProvinces) {
      setProvincesOptions(
        provincesData?.data?.data.map((ele, indx) => ({
          key: indx,
          label: ele.label,
          value: ele.code,
        }))
      );
    }
  }, [isSuccessProvinces, provincesData]);

  useEffect(() => {
    if (isSuccessMunicipality) {
      setMunicipalityOptions(
        municipalityData?.data?.data.map((ele, indx) => ({
          key: indx,
          label: ele.label,
          value: ele.code,
        }))
      );
    }
  }, [isSuccessMunicipality, municipalityData]);

  const langDecimal = locale === "es" ? "," : ".";

  useEffect(() => {
    if (isSuccess) {
      setPlantsInfo({
        ...plantsInfo,
        ...data.data,
        fechaEstimadaCoDPlanta: data.data.fechaEstimadaCoDPlanta
          ? dayjs(data.data.fechaEstimadaCoDPlanta)
          : null,
        fechaEstimadaRtBPlanta: data.data.fechaEstimadaRtBPlanta
          ? dayjs(data.data.fechaEstimadaRtBPlanta)
          : null,
        fechaIVA: data.data.fechaIVA ? dayjs(data.data.fechaIVA) : null,
        fechaPuestaEnMarcha: data.data.fechaPuestaEnMarcha
          ? dayjs(data.data.fechaPuestaEnMarcha)
          : null,
        fechaInicioPagoArrendamiento: data.data.fechaInicioPagoArrendamiento
          ? dayjs(data.data.fechaInicioPagoArrendamiento)
          : null,
        costesEstimadosOM: formatNumberOpex(String(data.data.costesEstimadosOM).replace(".", langDecimal), locale),
        costesSeguros: formatNumberOpex(String(data.data.costesSeguros).replace(".", langDecimal), locale),
        costesImpuestos: formatNumberOpex(String(data.data.costesImpuestos).replace(".", langDecimal), locale),
        otrosGastos: formatNumberOpex(String(data.data.otrosGastos).replace(".", langDecimal), locale),
      });
    }
  }, [isSuccess, data, locale, langDecimal]);

  const stringToNumber = (str) => {
    if (str === 0 || str === "" || !str) {
      return;
    }

    str = String(str);
    let normalizedStr = str?.replace(',', '.');
    return parseFloat(normalizedStr);
  };

  const handleSave = () => {
    const payload = Object.fromEntries(
      Object.entries(plantsInfo).filter(
        ([, valor]) => valor !== "" && valor !== null
      )
    );
    const payloadWithNumbers = Object.fromEntries(
      Object.entries(payload).map(([key, value]) => {
        if (
          key === "potenciaPico" ||
          key === "potenciaNominal" ||
          key === "horasEquivalentes90" ||
          key === "horasEquivalentes" ||
          key === "plazoContratoArrendamiento" ||
          key === "costeArrendamiento" ||
          key === "superficie" ||
          key === "longitud" ||
          key === "tensionLine" ||
          key === "codigoIT"
        ) {
          return [key, Number(value)];
        } else if (
          key === "costesEstimadosOM" ||
          key === "costesSeguros" ||
          key === "costesImpuestos" ||
          key === "otrosGastos"
        ) {
          return [key, stringToNumber(value)];
        }
        return [key, value];
      })
    );

    delete payloadWithNumbers.createdAt;
    delete payloadWithNumbers.originalData;
    delete payloadWithNumbers.__v;

    const formValidation = {
      name: plantsInfo.name,
      potenciaPico: Number(plantsInfo.potenciaPico),
      horasEquivalentes90: Number(plantsInfo.horasEquivalentes90),
      horasEquivalentes: Number(plantsInfo.horasEquivalentes),
    };

    if (!(projectType === "solar" && financingType === "construction")) {
      delete formValidation.horasEquivalentes;
    }

    const validation = validatePlantForm.validate(formValidation, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = formError;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        switch (ele.context.label) {
          case "horasEquivalentes90":
          case "horasEquivalentes":
            newErrorForm[ele.context.label].message = t("errorHours");
            break;
          default:
            newErrorForm[ele.context.label].message = t("required");
            break;
        }
      });
      setFormError(newErrorForm);
      setForceUpdate(!forceUpdate);
    } else {
      update.reset();
      update.mutate(
        { ...payloadWithNumbers },
        {
          onSuccess: () => {
            toast.success(t("common:saveChanges"));
            if (isNewProject === "false") {
              navigate(
                `/projects/edit-project?projectId=${projectId}&selected=plants`
              );
              setForceUpdate(!forceUpdate);
            } else {
              navigate(
                `/projects/new-project?objective=${objective}&projectType=${projectType}&financingType=${financingType}&selected=plants&projectId=${projectId}`
              );
            }
            queryClient.invalidateQueries(["getPlantsList", projectId]);
          },
          onError: (err) => {
            toast.error(
              t(
                `common:${
                  err.response.data.result.code.message ||
                  err.response.data.result.code
                }`
              )
            );
          },
        }
      );
    }
  };

  return (
    <ModuleContainer padding="33px 64px 56px 32px" direction="column">
      <PlantForm
        financingType={financingType}
        projectType={projectType}
        handleSave={handleSave}
        plantsInfo={plantsInfo}
        setPlantsInfo={setPlantsInfo}
        objective={objective}
        isNewProject={isNewProject}
        formError={formError}
        setFormError={setFormError}
        provincesOptions={provincesOptions}
        municipalityOptions={municipalityOptions}
        plantId={plantId}
      />
    </ModuleContainer>
  );
};
