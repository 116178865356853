import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ModuleContainer, Text, Div } from "../../styles/Common";
import { Row } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { Card } from "../../components/Card";
import { Pagination } from "../../components/Pagination";
import useGetActivityProject from "./Hooks/useGetActivityProject";
import { dateFormat } from "../../utilities/helpers";
import { ArrowLeftIcon } from "../../assets/icons";
import { useNavigate } from "react-router-dom";

export const ActivityProject = () => {
  const navigate = useNavigate();

  const getActivityProject = useGetActivityProject();
  const { data, isSuccess } = getActivityProject;
  const [itemList, setItemList] = useState([]);
  useEffect(() => {
    if (isSuccess) {
      setItemList(data?.data?.data);
    }
  }, [data, isSuccess]);

  const handleBack = () => {
    navigate("/projects");
  };

  const titleTable = [
    { title: "Fecha" },
    { title: "Acción" },
    { title: "Inversor" },
    { title: "Usuario" },
    { title: "Estado Interno" },
    { title: "Estado Externo" },
  ];

  return (
    <ModuleContainer direction="column" padding="26px 69px 12px 32px">
      <Row
        align="center"
        m="0 0 23px 0"
        style={{ cursor: "pointer" }}
        gap="6px"
        onClick={handleBack}
      >
        <ArrowLeftIcon />
        <Text weight={theme.fonts.weight.medium} color={theme.colors.green}>
          Volver atrás
        </Text>
      </Row>
      <Card
        margin="0 0 24px 0"
        width="100%"
        height="auto"
        padding="10px 35px 20px 26px"
      >
        <Row justify="space-between" height="54px" align="center">
          {titleTable.map((title, index) => (
            <Text
              key={index}
              style={{ width: "164px" }}
              color={theme.colors.green}
              minheight="53px"
            >
              {title.title}
            </Text>
          ))}
        </Row>
        {itemList.map((item, index) => (
          <Div
            justify="space-between"
            height="54px"
            background={index === 1 && theme.colors.green100}
            key={index}
            radius="10px"
            align="center"
          >
            <Text
              size={theme.fonts.size.xs}
              color={theme.colors.gray300}
              style={{ width: "164px" }}
            >
              {dateFormat(item.createdAt)}
            </Text>
            <Text
              size={theme.fonts.size.xs}
              color={theme.colors.gray300}
              style={{ width: "164px" }}
            >
              {item.action}
            </Text>
            <Text
              size={theme.fonts.size.xs}
              color={theme.colors.gray300}
              style={{ width: "164px" }}
            >
              {item.investorId.name}
            </Text>
            <Text
              size={theme.fonts.size.xs}
              color={theme.colors.gray300}
              style={{ width: "164px" }}
            >
              {item.createdBy.name}
            </Text>
            <Text
              size={theme.fonts.size.xs}
              color={theme.colors.white}
              style={{
                background: theme.colors.red,
                padding: "10px 10px",
                borderRadius: "10px",
                width: "164px",
              }}
            >
              {item.internalState}
            </Text>
            <Text
              size={theme.fonts.size.xs}
              color={theme.colors.white}
              style={{
                background: theme.colors.yellow700,
                padding: "10px 10px",
                borderRadius: "10px",
                width: "164px",
              }}
            >
              {item.exteralState}
            </Text>
          </Div>
        ))}
        <Row justify="center">
          <Pagination />
        </Row>
      </Card>
    </ModuleContainer>
  );
};

ActivityProject.propTypes = {
  projectInfo: PropTypes.object,
  debt: PropTypes.bool,
};
