import React from "react";
import { useNavigate } from "react-router-dom";
import { Div, ModuleContainer } from "../../styles/Common";
import { Link } from "../../components/Link";
import arrowLeft from "../../assets/icons/arrow-left.svg"
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import DownloadWhite from "../../assets/icons/Download-white.svg";
import DownloadGreen from "../../assets/icons/Download-green.svg";
import { Content } from "./components/content";
import { FinancingIcon, SaleIcon, CalendarIcon, CoinsIcon } from "../../assets/icons";
import { theme } from "../../styles/theme";

export const FinancialModel = () => {
  const { t } = useTranslation("financialModel");
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/projects")
  };

  const generalCharacteristics = [
    { label: t("size"), value: "-" },
    { label: t("stakeholders"), value: "-" },
    { label: t("location"), value: "-" },
    { label: t("production"), value: "-" },
    { label: t("opex"), value: "27.600 €" },
  ];

  const dates = [
    { label: t("rtb"), value: "No obtenido" },
    { label: t("cod"), value: "30/03/2011" },
  ];

  const originFunds = [
    { label: t("totalUses"), value: "17.114.501 €" },
    { label: t("epc"), value: "15.800.000 €" },
    { label: t("otherCapex"), value: "-" },
    { label: t("preOperationalCosts"), value: "-" },
    { label: t("upfrontFee"), value: "245.685 €" },
    { label: t("advisors"), value: "30.000 €" },
    { label: t("agencyFee"), value: "22.000 €" },
    { label: t("financialExpenses"), value: "532.171 €" },
    { label: t("dsraEndowment"), value: "484.646 €" },
  ];

  const totals = [
    { label: t("totalSources"), value: "17.114.501 €" },
    { label: t("equity"), value: "4.830.266 €" },
    { label: t("seniorDebt"), value: "12.284.235 €" },
  ];

  const financing = [
    { 
      label: t("amount"),
      value: "-",
      color: theme.colors.blue,
      icon: <CoinsIcon />
    },
    { 
      label: t("interestRate"),
      value: "-",
      color: theme.colors.green,
      icon: <FinancingIcon fill={theme.colors.white} />
    },
    { 
      label: `${t("term")}:`,
      value: "-",
      color: theme.colors.fuchsia,
      icon: <CalendarIcon />
    },
    { 
      label: `${t("commissions")}:`,
      value: "-",
      color: theme.colors.gray500,
      icon: <SaleIcon />
    },
  ];

  const sizingParameters = [
    { label: t("term"), value: "-" },
    { label: t("totalUses"), value: "-" },
    { label: t("totalUses"), value: "-" },
    { label: t("totalUses"), value: "-" },
    { label: t("equityFirst"), value: "-" }
  ];

  const refinancingHypothesisTime = [
    { 
      label: t("year"), 
      value: "2024", 
      color: theme.colors.blue 
    },
    { 
      label: `${t("term")}:`, 
      value: "36 meses",
      color: theme.colors.gray300 
    },
  ];

  const refinancingHypothesis = [
    { label: t("financingCancellationCost"), value: "-" },
    { label: t("amount"), value: "-" },
    { label: t("applicableInterestRate"), value: "-" },
    { label: t("commissions"), value: "-" },
  ];

  const sizingParameters2 = [
    { label: t("term"), value: "-" },
    { label: t("curve"), value: "-" },
    { label: `${t("production")}:`, value: "-" },
    { label: t("applicableRatios"), value: "-" },
  ];

  return (
    <ModuleContainer direction="column" padding="26px 69px 12px 32px">
      <Div justify="space-between">
        <Link icon={<img alt="arrow" src={arrowLeft} />} onClick={handleBack}>
          {t("back")}
        </Link>
        <Div gap="24px">
          <Button
            width="277px"
            height="30px"
            variant={"outlined"}
            icon={<img alt='download' src={DownloadGreen} width={"24px"} height={"24px"} />}
          >
            {t("downloadFinancialModel")}
          </Button>
          <Button
            width="272px"
            height="30px"
            icon={<img alt='download' src={DownloadWhite} width={"24px"} height={"24px"} />}
          >
            {t("downloadPDFInfo")}
          </Button>
        </Div>
      </Div>
      <Div direction="column" m="25px 0 0 0">
        <Content 
          source={"page"}
          generalCharacteristics={generalCharacteristics}
          dates={dates}
          originFunds={originFunds}
          totals={totals}
          financing={financing}
          sizingParameters={sizingParameters}
          refinancingHypothesisTime={refinancingHypothesisTime}
          refinancingHypothesis={refinancingHypothesis}
          sizingParameters2={sizingParameters2}
        />
      </Div>
    </ModuleContainer> 
  )
};