import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Div } from "../../../styles/Common";
import { Collapse } from "../../../components/Collapse";
import { ExtraButton } from "./extraButton";
import { Sizing } from "./sizing";
import { FinancingPrice } from "./financingPrice";
import { CashSweep } from "./cashSweep";
import { SwapCoverage } from "./swapCoverage";

export const SeniorDebt = ({
  sourceForm,
  handleChangePercent,
  handleChangeNumber,
  handleRadio,
  handleSelect,
  setSourceForm,
  handleChangeDec
}) => {
  const { t } = useTranslation("strategies");
  const [activeKey, setActiveKey] = useState(["0"]);
  const [collapsible, setCollapsible] = useState("disabled");
  const [collapsible2, setCollapsible2] = useState("disabled");

  const handlePanelChange = (key) => {
    setActiveKey(key);
  };

  const handleDisable = (value) => {
    const collapsibleValue = value ? "" : "disabled";
    setCollapsible(collapsibleValue);
    setSourceForm({...sourceForm, cashSweepActivation: value})
  };

  const handleDisable2 = (value) => {
    const collapsibleValue2 = value ? "" : "disabled";
    setCollapsible2(collapsibleValue2);
    setSourceForm({...sourceForm, swapHedge: value})
  };

  const items = [
    {
      key: "1",
      header: t("sizing"),
      panel: (
        <Sizing 
          sourceForm={sourceForm}
          handleChangeNumber={handleChangeNumber}
          handleRadio={handleRadio}
          handleSelect={handleSelect}
          handleChangePercent={handleChangePercent}
          handleChangeDec={handleChangeDec}
        />
      ),
    },
    {
      key: "2",
      header: t("financingPrice"),
      panel: (
        <FinancingPrice
          sourceForm={sourceForm}
          handleSelect={handleSelect}
          handleChangePercent={handleChangePercent}
          handleChangeDec={handleChangeDec}
        />
      ),
    },
    {
      key: "3",
      header: t("cashSweep"),
      panel: (
        <CashSweep
          sourceForm={sourceForm}
          handleChangeNumber={handleChangeNumber}
          handleChangePercent={handleChangePercent}
        />
      ),
      collapsible: collapsible,
      extra: <ExtraButton 
        disable={sourceForm.cashSweepActivation}
        handleDisable={handleDisable}
      />
    },
    {
      key: "4",
      header: t("swapCoverage"),
      panel: (
        <SwapCoverage
          sourceForm={sourceForm}
          handleChangePercent={handleChangePercent}
          handleSelect={handleSelect}
          handleRadio={handleRadio}
        />
      ),
      collapsible: collapsible2,
      extra: <ExtraButton 
        disable={sourceForm.swapHedge} 
        handleDisable={handleDisable2}
      />
    },
  ];

  return (
    <Div>
      <Collapse
        activeKey={activeKey}
        handlePanelChange={handlePanelChange}
        panels={items}
        group={true}
        marginBottom={"0px"}
        width={"100%"}
      />
    </Div>
  )
};

SeniorDebt.propTypes = {
  sourceForm: PropTypes.object,
  handleChangePercent: PropTypes.func,
  handleChangeNumber: PropTypes.func,
  handleRadio: PropTypes.func,
  handleSelect: PropTypes.func,
  setSourceForm: PropTypes.func,
  handleChangeDec: PropTypes.func
};