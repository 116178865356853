import React from "react";
import PropTypes from "prop-types";
import { Row } from "../../../../styles/Common";
import { Input } from "../../../../components/Input";
import { useTranslation } from "react-i18next";
import { theme } from "../../../../styles/theme";

export const Platform = ({
  strategyData,
  setStrategyData,
  handleChangeNumber,
  handleChange,
  tabActiveKey,
}) => {
  const { t } = useTranslation("teaserDocumentation");

  return (
    <>
      <Row p="0 100px 0 0 " m=" 33.5px 0 24px 0" gap="12px">
        <Input
          label={t("feeType")}
          id="feeType"
          type="number"
          width="310px"
          suffix="€/Mwp"
          helperColor={theme.colors.gray200}
          helper={t("feeNotIncluded")}
          value={strategyData[Number(tabActiveKey) - 1].feeType}
          onChange={(event) =>
            handleChangeNumber(event, strategyData, setStrategyData)
          }
        />
      </Row>
      <Row>
        <Input
          label={t("feeConsiderations")}
          id="feeConsiderations"
          width="100%"
          height="138px"
          value={strategyData[Number(tabActiveKey) - 1].feeConsiderations}
          onChange={(event) =>
            handleChange(event, strategyData, setStrategyData)
          }
        />
      </Row>
    </>
  );
};

Platform.propTypes = {
  strategyData: PropTypes.array,
  setStrategyData: PropTypes.func,
  handleChange: PropTypes.func,
  handleChangeNumber: PropTypes.func,
  tabActiveKey: PropTypes.string,
};
