import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { Input } from "../../../components/Input";
import { useTranslation } from "react-i18next";
import percent from "../../../assets/icons/percent.svg";
import years from "../../../assets/icons/years.svg";

export const CashSweep = ({
  sourceForm,
  handleChangeNumber,
  handleChangePercent
}) => {
  const { t } = useTranslation("strategies");

  return (
    <Row gutter={[32, 32]} style={{ width: '100%', marginTop: "32px" }}>
      <Col md={8}>
        <Input 
          id="CSYearsInPeriod"
          label={t("seniorDebtForm.cashSweepDuration")}
          value={sourceForm.CSYearsInPeriod}
          onChange={handleChangeNumber}
          suffix={<img alt="years" src={years} />}
        />
      </Col>
      <Col md={8}>
        <Input 
          id="CSStartingYear"
          label={t("seniorDebtForm.cashSweepYear")}
          value={sourceForm.CSStartingYear}
          onChange={handleChangeNumber}
        />
      </Col>
      <Col md={8}>
        <Input 
          id="CSRatioPeriod1"
          label={t("seniorDebtForm.cashSweepRatio")}
          value={sourceForm.CSRatioPeriod1}
          onChange={handleChangePercent}
          suffix={<img alt="percent" src={percent} />}
        />
      </Col>
    </Row>
  )
};

CashSweep.propTypes = {
  sourceForm: PropTypes.object,
  handleChangeNumber: PropTypes.func,
  handleChangePercent: PropTypes.func,
};