import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Div, ModuleContainer, Text } from "../../styles/Common";
import { Link } from "../../components/Link";
import arrowLeft from "../../assets/icons/arrow-left.svg"
import { useTranslation } from "react-i18next";
import { theme } from "../../styles/theme";
import { Card } from "../../components/Card";
import { Button } from "../../components/Button";
import Tabs from "../../components/Tabs";
import { TabsContent } from "./components/TabsContent";

export const InvestorActivity = () => {
  const { t } = useTranslation("investorActivity");
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("1");
  const [projectsInfo, setProjectsInfo] = useState({
    totalProjectInvitations: "00",
    discardedProjects: "00",
    closedOperations: "00"
  });

  useEffect(() => {
    if (activeKey === "1") {
      setProjectsInfo({
        totalProjectInvitations: "50",
        discardedProjects: "20",
        closedOperations: "02",
      })
    } else {
      setProjectsInfo({
        totalProjectInvitations: "10",
        discardedProjects: "07",
        closedOperations: "00",
      })
    }
  }, [activeKey]);

  const handleBack = () => {
    navigate("/users?key=3")
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const panels = [
    {
      tab: t("debtProject"),
      key: "1",
    },
    {
      tab: t("m&aProject"),
      key: "2",
    },
  ];

  return (
    <ModuleContainer direction="column" padding="11px 69px 0 32px">
      <Link icon={<img alt="arrow" src={arrowLeft} />} onClick={handleBack}>
        {t("back")}
      </Link>
      <Card 
        width="100%"
        margin="36px 0px 50px 0"
        padding="31px 38px 34px 32px"
        shadow="0px 7px 26px #0000000B"
        style={{ maxWidth: "100%" }}
      >
        <Div justify="space-between" m="0 0 24px 0">
          <Text
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            {t("title")}
          </Text>
          <Button width={"211px"}>
            {t("inviteProject")}
          </Button>
        </Div>
        <Tabs
          defaultActiveKey="1"
          onChange={handleTabChange}
          activeKey={activeKey}
          color={theme.colors.gray500}
        >
          {panels.map((panel) => (
            <Tabs.Panel tab={panel.tab} key={panel.key}>
              <TabsContent 
                source={activeKey === "1" ? "debt" : "m&a" }
                projectsInfo={projectsInfo}
              />
            </Tabs.Panel>
          ))}
        </Tabs>
      </Card>
    </ModuleContainer>
  )
};