import React from "react";
import PropTypes from "prop-types";
import { theme } from "../../../styles/theme";
import { Text } from "../../../styles/Common";
import { Col, Row } from "antd";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select2";
import { useTranslation } from "react-i18next";
import RadioGroup from "../../../components/Radio2/Group";
import { Radio } from "../../../components/Radio2";
import { CommonFields } from "./commonFields";
import DatePicker from "../../../components/DatePicker";
import { ContractStatusOptions } from "../../../utilities/plantsOptions";

export const FinancingRefinancing = ({
  plantsInfo,
  projectType,
  financingType,
  mwp,
  years,
  anual,
  ha,
  handleChange,
  handleSelect,
  handleDate,
  handleRadio,
  handleChangeNumber,
  formError,
  provincesOptions,
  municipalityOptions,
}) => {
  const { t } = useTranslation("newPlant");
  const lands =
    plantsInfo.estadoTerrenoPlanta === 2 ||
    plantsInfo.estadoTerrenoPlanta === 3;

  return (
    <>
      <Text
        color={theme.colors.blue}
        size={theme.fonts.size.h6}
        weight={theme.fonts.weight.medium}
        mb="23.5px"
      >
        {t("plantInformation")}
      </Text>
      <hr
        style={{
          width: "100%",
          border: `1px solid ${theme.colors.gray100}`,
          marginBottom: "36.5px",
        }}
      />
      <Row gutter={[32, 32]}>
        <CommonFields
          financingType={financingType}
          projectType={projectType}
          plantsInfo={plantsInfo}
          mwp={mwp}
          years={years}
          anual={anual}
          ha={ha}
          handleChange={handleChange}
          handleSelect={handleSelect}
          handleChangeNumber={handleChangeNumber}
          formError={formError}
          provincesOptions={provincesOptions}
          municipalityOptions={municipalityOptions}
        />
        <Col md={8}>
          <DatePicker
            label={t("startUpDate")}
            placeholder={t("dateFormat")}
            format={"DD/MM/YYYY"}
            value={plantsInfo.fechaPuestaEnMarcha}
            style={{ width: "100%" }}
            onChange={(event) => handleDate(event, "fechaPuestaEnMarcha")}
          />
        </Col>
        {lands && (
          <>
            <Col md={8}></Col>
          </>
        )}
        <Col md={8}>
          <Select
            label={t("contractStatus")}
            placeholder={`${t("choose")}...`}
            width={"100%"}
            options={ContractStatusOptions}
            value={plantsInfo.estadoContratoTecnicoCTA}
            onChange={(event) =>
              handleSelect(event, "estadoContratoTecnicoCTA")
            }
          />
        </Col>
        <Col md={8} style={{ marginTop: "16px" }}>
          <Input
            id={"codigoIT"}
            label={t("itCode")}
            value={plantsInfo.codigoIT}
            onChange={handleChangeNumber}
          />
        </Col>
        <Col md={8} style={{ marginTop: "16px" }}>
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray200}
            mt="2px"
            mb="18px"
            align="left"
          >
            {t("startupCertificate")}
          </Text>
          <RadioGroup
            name={"actaPuestaEnMarcha"}
            gap={"48px"}
            value={plantsInfo.actaPuestaEnMarcha}
            onChange={handleRadio}
          >
            <Radio label={t("yes")} value={true} />
            <Radio label={t("no")} value={false} />
          </RadioGroup>
        </Col>
      </Row>
    </>
  );
};

FinancingRefinancing.propTypes = {
  plantsInfo: PropTypes.object,
  projectType: PropTypes.string,
  financingType: PropTypes.string,
  mwp: PropTypes.string,
  years: PropTypes.string,
  anual: PropTypes.string,
  ha: PropTypes.string,
  m: PropTypes.string,
  kv: PropTypes.string,
  handleChange: PropTypes.func,
  handleSelect: PropTypes.func,
  handleDate: PropTypes.func,
  handleRadio: PropTypes.func,
  handleChangeNumber: PropTypes.func,
  formError: PropTypes.object,
  provincesOptions: PropTypes.array,
  municipalityOptions: PropTypes.array,
};
