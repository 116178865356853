import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Div, Text } from "../../../styles/Common";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { theme } from "../../../styles/theme";
import { Input } from "../../../components/Input";
import searchIcon from "../../../assets/icons/Search.svg";
import { Button } from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import { CardPlant } from "../styles";
import deleteIcon from "../../../assets/icons/delete-green.svg";
import editIcon from "../../../assets/icons/Edit-green.svg";
import copyIcon from "../../../assets/icons/Copy.svg";
import { IconButton } from "../../../components/IconButton";
import useDeletePlant from "../hooks/useDeletePlant";
import useCopyPlant from "../hooks/useCopyPlant";
import toast from "react-hot-toast";
import { dateFormat } from "../../../utilities/helpers";
import { AlertIcon } from "../../../assets/icons";
import useGetProvinces from "../../NewPlants/hooks/useGetProvinces";
import { axiosClient } from "../../../core/services/axiosInstance";
import { getLabelFromValue } from "../../../utilities/helpers";

export const Plants = ({
  objective,
  projectType,
  financingType,
  projectId: idProject,
  plantsList,
  isNew,
  projectName,
  additionalInformationRequested
}) => {
  const { t } = useTranslation(["newProject", "common"]);
  const { search } = useLocation();
  const projectId = new URLSearchParams(search).get("projectId") || idProject;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const deletePlant = useDeletePlant();
  const copy = useCopyPlant();

  const { data: provincesData, isSuccess: isSuccessProvinces } =
    useGetProvinces();
  const [provincesOptions, setProvincesOptions] = useState([]);
  useEffect(() => {
    if (isSuccessProvinces) {
      setProvincesOptions(
        provincesData?.data?.data.map((ele, indx) => ({
          key: indx,
          label: ele.label,
          value: ele.code,
        }))
      );
    }
  }, [isSuccessProvinces, provincesData]);

  const [municipalitiesByProvince, setMunicipalitiesByProvince] = useState({});
  const getMunicipalityLabel = (provinceCode, municipalityCode) => {
    return (
      municipalitiesByProvince[provinceCode]?.[municipalityCode] ||
      municipalityCode
    );
  };
  useEffect(() => {
    const uniqueProvinces = Array.from(
      new Set(plantsList.map((plant) => plant.location))
    );
    const validProvinces = uniqueProvinces.filter(
      (provinceCode) => provinceCode
    );

    if (validProvinces.length === 0) {
      return;
    }
    const fetchMunicipalities = async () => {
      try {
        const municipalities = await Promise.all(
          validProvinces.map(async (provinceCode) => {
            const response = await axiosClient.get(`provinces/${provinceCode}`);
            return { provinceCode, municipalities: response.data.data };
          })
        );

        const municipalitiesMap = municipalities.reduce(
          (acc, { provinceCode, municipalities }) => {
            acc[provinceCode] = municipalities.reduce(
              (municipalitiesAcc, ele) => {
                municipalitiesAcc[ele.code] = ele.label;
                return municipalitiesAcc;
              },
              {}
            );
            return acc;
          },
          {}
        );

        setMunicipalitiesByProvince(municipalitiesMap);
      } catch {
        toast.error("Failed to fetch municipalities");
      }
    };

    fetchMunicipalities();
  }, [plantsList]);

  const handleNewPlant = () => {
    navigate(
      `/projects/new-plant?objective=${objective}&projectType=${projectType}&financingType=${financingType}&projectId=${projectId}&isNewProject=${isNew}&projectName=${projectName}`
    );
  };

  const handleAdditionalInfoPlants = (plantId, plantName) => {
    navigate(
      `/projects/info-plants?objective=${objective}&projectType=${projectType}&financingType=${financingType}&projectId=${projectId}&plantId=${plantId}&projectName=${projectName}&plantName=${plantName}`
    );
  };

  const handleEditPlant = (plantId, plantName) => {
    navigate(
      `/projects/edit-plant?objective=${objective}&projectType=${projectType}&financingType=${financingType}&projectId=${projectId}&isNewProject=${isNew}&plantId=${plantId}&projectName=${projectName}&plantName=${plantName}`
    );
  };

  const handleDelete = (plantId) => {
    deletePlant.reset();
    deletePlant.mutate(plantId, {
      onSuccess: () => {
        toast.success(t("common:saveChanges"));
        queryClient.invalidateQueries(["getPlantsList", projectId]);
      },
      onError: (err) => {
        toast.error(
          t(
            `common:${
              err.response.data.result.code.message ||
              err.response.data.result.code
            }`
          )
        );
      },
    });
  };

  const handleCopy = (plantId) => {
    copy.reset();
    copy.mutate(plantId, {
      onSuccess: () => {
        toast.success(t("common:saveChanges"));
        queryClient.invalidateQueries(["getPlantsList", projectId]);
      },
      onError: (err) => {
        toast.error(
          t(
            `common:${
              err.response.data.result.code.message ||
              err.response.data.result.code
            }`
          )
        );
      },
    });
  };

  const buttonsOptions = [
    { icon: deleteIcon, func: (id) => handleDelete(id) },
    { icon: copyIcon, func: (id) => handleCopy(id) },
    {
      icon: editIcon,
      func: (id, name) => additionalInformationRequested ? handleAdditionalInfoPlants(id, name) : handleEditPlant(id, name),
    },
  ];

  const landsOptions = {
    1: "Propiedad",
    2: "Arrendamiento",
    3: "Pendiente",
  };

  return (
    <>
      <Div justify="space-between" align="center">
        <Text
          color={theme.colors.blue}
          size={theme.fonts.size.h6}
          weight={theme.fonts.weight.medium}
        >
          {t("plants")}
        </Text>
        <Input
          placeholder={`${t("common:search")}...`}
          radius={"23px"}
          width={"323px"}
          suffix={<img alt="search" src={searchIcon} />}
        />
      </Div>
      <Text
        color={theme.colors.gray200}
        mt="14px"
        mb="32px"
        style={{ width: "758px", lineHeight: "25px" }}
      >
        {t("plantsMessage")}
      </Text>
      <Div
        direction="column"
        maxheight="215px"
        height="217px"
        style={{ overflowY: "auto" }}
      >
        {plantsList.map((ele, indx) => (
          <CardPlant key={indx}>
            <Div justify="space-between" align="center">
              <Text
                color={theme.colors.blue}
                size={theme.fonts.size.h6}
                weight={theme.fonts.weight.medium}
                style={{ width: "343px" }}
                align="left"
              >
                {ele.name}
              </Text>
              {!ele?.isCompleted && additionalInformationRequested && (
                <Button
                  background={theme.colors.yellow100}
                  hBackground={theme.colors.yellow100}
                  height="40px"
                  width="223px"
                  onClick={() => handleAdditionalInfoPlants(ele._id, ele.name)}
                >
                  <Row
                    justify="center"
                    style={{ gap: "7px", alignItems: "center" }}
                  >
                    <Text
                      size={theme.fonts.size.sm}
                      color={theme.colors.yellow700}
                    >
                      Completar información
                    </Text>
                    <AlertIcon fill={theme.colors.yellow700} />
                  </Row>
                </Button>
              )}
              <Div gap="13px">
                {buttonsOptions.map((elem, index) => (
                  <IconButton
                    key={index}
                    icon={elem.icon}
                    onClick={() => elem.func(ele._id, ele.name)}
                    width={"32px"}
                    height={"32px"}
                  />
                ))}
              </Div>
            </Div>
            <hr
              style={{
                width: "100%",
                border: `1px solid ${theme.colors.gray100}`,
                margin: "13px 0 17px 0",
              }}
            />
            <Row justify={"space-between"}>
              <Col>
                <Text
                  color={theme.colors.gray300}
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.medium}
                  mb="8px"
                >
                  {t("location")}
                </Text>
                <Text color={theme.colors.gray300} size={theme.fonts.size.sm}>
                  {ele?.location
                    ? `${getLabelFromValue(
                        ele.location,
                        provincesOptions
                      )}, ${getMunicipalityLabel(ele.location, ele.municipio)}`
                    : "-"}
                </Text>
              </Col>
              <Col>
                <Text
                  color={theme.colors.gray300}
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.medium}
                  mb="8px"
                >
                  {t("peakPower")}
                </Text>
                <Text color={theme.colors.gray300} size={theme.fonts.size.sm}>
                  {ele.potenciaPico} MWp
                </Text>
              </Col>
              <Col>
                <Text
                  color={theme.colors.gray300}
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.medium}
                  mb="8px"
                >
                  {t("land")}
                </Text>
                <Text color={theme.colors.gray300} size={theme.fonts.size.sm}>
                  {landsOptions[ele.estadoTerrenoPlanta]}
                </Text>
              </Col>
              <Col>
                <Text
                  color={theme.colors.gray300}
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.medium}
                  mb="8px"
                >
                  {t("startDate")}
                </Text>
                <Text color={theme.colors.gray300} size={theme.fonts.size.sm}>
                  {ele.fechaEstimadaCoDPlanta
                    ? dateFormat(ele.fechaEstimadaCoDPlanta)
                    : "-"}
                </Text>
              </Col>
            </Row>
          </CardPlant>
        ))}
      </Div>
      <Button
        width={"150px"}
        onClick={handleNewPlant}
        style={{ margin: "10px 0 0 0" }}
      >
        {plantsList.length > 0 ? "Crear otra planta" : "Crear planta"}
      </Button>
    </>
  );
};

Plants.propTypes = {
  objective: PropTypes.string,
  projectType: PropTypes.string,
  financingType: PropTypes.string,
  projectId: PropTypes.string,
  plantsList: PropTypes.array,
  isNew: PropTypes.bool,
  projectName: PropTypes.string,
  additionalInformationRequested: PropTypes.bool
};
