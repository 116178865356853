import React from "react";
import PropTypes from "prop-types";
import { theme } from "../../../styles/theme";
import { Div, Text } from "../../../styles/Common";
import { Col, Row } from "antd";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select2";
import { useTranslation } from "react-i18next";
import RadioGroup from "../../../components/Radio2/Group";
import { Radio } from "../../../components/Radio2";
import { CommonFields } from "./commonFields";
import {
  AACOptions,
  AAPOptions,
  ConnectionPermissionOptions,
  DIAOptions,
  DUPOptions,
  LineType,
} from "../../../utilities/plantsOptions";
import DatePicker from "../../../components/DatePicker";

export const SalePre = ({
  plantsInfo,
  projectType,
  financingType,
  mwp,
  years,
  anual,
  ha,
  m,
  kv,
  handleChange,
  handleSelect,
  handleDate,
  handleRadio,
  handleChangeNumber,
  formError,
  provincesOptions,
  municipalityOptions,
}) => {
  const { t } = useTranslation("newPlant");

  return (
    <>
      <Text
        color={theme.colors.blue}
        size={theme.fonts.size.h6}
        weight={theme.fonts.weight.medium}
        mb="23.5px"
      >
        {t("plantInformation")}
      </Text>
      <hr
        style={{
          width: "100%",
          border: `1px solid ${theme.colors.gray100}`,
          marginBottom: "36.5px",
        }}
      />
      <Row gutter={[32, 32]}>
        <CommonFields
          financingType={financingType}
          projectType={projectType}
          plantsInfo={plantsInfo}
          mwp={mwp}
          years={years}
          anual={anual}
          ha={ha}
          handleChange={handleChange}
          handleSelect={handleSelect}
          handleChangeNumber={handleChangeNumber}
          formError={formError}
          provincesOptions={provincesOptions}
          municipalityOptions={municipalityOptions}
        />
        <Col md={8}>
          <DatePicker
            label={t("rtbDate")}
            placeholder={t("dateFormat")}
            format={"DD/MM/YYYY"}
            value={plantsInfo.fechaEstimadaRtBPlanta}
            style={{ width: "100%" }}
            onChange={(event) => handleDate(event, "fechaEstimadaRtBPlanta")}
          />
        </Col>
        <Col md={8}>
          <DatePicker
            label={t("codDate")}
            placeholder={t("dateFormat")}
            format={"DD/MM/YYYY"}
            value={plantsInfo.fechaEstimadaCoDPlanta}
            style={{ width: "100%" }}
            onChange={(event) => handleDate(event, "fechaEstimadaCoDPlanta")}
          />
        </Col>
        <Col md={8}>
          <Input
            id={"longitud"}
            label={t("lineLongDist")}
            suffix={<img alt="m" src={m} />}
            value={plantsInfo.longitud}
            onChange={handleChangeNumber}
          />
        </Col>
        <Col md={8}>
          <Select
            label={t("typeOfLine")}
            placeholder={`${t("choose")}...`}
            width={"100%"}
            options={LineType}
            value={plantsInfo.typeLine}
            onChange={(event) => handleSelect(event, "typeLine")}
          />
        </Col>
        <Col md={8}>
          <Input
            id={"tensionLine"}
            label={t("lineVoltage")}
            suffix={<img alt="kv" src={kv} />}
            value={plantsInfo.tensionLine}
            onChange={handleChangeNumber}
          />
        </Col>
      </Row>
      <Text
        color={theme.colors.blue}
        size={theme.fonts.size.h6}
        weight={theme.fonts.weight.medium}
        mt="55px"
        mb="23.5px"
      >
        {t("permsAuthorOrDecla")}
      </Text>
      <hr
        style={{
          width: "100%",
          border: `1px solid ${theme.colors.gray100}`,
          marginBottom: "36.5px",
        }}
      />
      <Row gutter={[32, 32]}>
        <Col md={8}>
          <Select
            label={t("connectionPermission")}
            placeholder={`${t("choose")}...`}
            width={"100%"}
            options={ConnectionPermissionOptions}
            value={plantsInfo.interconexion}
            onChange={(event) => handleSelect(event, "interconexion")}
          />
        </Col>
        <Col md={8}>
          <Select
            label={t("dia")}
            placeholder={`${t("choose")}...`}
            width={"100%"}
            options={DIAOptions}
            value={plantsInfo.DIA}
            onChange={(event) => handleSelect(event, "DIA")}
          />
        </Col>
        <Col md={8}>
          <Select
            label={t("aap")}
            placeholder={`${t("choose")}...`}
            width={"100%"}
            options={AAPOptions}
            value={plantsInfo.AAP}
            onChange={(event) => handleSelect(event, "AAP")}
          />
        </Col>
        <Col md={8}>
          <Select
            label={t("aac")}
            placeholder={`${t("choose")}...`}
            width={"100%"}
            options={AACOptions}
            value={plantsInfo.AAC}
            onChange={(event) => handleSelect(event, "AAC")}
          />
        </Col>
        <Col md={8}>
          <Select
            label={t("dup")}
            placeholder={`${t("choose")}...`}
            width={"100%"}
            options={DUPOptions}
            value={plantsInfo.DUP}
            onChange={(event) => handleSelect(event, "DUP")}
          />
        </Col>
        <Col md={8}>
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray200}
            mt="2px"
            mb="8px"
            align="left"
          >
            {t("admispro")}
          </Text>
          <RadioGroup
            name={"admisionOSCA"}
            gap={"48px"}
            value={plantsInfo.admisionOSCA}
            onChange={handleRadio}
          >
            <Radio label={t("yes")} value={true} />
            <Radio label={t("no")} value={false} />
          </RadioGroup>
        </Col>
        <Col md={8}>
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray200}
            mt="2px"
            mb="8px"
            align="left"
          >
            {t("equipmentMed")}
          </Text>
          <RadioGroup
            name={"equiposMedicion"}
            gap={"48px"}
            value={plantsInfo.equiposMedicion}
            onChange={handleRadio}
          >
            <Radio label={t("yes")} value={true} />
            <Radio label={t("no")} value={false} />
          </RadioGroup>
        </Col>
        <Col md={8}>
          <DatePicker
            label={t("taxDate")}
            placeholder={t("dateFormat")}
            format={"DD/MM/YYYY"}
            value={plantsInfo.fechaIVA}
            style={{ width: "100%" }}
            onChange={(event) => handleDate(event, "fechaIVA")}
          />
        </Col>
        <Col md={8}></Col>
      </Row>
      <Div m="32px 0 0 0" align="center" gap="20px">
        <Text
          size={theme.fonts.size.sm}
          color={theme.colors.gray200}
          align="left"
        >
          {t("buildingLicense")}:
        </Text>
        <RadioGroup
          name={"workLicenses"}
          gap={"32px"}
          value={plantsInfo.workLicenses}
          onChange={handleRadio}
        >
          <Radio label={t("obtained")} value={1} />
          <Radio label={t("inProcess")} value={2} />
          <Radio label={t("notRequested")} value={3} />
        </RadioGroup>
      </Div>
    </>
  );
};

SalePre.propTypes = {
  plantsInfo: PropTypes.object,
  projectType: PropTypes.string,
  financingType: PropTypes.string,
  mwp: PropTypes.string,
  years: PropTypes.string,
  anual: PropTypes.string,
  ha: PropTypes.string,
  m: PropTypes.string,
  kv: PropTypes.string,
  handleChange: PropTypes.func,
  handleSelect: PropTypes.func,
  handleDate: PropTypes.func,
  handleRadio: PropTypes.func,
  handleChangeNumber: PropTypes.func,
  formError: PropTypes.object,
  provincesOptions: PropTypes.array,
  municipalityOptions: PropTypes.array,
};
