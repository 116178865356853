import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Radio } from "../../../components/Radio";
import { Text, Div } from "../../../styles/Common";
import { Input } from "../../../components/Input";
import { theme } from "../../../styles/theme";
import { formatNumber } from "../../../utilities/helpers";

export const Capex = ({
  capexInfo,
  setCapexInfo,
  projectType,
  handleChangeFormat
}) => {
  const { t, i18n } = useTranslation("newProject");
  const locale = i18n.language;

  const isEditMode = capexInfo && Object.keys(capexInfo).length > 0 &&
    (capexInfo.totalProjectCost > 0 ||
      Object.values(capexInfo).some(value => value > 0));

  const [selection, setSelection] = useState(() => {
    if (isEditMode) {
      return capexInfo.totalProjectCost > 0 ? "totalExpenses" : "detailedExpenses";
    }
    return "";
  });

  const handleSelectionChange = useCallback((key) => {
    if (selection !== key) {
      const newCapexInfo = { ...capexInfo };
      if (key === "totalExpenses") {
        Object.keys(newCapexInfo).forEach(field => {
          if (field !== "totalProjectCost") {
            newCapexInfo[field] = '';
          }
        });
      } else if (key === "detailedExpenses") {
        newCapexInfo["totalProjectCost"] = '';
      }

      setCapexInfo(newCapexInfo);
      setSelection(key);
    }
  }, [selection, capexInfo, setCapexInfo]);

  const inputConfigs = [
    {
      key: "totalExpenses",
      condition: (selection) => selection === "totalExpenses",
      render: (t) => (
        <Input
          sizeLabel={theme.fonts.size.default}
          weightLabel={theme.fonts.weight.regular}
          label={t("capex.totalProjectCost")}
          id="totalProjectCost"
          suffix={"€"}
          value={formatNumber(capexInfo["totalProjectCost"], locale) === "0" ? "" : formatNumber(capexInfo["totalProjectCost"], locale)}
          onChange={(e) => handleChangeFormat(e, capexInfo, setCapexInfo)}
          min="0"
          width="50%"
        />
      ),
    },
    {
      key: "detailedExpenses",
      condition: (selection) => selection === "detailedExpenses",
      render: (t) => (
        <Div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
          }}
        >
          {Object.entries({
            substationReinforcementCost: t("capex.substationReinforcementCost"),
            transmissionLineCost: t("capex.transmissionLineCost"),
            solarPanelsCosts: t("capex.totalCostSolarPanels"),
            totalTransformersCost: t("capex.totalTransformersCost"),
            invertersCosts: t("capex.powerInverterCost"),
            additionalInvestmentCosts: t("capex.additionalInvestmentCosts"),
          }).filter(([id]) => {
            if (id === "solarPanelsCosts" || id === "invertersCosts") {
              return projectType === "solar";
            }
            return true;
          }).map(([id, label]) => (
            <Input
              key={id}
              sizeLabel={theme.fonts.size.default}
              weightLabel={theme.fonts.weight.regular}
              label={label}
              id={id}
              suffix={"€"}
              value={formatNumber(capexInfo[id], locale) === "0" ? "" : formatNumber(capexInfo[id], locale)}
              onChange={(e) => handleChangeFormat(e, capexInfo, setCapexInfo)}
              min="0"
            />
          ))}
          {projectType === "eolic" && (
            <Input
              key={"totalTurbinesCost"}
              sizeLabel={theme.fonts.size.default}
              weightLabel={theme.fonts.weight.regular}
              label={t("capex.totalTurbinesCost")}
              id={"totalTurbinesCost"}
              suffix={"€"}
              value={formatNumber(capexInfo["totalTurbinesCost"], locale) === "0" ? "" : formatNumber(capexInfo["totalTurbinesCost"], locale)}
              onChange={(e) => handleChangeFormat(e, capexInfo, setCapexInfo)}
              min="0"
            />
          )}
        </Div>
      ),
    },
  ];

  return (
    <>
      <Text
        weight={theme.fonts.weight.medium}
        size={theme.fonts.size.h6}
        color={theme.colors.blue}
        mb="32px"
      >
        {t("CAPEX")}
      </Text>
      <Radio.Group
        name="capex"
        value={selection}
        onChange={(e) => handleSelectionChange(e.target.value)}
        gap="8px"
        direction="column"
      >
        {inputConfigs.map(({ key, render, condition }) => (
          <React.Fragment key={key}>
            <Radio
              label={t(`capex.${key}`)}
              value={key}
              checked={selection === key}
              onChange={() => handleSelectionChange(key)}
            />
            {condition(selection) ? render(t) : null}
          </React.Fragment>
        ))}
      </Radio.Group>
    </>
  );
};

Capex.propTypes = {
  capexInfo: PropTypes.object,
  setCapexInfo: PropTypes.func,
  projectType: PropTypes.string,
  handleChangeFormat: PropTypes.func,
};