import React from "react";
import PropTypes from "prop-types";
import { theme } from "../../../styles/theme";
import { Text } from "../../../styles/Common";
import { Col, Row } from "antd";
import { Input } from "../../../components/Input";
import { useTranslation } from "react-i18next";
import { CommonFields } from "./commonFields";
import DatePicker from "../../../components/DatePicker";
import { formatNumberOpex } from "../../../utilities/helpers";

export const SaleOperation = ({
  plantsInfo,
  projectType,
  financingType,
  mwp,
  years,
  anual,
  ha,
  e,
  handleChange,
  handleSelect,
  handleDate,
  handleChangeNumber,
  formError,
  provincesOptions,
  municipalityOptions,
  handleChangeFormatOpex
}) => {
  const { t, i18n } = useTranslation("newPlant");
  const locale = i18n.language;

  return (
    <>
      <Text
        color={theme.colors.blue}
        size={theme.fonts.size.h6}
        weight={theme.fonts.weight.medium}
        mb="23.5px"
      >
        {t("plantInformation")}
      </Text>
      <hr
        style={{
          width: "100%",
          border: `1px solid ${theme.colors.gray100}`,
          marginBottom: "36.5px",
        }}
      />
      <Row gutter={[32, 32]}>
        <CommonFields
          financingType={financingType}
          projectType={projectType}
          plantsInfo={plantsInfo}
          mwp={mwp}
          years={years}
          anual={anual}
          ha={ha}
          handleChange={handleChange}
          handleSelect={handleSelect}
          handleChangeNumber={handleChangeNumber}
          formError={formError}
          provincesOptions={provincesOptions}
          municipalityOptions={municipalityOptions}
        />
        <Col md={8}>
          <DatePicker
            label={t("startUpDate")}
            placeholder={t("dateFormat")}
            format={"DD/MM/YYYY"}
            value={plantsInfo.fechaPuestaEnMarcha}
            style={{ width: "100%" }}
            onChange={(event) => handleDate(event, "fechaPuestaEnMarcha")}
          />
        </Col>
      </Row>
      <Text
        color={theme.colors.blue}
        size={theme.fonts.size.h6}
        weight={theme.fonts.weight.medium}
        mt="55px"
        mb="23.5px"
      >
        {t("plantOpex")}
      </Text>
      <hr
        style={{
          width: "100%",
          border: `1px solid ${theme.colors.gray100}`,
          marginBottom: "36.5px",
        }}
      />
      <Row gutter={[32, 32]}>
        <Col md={8}>
          <Input
            id={"costesEstimadosOM"}
            label={t("o&mCost")}
            suffix={<img alt="e" src={e} />}
            value={plantsInfo.costesEstimadosOM === "" ? "" : formatNumberOpex(plantsInfo.costesEstimadosOM, locale)}
            onChange={handleChangeFormatOpex}
          />
        </Col>
        <Col md={8}>
          <Input
            id={"costesSeguros"}
            label={t("insuranceCost")}
            suffix={<img alt="e" src={e} />}
            value={plantsInfo.costesSeguros === "" ? "" : formatNumberOpex(plantsInfo.costesSeguros, locale)}
            onChange={handleChangeFormatOpex}
          />
        </Col>
        <Col md={8}>
          <Input
            id={"costesImpuestos"}
            label={t("taxes")}
            suffix={<img alt="e" src={e} />}
            value={plantsInfo.costesImpuestos === "" ? "" : formatNumberOpex(plantsInfo.costesImpuestos, locale)}
            onChange={handleChangeFormatOpex}
          />
        </Col>
        <Col md={8}>
          <Input
            id={"otrosGastos"}
            label={t("otherCost")}
            suffix={<img alt="e" src={e} />}
            value={plantsInfo.otrosGastos === "" ? "" : formatNumberOpex(plantsInfo.otrosGastos, locale)}
            onChange={handleChangeFormatOpex}
          />
        </Col>
      </Row>
    </>
  );
};

SaleOperation.propTypes = {
  plantsInfo: PropTypes.object,
  projectType: PropTypes.string,
  financingType: PropTypes.string,
  mwp: PropTypes.string,
  years: PropTypes.string,
  anual: PropTypes.string,
  ha: PropTypes.string,
  m: PropTypes.string,
  kv: PropTypes.string,
  handleChange: PropTypes.func,
  handleSelect: PropTypes.func,
  handleDate: PropTypes.func,
  handleRadio: PropTypes.func,
  handleChangeNumber: PropTypes.func,
  e: PropTypes.string,
  formError: PropTypes.object,
  provincesOptions: PropTypes.array,
  municipalityOptions: PropTypes.array,
  handleChangeFormatOpex: PropTypes.func,
};
