import React from "react";
import { Div, Text } from "../../../../styles/Common";
import { theme } from "../../../../styles/theme";
import { Button } from "../../../../components/Button";
import { useTranslation } from "react-i18next";
import DownloadWhite from "../../../../assets/icons/Download-white.svg";
import { Content } from "../../../FinancialModel/components/content";
import { FinancingIcon, SaleIcon, CalendarIcon, CoinsIcon } from "../../../../assets/icons";

export const FinancialDashboard = () => {
  const { t } = useTranslation("financialModel");

  const generalCharacteristics = [
    { label: t("size"), value: "-" },
    { label: t("stakeholders"), value: "-" },
    { label: t("location"), value: "-" },
    { label: t("production"), value: "-" },
    { label: t("opex"), value: "27.600 €" },
  ];

  const dates = [
    { label: t("rtb"), value: "No obtenido" },
    { label: t("cod"), value: "30/03/2011" },
  ];

  const originFunds = [
    { label: t("totalUses"), value: "17.114.501 €" },
    { label: t("epc"), value: "15.800.000 €" },
    { label: t("otherCapex"), value: "-" },
    { label: t("preOperationalCosts"), value: "-" },
    { label: t("upfrontFee"), value: "245.685 €" },
    { label: t("advisors"), value: "30.000 €" },
    { label: t("agencyFee"), value: "22.000 €" },
    { label: t("financialExpenses"), value: "532.171 €" },
    { label: t("dsraEndowment"), value: "484.646 €" },
  ];

  const totals = [
    { label: t("totalSources"), value: "17.114.501 €" },
    { label: t("equity"), value: "4.830.266 €" },
    { label: t("seniorDebt"), value: "12.284.235 €" },
  ];

  const financing = [
    { 
      label: t("amount"),
      value: "-",
      color: theme.colors.blue,
      icon: <CoinsIcon />
    },
    { 
      label: t("interestRate"),
      value: "-",
      color: theme.colors.green,
      icon: <FinancingIcon fill={theme.colors.white} />
    },
    { 
      label: `${t("term")}:`,
      value: "-",
      color: theme.colors.fuchsia,
      icon: <CalendarIcon />
    },
    { 
      label: `${t("commissions")}:`,
      value: "-",
      color: theme.colors.gray500,
      icon: <SaleIcon />
    },
  ];

  const sizingParameters = [
    { label: t("term"), value: "-" },
    { label: t("totalUses"), value: "-" },
    { label: t("totalUses"), value: "-" },
    { label: t("totalUses"), value: "-" },
    { label: t("equityFirst"), value: "-" }
  ];

  return (
    <Div direction="column">
      <Div justify="space-between">
        <Text 
          color={theme.colors.blue}
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.h5i}
        >
          {t("financingDashboard")}
        </Text>
        <Button
          width="186px"
          height="45px"
          icon={<img alt='download' src={DownloadWhite} width={"24px"} height={"24px"} />}
        >
          {t("downloadModel")}
        </Button>
      </Div>
      <Div direction="column" m="25px 0 0 0">
        <Content 
          generalCharacteristics={generalCharacteristics}
          dates={dates}
          originFunds={originFunds}
          totals={totals}
          financing={financing}
          sizingParameters={sizingParameters}
        />
      </Div>
    </Div>
  )
};

export default FinancialDashboard;
