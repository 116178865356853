import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { Div, ModuleContainer, Text } from "../../styles/Common";
import { Link } from "../../components/Link";
import { useTranslation } from "react-i18next";
import arrowBack from "../../assets/icons/arrow-left.svg";
import { Card } from "./styles";
import Tabs from "../../components/Tabs";
import { BasicInformation } from "./components/basicInfo";
import { Button } from "../../components/Button";
import { theme } from "../../styles/theme";
import { JuniorDebt } from "./components/juniorDebt";
import { Intermediation } from "./components/intermediation";
import useCreateSource from "./hooks/useCreateSource";
import { Modal } from "../../components/Modal";
import { LikeIcon } from "../../assets/icons";
import useGetStrategyByType from "./hooks/useGetStrategyByType";
import { unformatNumber } from "../../utilities/helpers";
import useGetInvestingCompanies from "./hooks/usegetInvestingCompanies";
import { SeniorDebt } from "./components/seniorDebt";

export const Sources = () => {
  const { t, i18n } = useTranslation("strategies");
  const locale = i18n.language;
  const navigate = useNavigate();
  const { search } = useLocation();
  const type = new URLSearchParams(search).get("type");
  const create = useCreateSource();
  const { data: dataStrategies, isSuccess: isSuccessDataStrategies } =
    useGetStrategyByType(1);
  const [strategiesOptions, setStrategiesOptions] = useState([]);
  const { data: dataInvestors, isSuccess: isSuccessInvestors } =
    useGetInvestingCompanies();
  const [investorsOptions, setInvestorsOptions] = useState([]);
  const [activeKey, setActiveKey] = useState("1");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [sourceForm, setSourceForm] = useState({
    name: "",
    strategyId: null,
    investorId: null,
    minFinancingTicket: "",
    maxFinancingTicket: null,
    tenor: null,
    isShort: null,
    productionScenario: null,
    PPATenorRequirement: null,
    priceCurveCondition: null,
    priceCurveScenario: null,
    PPACoverage: null,
    DSCRForPPA: null,
    DSCRForMerchant: null,
    interestCalculationBase: null,
    interest: null,
    interestAccured: null,
    euriborFloor: null,
    maximumLeverage: null,
    financingOpeningFee: null,
    financingSpread: null,
    makeWholeComission: null,
    nonCallPeriod: null,
    cashSweepActivation: null,
    CSYearsInPeriod: null,
    CSRatioPeriod1: null,
    swapHedge: null,
    hedge: null,
    intermediationOpeningFee: null,
    intermediationSpread: null,
    debtPayment: null,
    longIntermediationOpeningFee: null,
    longIntermediationSpread: null,
    shortTenor: null,
    balloonLegalMaturity: null,
    shortFinancingOpeningFee: null,
    shortInterest: null,
    shortFinancingSpread: null,
    maxLeverageOverCAPEX: null,
    DSCR: null,
    juniorOpeningFee: null,
    id: null,
    merchantFinancingTenor: null,
    legalTenor: null,
    CSStartingYear: null,
    swapRate: null,
    swapMaturity: null,
    swapStartDate: null,
    juniorSpread: null,
  });

  const juniorDebt = type === "Junior Debt";

  useEffect(() => {
    if (isSuccessDataStrategies) {
      setStrategiesOptions(
        dataStrategies?.data?.data.map((ele) => ({
          label: ele.name,
          value: ele._id,
        }))
      );
    }
  }, [isSuccessDataStrategies, dataStrategies]);

  useEffect(() => {
    if (isSuccessInvestors) {
      setInvestorsOptions(
        dataInvestors?.data.map((ele) => ({
          label: ele.name,
          value: ele._id,
        }))
      );
    }
  }, [isSuccessInvestors, dataInvestors]);

  const handleBack = () => {
    navigate("/strategies");
  };

  const onChange = (key) => {
    setActiveKey(key);
  };

  const handleChangeDec = (event) => {
    const { id, value } = event.target;
    const newSourceForm = { ...sourceForm };

    newSourceForm[id] = value.replace(/\D.,/g, "");
    setSourceForm(newSourceForm);
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    const newSourceForm = { ...sourceForm };

    newSourceForm[id] = value;
    setSourceForm(newSourceForm);
  };

  const handleChangeNumber = (event) => {
    const { id, value } = event.target;
    const newSourceForm = { ...sourceForm };

    newSourceForm[id] = unformatNumber(value, locale).replace(/\D/g, "");
    setSourceForm(newSourceForm);
    setForceUpdate(!forceUpdate);
  };

  const handleChangePercent = (event) => {
    const { id, value } = event.target;
    const newSourceForm = { ...sourceForm };

    let numericValue = value.replace(/[^0-9.,]/g, "");

    const parts = numericValue.split("." || ",");
    if (parts.length > 2) {
      numericValue = parts[0] + "." || "," + parts.slice(1).join("");
    }

    if (numericValue && parseFloat(numericValue) > 100) {
      numericValue = "100";
    }

    newSourceForm[id] = numericValue;
    setSourceForm(newSourceForm);
    setForceUpdate(!forceUpdate);
  };

  const handleSelect = (value, id) => {
    const newSourceForm = { ...sourceForm };

    newSourceForm[id] = value;
    setSourceForm(newSourceForm);
  };

  const handleRadio = (event) => {
    const { name, value } = event.target;
    const newSourceForm = { ...sourceForm };

    newSourceForm[name] = value;
    setSourceForm(newSourceForm);
  };

  const panels = [
    {
      key: "1",
      tab: t("basicInfo"),
      children: (
        <BasicInformation
          sourceForm={sourceForm}
          handleChange={handleChange}
          handleChangeNumber={handleChangeNumber}
          handleSelect={handleSelect}
          handleRadio={handleRadio}
          strategiesOptions={strategiesOptions}
          investorsOptions={investorsOptions}
        />
      ),
      disabled: false,
    },
    {
      key: "2",
      tab: juniorDebt ? t("juniorDebt") : t("seniorDebt"),
      children: juniorDebt ? (
        <JuniorDebt
          sourceForm={sourceForm}
          handleChange={handleChange}
          handleChangePercent={handleChangePercent}
        />
      ) : (
        <SeniorDebt
          sourceForm={sourceForm}
          handleChange={handleChange}
          handleChangePercent={handleChangePercent}
          handleChangeNumber={handleChangeNumber}
          handleRadio={handleRadio}
          handleSelect={handleSelect}
          setSourceForm={setSourceForm}
          handleChangeDec={handleChangeDec}
        />
      ),
    },
    {
      key: "3",
      tab: t("intermediation"),
      children: (
        <Intermediation
          sourceForm={sourceForm}
          handleChangePercent={handleChangePercent}
        />
      ),
    },
  ];

  const stringToNumber = (str) => {
    if (str === null) {
      return;
    }

    let normalizedStr = str.replace(',', '.');
    return parseFloat(normalizedStr);
  };

  const objectsInfo = (activeKey) => {
    const basicPayload = {
      name: sourceForm.name,
      strategyId: sourceForm.strategyId,
      investorId: sourceForm.investorId,
      minFinancingTicket: Number(sourceForm.minFinancingTicket),
      maxFinancingTicket: Number(sourceForm.maxFinancingTicket),
      merchantFinancingTenor: sourceForm.merchantFinancingTenor,
      isShort: sourceForm.isShort,
      ...(sourceForm.id && { id: sourceForm.id }),
    };

    const juniorDebtPayload = {
      tenor: Number(sourceForm.tenor),
      maxLeverageOverCAPEX: Number(sourceForm.maxLeverageOverCAPEX),
      DSCR: Number(sourceForm.DSCR),
      juniorOpeningFee: Number(sourceForm.juniorOpeningFee),
      juniorSpread: Number(sourceForm.juniorSpread),
    };
    let seniorDebtPayload = {
      productionScenario: sourceForm.productionScenario,
      debtPayment: sourceForm.debtPayment,
      tenor: Number(sourceForm.tenor),
      legalTenor: Number(sourceForm.legalTenor),
      PPATenorRequirement: Number(sourceForm.PPATenorRequirement),
      priceCurveCondition: sourceForm.priceCurveCondition,
      priceCurveScenario: sourceForm.priceCurveScenario,
      PPACoverage: stringToNumber(sourceForm.PPACoverage),
      DSCRForPPA: stringToNumber(sourceForm.DSCRForPPA),
      DSCRForMerchant: stringToNumber(sourceForm.DSCRForMerchant),
      interestCalculationBase: sourceForm.interestCalculationBase,
      interest: sourceForm.interest,
      interestAccured: sourceForm.interestAccured,
      euriborFloor: sourceForm.euriborFloor,
      maximumLeverage: stringToNumber(sourceForm.maximumLeverage),
      financingOpeningFee: stringToNumber(sourceForm.financingOpeningFee),
      financingSpread: stringToNumber(sourceForm.financingSpread),
      makeWholeComission: stringToNumber(sourceForm.makeWholeComission),
      nonCallPeriod: sourceForm.nonCallPeriod,
      cashSweepActivation: sourceForm.cashSweepActivation,
    };

    if (sourceForm.cashSweepActivation) {
      seniorDebtPayload = {
        ...seniorDebtPayload,
        CSYearsInPeriod: Number(sourceForm.CSYearsInPeriod),
        CSStartingYear: Number(sourceForm.CSStartingYear),
        CSRatioPeriod1: stringToNumber(sourceForm.CSRatioPeriod1),
      };
    }
    if (sourceForm.swapHedge) {
      seniorDebtPayload = {
        ...seniorDebtPayload,
        euriborFloorSwap: sourceForm.euriborFloorSwap,
        swapRate: stringToNumber(sourceForm.swapRate),
        hedge: stringToNumber(sourceForm.hedge),
        swapStartDate: sourceForm.swapStartDate,
        swapMaturity: sourceForm.swapMaturity,
      };
    }

    switch (activeKey) {
      case "1":
        return basicPayload;
      case "2":
        if (juniorDebt) {
          return {
            ...basicPayload,
            ...juniorDebtPayload,
          };
        } else {
          return {
            ...basicPayload,
            ...seniorDebtPayload,
          };
        }
      case "3":
        if (juniorDebt) {
          return {
            ...basicPayload,
            ...juniorDebtPayload,
            intermediationOpeningFee: stringToNumber(sourceForm.intermediationOpeningFee),
            intermediationSpread: stringToNumber(sourceForm.intermediationSpread),
          };
        } else {
          return {
            ...basicPayload,
            ...seniorDebtPayload,
            intermediationOpeningFee: stringToNumber(sourceForm.intermediationOpeningFee),
            intermediationSpread: stringToNumber(sourceForm.intermediationSpread),
          };
        }
      default:
        break;
    }

    return basicPayload;
  };

  const handleCreate = () => {
    const payload = objectsInfo(activeKey);

    function removeNullAndZero(obj) {
      return Object.fromEntries(
        Object.entries(obj).filter(
          ([, value]) => value !== null && value !== 0 && value !== ""
        )
      );
    }

    const filteredPayload = removeNullAndZero(payload);

    create.reset();
    create.mutate(filteredPayload, {
      onSuccess: (res) => {
        setSourceForm({ ...sourceForm, id: res?.data._id });
        setShowModalSuccess(true);
      },
      onError: (err) => {
        toast.error(t(`common:${err.response.data.result.code}`));
      },
    });
  };

  const handleClose = () => {
    setShowModalSuccess(false);
    switch (activeKey) {
      case "1":
        setActiveKey("2");
        break;
      case "2":
        setActiveKey("3");
        break;
      case "3":
        navigate("/strategies");
        break;
      default:
        break;
    }
  };

  return (
    <ModuleContainer direction="column" padding="0px 69px 52px 32px">
      <Modal open={showModalSuccess} onCancel={handleClose}>
        <Modal.Header
          icon={
            <LikeIcon
              stroke={theme.colors.green}
              width={"50px"}
              height={"50px"}
            />
          }
          iconBackground={theme.colors.green100}
          iconWidth={"50px"}
          iconHeight={"50px"}
          align="-webkit-center"
          padding={"20px 63px 0px 63px"}
          title={t("sourceSuccessTitle")}
          weightTitle={theme.fonts.weight.medium}
        />
        <Modal.Body margin="28px 0 32px 0">
          <Text
            size={theme.fonts.size.default}
            color={theme.colors.gray500}
            style={{ textAlign: "center" }}
          >
            {t("sourceSuccessMessage")}
          </Text>
        </Modal.Body>
      </Modal>
      <Div m="16px 0 36px 0">
        <Link icon={<img alt="back" src={arrowBack} />} onClick={handleBack}>
          {t("goBack")}
        </Link>
      </Div>
      <Div>
        <Card>
          <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onChange}>
            {panels.map((panel) => (
              <Tabs.Panel
                tab={panel.tab}
                key={panel.key}
                disabled={panel.disabled}
              >
                <Div width="100%" direction="column" p="28px 37px 0px 22px">
                  {panel.children}
                </Div>
              </Tabs.Panel>
            ))}
          </Tabs>
          <Div m="32px 0 0 0" gap="16px">
            <Button variant={"outlined"} width="177px" onClick={handleBack}>
              {t("cancel")}
            </Button>
            <Button
              width="207px"
              onClick={handleCreate}
              loading={create.isLoading}
              disabled={activeKey !== "1" && sourceForm.id === null}
            >
              {t("saveInfo")}
            </Button>
          </Div>
        </Card>
      </Div>
    </ModuleContainer>
  );
};
