import React from "react";
import { useQueryClient } from "react-query";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Upload } from "antd";
import toast from "react-hot-toast";
import { Row, Col, Text, Div } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Input } from "../../../components/Input";
import { useTranslation } from "react-i18next";
import { Link } from "../../../components/Link";
import { Select } from "../../../components/Select";
import {
  UploadIcon,
  CloseIcon,
  DeleteIcon,
  EyeIcon,
} from "../../../assets/icons";
import { Tag } from "../../../components/Tag";
import useSaveDocuments from "../../Documents/hooks/useSaveDocuments";
import useDeleteDocument from "../../Documents/hooks/useDeleteDocument";

const PromoterInfo = ({
  promoterInfo,
  setPromoterInfo,
  handleChange,
  handleSelect,
  countriesOptions,
  handleChangeNumber,
}) => {
  const { t } = useTranslation(["newProject"]);
  const queryClient = useQueryClient();
  const saveDocs = useSaveDocuments();
  const deleteDocs = useDeleteDocument();

  const { search } = useLocation();
  const projectId = new URLSearchParams(search).get("projectId");

  const removeCountry = (country) => {
    const updatedCountries = promoterInfo?.company?.countries?.filter(
      (ctry) => ctry !== country
    );
    setPromoterInfo((prevState) => ({
      ...prevState,
      company: {
        ...prevState.company,
        countries: updatedCountries,
      },
    }));
  };

  const maxSize = 500000000;
  const handleUploadDocument = (info) => {
    const { file } = info;
    const newInfo = promoterInfo.document;
    if (saveDocs.isLoading) {
      return;
    }

    if (file?.type && file?.size) {
      if (file.type !== "application/x-msdownload" && file.size < maxSize) {
        newInfo.value = file;
        newInfo.error = false;
      } else {
        newInfo.error = true;
      }
      setPromoterInfo((prevState) => ({
        ...prevState,
        document: newInfo,
      }));
    }

    const payload = {
      description: "",
      name: file.name,
      size: file.size,
      type: file.type,
      projectId: projectId,
      uploadtype: 2,
      category: 1,
      subdomain: "",
      documents: [{ doc: file }],
    };
    saveDocs.reset();
    saveDocs.mutate(
      { ...payload },
      {
        onSuccess: () => {
          toast.success(t("documentsSuccess"));
        },
        onError: (err) => {
          toast.error(
            err?.response?.data?.result?.code?.message.map((error) =>
              t(`common:${error}`)
            )
          );
        },
      }
    );
  };

  const handleDeleteDocument = (documentId) => {
    if (documentId) {
      deleteDocs.reset();
      deleteDocs.mutate(documentId, {
        onSuccess: () => {
          setPromoterInfo((prevState) => ({
            ...prevState,
            document: { value: null, error: false },
          }));
          queryClient.invalidateQueries(["documentsList"]);
          toast.success(t("deleted"));
        },
        onError: (err) => {
          toast.error(
            err?.response?.data?.result?.code?.message.map((error) =>
              t(`common:${error}`)
            )
          );
        },
      });
    } else {
      setPromoterInfo((prevState) => ({
        ...prevState,
        document: { value: null, error: false },
      }));
    }
    setPromoterInfo((prevState) => ({
      ...prevState,
      document: { value: null, error: false },
    }));
  };

  return (
    <>
      <Text
        weight={theme.fonts.weight.medium}
        size={theme.fonts.size.h6}
        color={theme.colors.blue}
        mb="24px"
      >
        {t("promoterInfo")}
      </Text>

      <Col width="100%">
        <Row m=" 0px 0px 10px 0px">
          <Select
            sizeLabel={theme.fonts.size.default}
            weightLabel={theme.fonts.weight.regular}
            borderColor={theme.colors.gray100}
            height="45px"
            label={t("countries")}
            id="countries"
            onChange={(value, item) =>
              handleSelect(value, item, promoterInfo, setPromoterInfo)
            }
            width="358px"
            options={countriesOptions}
            value=""
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
          />
        </Row>
        <Row gap="5px" m="0 0 25px 0" style={{ flexWrap: "wrap" }}>
          {promoterInfo?.company?.countries?.map((country, index) => (
            <Tag
              key={index}
              closable
              closeIcon={
                <CloseIcon
                  width="24px"
                  height="24px"
                  fill={theme.colors.gray300}
                />
              }
              color={theme.colors.gray100}
              width="auto"
              onClose={() => removeCountry(country)}
            >
              <Text
                size={theme.fonts.size.sm}
                weight={theme.fonts.weight.regular}
                color={theme.colors.gray300}
              >
                {t(country)}
              </Text>
            </Tag>
          ))}
        </Row>
        <Row m=" 0px 0px 24px 0px" gap="17px">
          <Input
            sizeLabel={theme.fonts.size.default}
            weightLabel={theme.fonts.weight.regular}
            label={t("yearsExperience")}
            id="experiencesYears"
            onChange={(e) =>
              handleChangeNumber(e, promoterInfo, setPromoterInfo)
            }
            width="100%"
            value={promoterInfo.company.experiencesYears}
          />
          <Input
            sizeLabel={theme.fonts.size.default}
            weightLabel={theme.fonts.weight.regular}
            label={t("completedProjects")}
            id="completedProjects"
            onChange={(e) =>
              handleChangeNumber(e, promoterInfo, setPromoterInfo)
            }
            width="100%"
            value={promoterInfo.company.completedProjects}
          />
          <Input
            sizeLabel={theme.fonts.size.default}
            weightLabel={theme.fonts.weight.regular}
            label={t("mwDeveloped")}
            suffix={"MW"}
            id="totalMW"
            onChange={(e) =>
              handleChangeNumber(e, promoterInfo, setPromoterInfo)
            }
            width="100%"
            value={promoterInfo.company.totalMW}
          />
        </Row>
        <Row m=" 0px 0px 24px 0px">
          <Input
            sizeLabel={theme.fonts.size.default}
            weightLabel={theme.fonts.weight.regular}
            label={t("nameCompany")}
            id="companyName"
            onChange={(e) => handleChange(e, promoterInfo, setPromoterInfo)}
            width="100%"
            value={promoterInfo.companyName}
          />
        </Row>
        <Row m=" 0px 0px 8px 0px">
          <Input
            sizeLabel={theme.fonts.size.default}
            weightLabel={theme.fonts.weight.regular}
            label={t("descriptionOptional")}
            id="description"
            onChange={(e) => handleChange(e, promoterInfo, setPromoterInfo)}
            width="100%"
            height="106px"
            value={promoterInfo.description}
          />
        </Row>
        <Row m=" 0px 0px 24px 0px">
          <Text color={theme.colors.gray300} size={theme.fonts.size.xs}>
            {t("briefDescription")}
          </Text>
        </Row>
        <Row>
          {!promoterInfo.document.value ? (
            <Div
              style={{ cursor: "pointer" }}
              direction="column"
              background={theme.colors.green100}
              width="100%"
              radius="7px"
              justify="center"
              align="center"
              p="16px 0 24px 0"
              border="1px dashed"
              borderColor={
                promoterInfo.document.error
                  ? theme.colors.red
                  : theme.colors.gray200
              }
              height="139px"
            >
              <Upload
                multiple={false}
                showUploadList={false}
                onChange={handleUploadDocument}
              >
                <Div
                  background={theme.colors.gray100}
                  radius="50%"
                  width="38px"
                  height="38px"
                  justify="center"
                  align="center"
                  m="0 0 8px 0"
                >
                  <UploadIcon fill={theme.colors.gray200} />
                </Div>
                <Text mb="7px" color={theme.colors.gray200}>
                  {t("documentPromoter")}
                </Text>
                <Text color={theme.colors.green}>{t("click")}</Text>
              </Upload>
              {promoterInfo.document.error && (
                <Text color={theme.colors.red} size={theme.fonts.size.xs}>
                  {t("invalidDocument")}
                </Text>
              )}
            </Div>
          ) : (
            <Div
              background={theme.colors.green100}
              radius="7px"
              justify="left"
              align="center"
              p="10px 18px 10px 12px"
              border="1px solid"
              borderColor={theme.colors.gray100}
              gap="55px"
            >
              <Col align="left">
                <Text mb="7px" color={theme.colors.gray300}>
                  {promoterInfo.document.value.name}
                </Text>
                <Text size={theme.fonts.size.sm} color={theme.colors.green}>
                  {(promoterInfo.document.value.size / (1024 * 1024)).toFixed(
                    2
                  )}{" "}
                  Mb
                </Text>
              </Col>
              <Row gap="13px">
                <Div
                  radius="50%"
                  width="38px"
                  height="38px"
                  justify="center"
                  align="center"
                  m="0 0 8px 0"
                >
                  <Link onClick={() => handleDeleteDocument(document?._id)}>
                    <DeleteIcon fill={theme.colors.gray200} />
                  </Link>
                </Div>
                <Div
                  radius="50%"
                  width="38px"
                  height="38px"
                  justify="center"
                  align="center"
                  m="0 0 8px 0"
                >
                  <Link>
                    <EyeIcon fill={theme.colors.gray200} />
                  </Link>
                </Div>
              </Row>
            </Div>
          )}
        </Row>
      </Col>
    </>
  );
};

PromoterInfo.propTypes = {
  promoterInfo: PropTypes.object,
  setPromoterInfo: PropTypes.func,
  handleChange: PropTypes.func,
  handleSelect: PropTypes.func,
  countriesOptions: PropTypes.array,
  handleChangeNumber: PropTypes.func,
};

export default PromoterInfo;
