import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { theme } from "../../../../styles/theme";
import { Row, Text } from "../../../../styles/Common";
import { Input } from "../../../../components/Input";
import { Select } from "../../../../components/Select2";
import { productionPriceCurveOptionsForAnexOne } from "../../../../utilities/generalOptions";

export const Annex1 = ({
  strategyData,
  handleChangeStrategy,
  tabActiveKey,
  handleSelectStrategy,
  p50Condition,
}) => {
  const { t } = useTranslation("teaserDocumentation");
  return (
    <>
      <Row gap="23px" m="33.5px 0 25px 0">
        <Input
          label={t("underlyingDebtTenor")}
          id="underlyingDebtTenor"
          width="310px"
          sizeLabel={theme.fonts.size.default}
          value={
            strategyData[Number(tabActiveKey) - 1].anexoOne.underlyingDebtTenor
          }
          onChange={(event) => handleChangeStrategy(event, "anexoOne")}
        />
      </Row>
      <Row>
        <Text
          weight={theme.fonts.weight.medium}
          color={theme.colors.blue}
          mb="18px"
        >
          {t("productionScenario")}
        </Text>
      </Row>
      <Row gap="33px" m=" 0 0 25px 0">
        {p50Condition === "P90" ? (
          <Input
            label="P90"
            id="p90"
            type="number"
            width="149px"
            sizeLabel={theme.fonts.size.default}
            value={strategyData[Number(tabActiveKey) - 1].anexoOne.p90}
            onChange={(event) => handleChangeStrategy(event, "anexoOne")}
          />
        ) : (
          <Input
            label="P50"
            id="p50"
            type="number"
            width="149px"
            sizeLabel={theme.fonts.size.default}
            value={strategyData[Number(tabActiveKey) - 1].anexoOne.p50}
            onChange={(event) => handleChangeStrategy(event, "anexoOne")}
          />
        )}
        <Input
          label={t("annualDegradation")}
          id="annualDegradation"
          type="number"
          width="310px"
          suffix="%"
          sizeLabel={theme.fonts.size.default}
          value={
            strategyData[Number(tabActiveKey) - 1].anexoOne.annualDegradation
          }
          onChange={(event) => handleChangeStrategy(event, "anexoOne")}
        />
      </Row>
      <Row>
        <Text
          weight={theme.fonts.weight.medium}
          color={theme.colors.blue}
          mb="18px"
        >
          {t("merchantRevenues")}
        </Text>
      </Row>
      <Row gap="33px" m=" 0 0 25px 0">
        <Input
          label={t("averageDscr")}
          id="averageDscr"
          type="number"
          width="310px"
          sizeLabel={theme.fonts.size.default}
          value={strategyData[Number(tabActiveKey) - 1].anexoOne.averageDscr}
          onChange={(event) => handleChangeStrategy(event, "anexoOne")}
        />
        <Select
          width="310px"
          label={t("priceCurve")}
          placeholder="Seleccionar"
          sizeLabel={theme.fonts.size.default}
          options={productionPriceCurveOptionsForAnexOne}
          value={strategyData[Number(tabActiveKey) - 1].anexoOne.priceCurve}
          onChange={(value) =>
            handleSelectStrategy(value, "priceCurve", "anexoOne")
          }
        />
      </Row>
      <Row>
        <Text
          weight={theme.fonts.weight.medium}
          color={theme.colors.blue}
          mb="18px"
        >
          {t("inflationCurve")}
        </Text>
      </Row>
      <Row gap="33px" m=" 0 0 25px 0">
        <Row>
          <Input
            label={t("yearsRange")}
            id="yearsRangeFrom"
            type="number"
            width="149px"
            sizeLabel={theme.fonts.size.default}
            value={
              strategyData[Number(tabActiveKey) - 1].anexoOne.yearsRangeFrom
            }
            onChange={(event) => handleChangeStrategy(event, "anexoOne")}
          />
          <Text mt="45px">-</Text>
          <Input
            id="yearsRangeTo"
            type="number"
            width="149px"
            sizeLabel={theme.fonts.size.default}
            style={{ margin: "27px 0 0 0" }}
            value={strategyData[Number(tabActiveKey) - 1].anexoOne.yearsRangeTo}
            onChange={(event) => handleChangeStrategy(event, "anexoOne")}
          />
        </Row>
        <Input
          label={t("inflationPercent")}
          id="inflationPercent"
          type="number"
          width="100%"
          suffix="%"
          sizeLabel={theme.fonts.size.default}
          value={
            strategyData[Number(tabActiveKey) - 1].anexoOne.inflationPercent
          }
          onChange={(event) => handleChangeStrategy(event, "anexoOne")}
        />
        <Input
          label={t("startYear")}
          id="startFromYear"
          type="number"
          width="100%"
          suffix={t("onwards")}
          sizeLabel={theme.fonts.size.default}
          value={strategyData[Number(tabActiveKey) - 1].anexoOne.startFromYear}
          onChange={(event) => handleChangeStrategy(event, "anexoOne")}
        />
      </Row>
      <Row>
        <Text
          weight={theme.fonts.weight.medium}
          color={theme.colors.blue}
          mb="18px"
        >
          {t("operationCost")}
        </Text>
      </Row>
      <Row gap="33px" m=" 0 0 25px 0">
        <Input
          label={t("alter5PlatformFeeText")}
          id="operationCostAlter5PlatformFeeText"
          width="100%"
          height="138px"
          placeholder={t("alter5TextDescription")}
          sizeLabel={theme.fonts.size.default}
          value={
            strategyData[Number(tabActiveKey) - 1].anexoOne
              .operationCostAlter5PlatformFeeText
          }
          onChange={(event) => handleChangeStrategy(event, "anexoOne")}
        />
      </Row>
    </>
  );
};

Annex1.propTypes = {
  strategyData: PropTypes.array,
  handleChangeStrategy: PropTypes.func,
  tabActiveKey: PropTypes.string,
  handleSelectStrategy: PropTypes.func,
  p50Condition: PropTypes.string,
};
