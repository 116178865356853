import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { Div, Row, Text } from "../../../../styles/Common";
import { theme } from "../../../../styles/theme";
import useGetQyA from "../../hooks/useGetQ&A";
import messagesImg from "../../../../assets/images/messages.svg";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/Button";
import { DrawerQuestions } from "./DrawerQuestions";
import useSendQuestions from "../../hooks/useSendQuestions";
import { CardList, SubCardList, WrapperDate } from "./styles";
import { dateFormat } from "../../../../utilities/helpers";
import { Link } from "../../../../components/Link";
import { ArrowDownIcon, ArrowRight } from "../../../../assets/icons";

export const ProjectQuestions = ({ projectId }) => {
  const { t } = useTranslation(["investorProjectDetail"]);
  const { data, isSuccess, isLoading, refetch } = useGetQyA(projectId);
  const sendQuestion = useSendQuestions();
  const [questionsList, setQuestionsList] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [questionsInfo, setQuestionsInfo] = useState([{ topic: "", answer: "" }]);
  const [openAnswerIndex, setOpenAnswerIndex] = useState(null);

  const topicOptions = [
    { label: "Permisos", value: "Permisos" },
    { label: "Medio ambiente", value: "Medio ambiente" },
    { label: "Técnica", value: "Técnica" },
    { label: "Datos financieros", value: "Datos financieros" },
    { label: "Producción", value: "Producción" },
    { label: "Impuestos", value: "Impuestos" },
    { label: "Interconexión", value: "Interconexión" },
    { label: "Venta de proyectos", value: "Venta de proyectos" },
    { label: "Estructura financiera", value: "Estructura financiera" },
    { label: "Proveedores", value: "Proveedores" },
    { label: "Terreno", value: "Terreno" },
    { label: "Costes de desarrollo", value: "Costes de desarrollo" },
    { label: "Proceso de transacción", value: "Proceso de transacción" },
    { label: "Otros", value: "Otros" }
  ];

  useEffect(() => {
    if (isSuccess) {
      setQuestionsList(data?.data.data);
    }
  }, [isSuccess, data]);

  const handleOpen = () => {
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
    setQuestionsInfo([{ topic: "", answer: "" }]);
    refetch();
  };

  const handleAddQuestion = () => {
    setQuestionsInfo([...questionsInfo, { topic: "", answer: "" }]);
  };

  const handleDelete = (index) => {
    const updatedQuestions = questionsInfo.filter((ele, indx) => indx !== index);
    setQuestionsInfo(updatedQuestions);
  };

  const handleSelect = (value, id, index) => {
    const newQuestion = [...questionsInfo];
    newQuestion[index][id] = value;
    setQuestionsInfo(newQuestion);
  };

  const handleChange = (event, index) => {
    const { id, value } = event.target;
    const newQuestion = [...questionsInfo];
    newQuestion[index][id] = value;
    setQuestionsInfo(newQuestion);
  };

  const handleSave = () => {
    const filteredQuestions = questionsInfo.filter(objeto =>
      Object.values(objeto).some(valor => valor.trim() !== '')
    );

    const isValid = filteredQuestions.every(obj =>
      Object.values(obj).every(valor => valor)
    );

    const payload = questionsInfo.map((questionInfo) => ({
      ...questionInfo,
      projectId: projectId,
    }));

    if (!isValid) {
      toast.error(t("drawerQuestions.errorFields"));
    } else {
      sendQuestion.reset();
      sendQuestion.mutate(
        [...payload],
        {
          onSuccess: () => {
            toast.success(t("drawerQuestions.questionSaved"));
            setOpenDrawer(false);
            setQuestionsInfo([{ topic: "", answer: "" }]);
            refetch();
          },
          onError: (err) => {
            toast.error(t(`common:${err?.response?.data?.result?.code}`)) ||
              "No ha sido posible guardar las preguntas, por favor intenta nuevamente";
          },
        }
      );
    }
  };

  const handleOpenAnswer = (index) => {
    setOpenAnswerIndex(openAnswerIndex === index ? null : index);
  };

  const buttonProps = {
    height: "30px",
    variant: "outlined"
  }

  return (
    <Div direction={questionsList.length === 0 ? "row" : "column"}>
      <DrawerQuestions
        openDrawer={openDrawer}
        handleClose={handleClose}
        questionsInfo={questionsInfo}
        handleAddQuestion={handleAddQuestion}
        handleDelete={handleDelete}
        handleSelect={handleSelect}
        handleChange={handleChange}
        handleSave={handleSave}
        isLoading={sendQuestion.isLoading}
        topicOptions={topicOptions}
      />
      <Text
        weight={theme.fonts.weight.medium}
        size={theme.fonts.size.h5i}
        color={theme.colors.blue}
        mb="32px"
      >
        Q&A
      </Text>
      {isLoading ? (
        <Div width="100%" height="100%" justify="center" align="center">
          <ReactLoading color={theme.colors.green} />
        </Div>
      ) : (
        questionsList.length === 0 ? (
          <Div width="100%" direction="column" justify="center" align="center">
            <img alt="messages" src={messagesImg} />
            <Text
              size={theme.fonts.size.xs}
              color={theme.colors.gray300}
              lineHeight="21px"
              mt="17px"
              mb="22px"
              style={{ width: "182px" }}
            >
              {t("questionsMessages")}
            </Text>
            <Button
              {...buttonProps}
              width="182px"
              style={{ marginBottom: "28px" }}
              onClick={handleOpen}
            >
              {t("sendQuestion")}
            </Button>
          </Div>
        ) : (
          <CardList>
            <Div
              direction="column"
              style={{
                maxHeight: "410px",
                height: "410px",
                overflowY: "auto",
              }}
            >
              {questionsList.map((ele, indx) => (
                <SubCardList key={indx} background={indx % 2 === 0 ? theme.colors.white : theme.colors.light}>
                  <Row gap="26px" align="center" m="0 0 8px 0">
                    <Text color={theme.colors.gray500} weight={theme.fonts.weight.medium}>
                      {`${t("topic")}: ${ele.topic}`}
                    </Text>
                    <WrapperDate>
                      <Text color={theme.colors.gray300} size={theme.fonts.size.xxs}>
                        {dateFormat(ele.createdAt)}
                      </Text>
                    </WrapperDate>
                  </Row>
                  <Text color={theme.colors.gray500} lineHeight="25px" mb="10px">
                    {ele.answer}
                  </Text>
                  <Link
                    icon={openAnswerIndex === indx ?
                      <ArrowDownIcon fill={theme.colors.gray500} width={"24px"} />
                      : <ArrowRight fill={theme.colors.gray500} width={"18px"} />
                    }
                    size={theme.fonts.size.xs}
                    color={theme.colors.blue}
                    weight={theme.fonts.weight.regular}
                    onClick={() => handleOpenAnswer(indx)}
                  >
                    {t("answer")}
                  </Link>
                  {openAnswerIndex === indx && (
                    <Div p="11px 9px 0 9px">
                      <Text size={theme.fonts.size.sm} color={theme.colors.gray300}>
                        {ele.question || ""}
                      </Text>
                    </Div>
                  )}
                </SubCardList>
              ))}
            </Div>
            <Div m="27px 0 0 0">
              <Button
                {...buttonProps}
                width="183px"
                onClick={handleOpen}
              >
                {t("sendQuestion")}
              </Button>
            </Div>
          </CardList>
        )
      )}
    </Div>
  );
};

ProjectQuestions.propTypes = {
  projectId: PropTypes.string,
};
