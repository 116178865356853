import React from "react";
import { Div, Row, Text } from "../../../../styles/Common";
import { theme } from "../../../../styles/theme";
import { Card } from "../Questions/styles";
import { FinancingIcon, SaleIcon, CalendarIcon, CoinsIcon } from "../../../../assets/icons";
import { useTranslation } from "react-i18next";

export const FinancialDashboardDetailProject = () => {
    const { t } = useTranslation("financialDashboardDetailProject");
    return (
        <>
            <Div direction="column" style={{ paddingBottom: "6.25rem" }}>
                <Div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                    <Div style={{ width: '50%', height: '340px' }}>
                        <Card width="100%" height="auto" padding="23px 27px 36px 24px">
                            <Text weight={theme.fonts.weight.medium} size={theme.fonts.size.h6} color={theme.colors.blue} mb="16px">
                                {t("generalFeatures")}
                            </Text>
                            <Row justify="space-between" m="0 0 1rem 0">
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm}>{t("size")}</Text>
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm} style={{ textAlign: 'left' }}>-</Text>
                            </Row>
                            <Row justify="space-between" m="0 0 1rem 0">
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm}>{t("stakeholders")}</Text>
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm} style={{ textAlign: 'left' }}>-</Text>
                            </Row>
                            <Row justify="space-between" m="0 0 1rem 0">
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm}>{t("location")}</Text>
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm} style={{ textAlign: 'left' }}>-</Text>
                            </Row>
                            <Row justify="space-between" m="0 0 1rem 0">
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm}>{t("production")}</Text>
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm} style={{ textAlign: 'left' }}>-</Text>
                            </Row>
                            <Row justify="space-between" m="0 0 1rem 0">
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm}>{t("opex")}</Text>
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm} style={{ textAlign: 'left' }}>27.600 €</Text>
                            </Row>
                            <Text weight={theme.fonts.weight.medium} size={theme.fonts.size.sm} color={theme.colors.green} mb="16px">
                                {t("dates")}
                            </Text>
                            <Row justify="space-between" m="0 0 1rem 0">
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm}>{t("rtb")}</Text>
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm} style={{ textAlign: 'left' }}>{t("rtbNotObtained")}</Text>
                            </Row>
                            <Row justify="space-between" m="0 0 1rem 0">
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm}>{t("cod")}</Text>
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm} style={{ textAlign: 'left' }}>{t("codDate")}</Text>
                            </Row>
                        </Card>
                    </Div>
                    <Div style={{ width: '50%', height: 'auto' }}>
                        <Card width="100%" height="auto" padding="23px 27px 36px 24px">
                            <Text weight={theme.fonts.weight.medium} size={theme.fonts.size.h6} color={theme.colors.blue} mb="16px">
                                {t("fundSource")}
                            </Text>
                            <Row justify="space-between" m="0 0 1rem 0">
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm}>{t("totalUses")}</Text>
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm} style={{ textAlign: 'left' }}>17.144.501 €</Text>
                            </Row>
                            <Row justify="space-between" m="0 0 1rem 0">
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm}>{t("epc")}</Text>
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm} style={{ textAlign: 'left' }}>15.800.000 €</Text>
                            </Row>
                            <Row justify="space-between" m="0 0 1rem 0">
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm}>{t("otherCapex")}</Text>
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm} style={{ textAlign: 'left' }}>-</Text>
                            </Row>
                            <Row justify="space-between" m="0 0 1rem 0">
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm}>{t("preOperationalCosts")}</Text>
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm} style={{ textAlign: 'left' }}>-</Text>
                            </Row>
                            <Row justify="space-between" m="0 0 1rem 0">
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm}>{t("upfrontFee")}</Text>
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm} style={{ textAlign: 'left' }}>245.685 €</Text>
                            </Row>
                            <Row justify="space-between" m="0 0 1rem 0">
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm}>{t("advisors")}</Text>
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm} style={{ textAlign: 'left' }}>30.000 €</Text>
                            </Row>
                            <Row justify="space-between" m="0 0 1rem 0">
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm}>{t("agencyFee")}</Text>
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm} style={{ textAlign: 'left' }}>-</Text>
                            </Row>
                            <Row justify="space-between" m="0 0 1rem 0">
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm}>{t("financialExpenses")}</Text>
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm} style={{ textAlign: 'left' }}>532.117 €</Text>
                            </Row>
                            <Row justify="space-between" m="0 0 1.5rem 0">
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm}>{t("dsraEndowment")}</Text>
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm} style={{ textAlign: 'left' }}>484.646 €</Text>
                            </Row>
                            <hr style={{ border: `1px solid ${theme.colors.gray100}`, width: "100%" }} />
                            <Text weight={theme.fonts.weight.medium} size={theme.fonts.size.sm} color={theme.colors.green}
                                style={{ marginTop: "1.5rem" }}>
                                {t("totalSources")}
                            </Text>
                            <Row justify="space-between" m="1rem 0 1rem 0">
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm}>{t("equity")}</Text>
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm} style={{ textAlign: 'left' }}>4.830.256 €</Text>
                            </Row>
                            <Row justify="space-between" m="0 0 1rem 0">
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm}>{t("seniorDebt")}</Text>
                                <Text color={theme.colors.gray500} size={theme.fonts.size.sm} style={{ textAlign: 'left' }}>12.284.235 €</Text>
                            </Row>
                        </Card>
                    </Div>
                </Div>
                <Div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', marginTop: '-15%', width: '48%' }}>
                    <Card
                        height="200px"
                        padding="16px"
                        style={{
                            backgroundColor: theme.colors.blue,
                            color: theme.colors.white,
                            display: 'flex',
                            textAlign: 'center',
                            width: '25%'
                        }}
                    >
                        <Div style={{
                            backgroundColor: '#FFFFFF34',
                            borderRadius: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '3.5rem',
                            height: '3.5rem',
                            position: 'relative',
                            left: '25%'
                        }}>
                            <CoinsIcon fill={theme.colors.white} style={{ width: '2.5rem', height: '2.5rem' }} />
                        </Div>
                        <Div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                            <Text style={{ color: theme.colors.white, fontSize: theme.fonts.size.md, lineHeight: '1.5', textAlign: 'center' }}>{t("maxFinancingAmount")}</Text>
                            <Text style={{ color: theme.colors.white, fontSize: theme.fonts.size.md, lineHeight: '1.5', textAlign: 'center' }}>{t("maxFinancingAmountValue")}</Text>
                        </Div>
                    </Card>
                    <Div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                        <Card
                            width="100%"
                            height="50px"
                            padding="16px"
                            style={{
                                backgroundColor: theme.colors.gray200,
                                color: theme.colors.white,
                                display: 'flex',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                <Div style={{
                                    backgroundColor: '#FFFFFF34',
                                    borderRadius: '10px',
                                    width: '3rem',
                                    height: '3rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <CalendarIcon fill={theme.colors.white} style={{ width: '2rem', height: '2rem' }} />
                                </Div>
                                <Div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <Text style={{ color: theme.colors.white, textAlign: 'left' }}>{t("term")}</Text>
                                    <Text style={{ color: theme.colors.white, textAlign: 'left' }}>{t("termValue")}</Text>
                                </Div>
                            </Div>
                        </Card>
                        <Card
                            width="100%"
                            height="50px"
                            padding="16px"
                            style={{
                                backgroundColor: theme.colors.green,
                                color: theme.colors.white,
                                display: 'flex',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                <Div style={{
                                    backgroundColor: '#FFFFFF34',
                                    borderRadius: '10px',
                                    width: '3rem',
                                    height: '3rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <FinancingIcon fill={theme.colors.white} style={{ width: '2rem', height: '2rem' }} />
                                </Div>
                                <Div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <Text style={{ color: theme.colors.white, textAlign: 'left' }}>{t("profitability")}</Text>
                                    <Text style={{ color: theme.colors.white, textAlign: 'left' }}>{t("profitabilityValue")}</Text>
                                </Div>
                            </Div>
                        </Card>
                    </Div>
                    <Card                        
                        height="200px"
                        padding="16px"
                        style={{
                            backgroundColor: theme.colors.gray500,
                            color: theme.colors.white,
                            display: 'flex',
                            textAlign: 'center',
                            width:"25%"
                        }}
                    >
                        <Div style={{
                            backgroundColor: '#FFFFFF34',
                            borderRadius: '10px',
                            width: '3.5rem',
                            height: '3.5rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            left: '30%'
                        }}>
                            <SaleIcon fill={theme.colors.white} style={{ width: '2.5rem', height: '2.5rem' }} />
                        </Div>
                        <Div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                            <Text style={{ color: theme.colors.white, fontSize: theme.fonts.size.md, lineHeight: '1.5', textAlign: 'center' }}>{t("financingCommissions")}</Text>
                            <Text style={{ color: theme.colors.white, fontSize: theme.fonts.size.md, lineHeight: '1.5', textAlign: 'center' }}>{t("financingCommissionsValue")}</Text>
                        </Div>
                    </Card>
                </Div>
            </Div>
        </>
    );
};

export default FinancialDashboardDetailProject;
