import React, { useState } from "react";
import PropTypes from "prop-types";
import { Div, Text } from "../../../../styles/Common";
import { CardInvestorProj, DashedLine, Divider, WrapperImage } from "../styles";
import { theme } from "../../../../styles/theme";
import { CloseIcon, SunIcon, WindyIcon } from "../../../../assets/icons";
import { Link } from "../../../../components/Link";
import { Button } from "../../../../components/Button";
import { useTranslation } from "react-i18next";
import { dateFormat } from "../../../../utilities/helpers";
import ModalDiscardProject from "./ModalDiscardProject";

export const SaleProjects = ({ projectsData, returnImg, handleDetail }) => {
  const { t } = useTranslation("projectsTabsView");

  const [showModal, setShowModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Div direction="column" gap="24px">
      <ModalDiscardProject
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedProject={selectedProject}
        instanceType={"sale"}
        selectedProjectId={selectedProjectId}
      />
      {projectsData.map((ele, indx) => (
        <CardInvestorProj key={indx}>
          <WrapperImage backgImg={returnImg(ele.projectType.type.name)}>
            <Text
              color={theme.colors.white}
              size={theme.fonts.size.h6}
              weight={theme.fonts.weight.medium}
            >
              {ele.teaserName}
            </Text>
            <Div align="center" m="3px 0 0 0">
              {ele.projectType.type.name === "solar" ? (
                <SunIcon fill={theme.colors.yellow200} width={24} height={24} />
              ) : (
                <WindyIcon
                  fill={theme.colors.yellow200}
                  width={24}
                  height={24}
                />
              )}
              <Text color={theme.colors.yellow200}>
                {t(`${ele.projectType.type.name}`)}
              </Text>
            </Div>
            <DashedLine />
            <Div justify="space-between">
              <Div direction="column">
                <Text
                  color={theme.colors.white}
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.semibold}
                >
                  {t("size")}
                </Text>
                <Text
                  color={theme.colors.white}
                  size={theme.fonts.size.h6}
                  weight={theme.fonts.weight.semibold}
                >
                  {`${ele.teaserProjectSize || "-"} MWp`}
                </Text>
              </Div>
              <Div direction="column">
                <Text
                  color={theme.colors.white}
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.semibold}
                >
                  {t("price")}
                </Text>
                <Text
                  color={theme.colors.white}
                  size={theme.fonts.size.h6}
                  weight={theme.fonts.weight.semibold}
                >
                  {`€ ${ele.teaserTargetPrice || "-"}`}
                </Text>
              </Div>
            </Div>
          </WrapperImage>
          <Div direction="column" p="20px 25.5px 22px 0" width="100%">
            <Div justify="space-between" align="center" width="100%" gap="2px">
              <Div direction="column" align="flex-start">
                <Text
                  color={theme.colors.blue}
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.semibold}
                >
                  {t("projectPhase")}:
                </Text>
                <Text
                  color={theme.colors.gray200}
                  size={theme.fonts.size.default}
                >
                  {ele.teaserProjectStatus || "-"}
                </Text>
              </Div>
              <Link
                onClick={() => {
                  setSelectedProject(ele.name);
                  setSelectedProjectId(ele.id);
                  setShowModal(true);
                }}
              >
                Descartar proyecto
                <CloseIcon fill={theme.colors.green} />
              </Link>
            </Div>
            <Divider />
            <Div justify="space-between" align="center" width="100%" gap="2px">
              <Div direction="column" align="flex-start">
                <Text
                  color={theme.colors.blue}
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.semibold}
                >
                  {t("closing")}:
                </Text>
                <Text
                  color={theme.colors.gray200}
                  size={theme.fonts.size.default}
                >
                  {ele.teasercloseDate ? dateFormat(ele.teasercloseDate) : "-"}
                </Text>
              </Div>
              <Button
                onClick={() => handleDetail(ele._id)}
              >
                Acceso Inversión
              </Button>
            </Div>
          </Div>
        </CardInvestorProj>
      ))}
    </Div>
  );
};

SaleProjects.propTypes = {
  projectsData: PropTypes.array,
  returnImg: PropTypes.func,
  handleDetail: PropTypes.func,
};
