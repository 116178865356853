import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select2";
import { useTranslation } from "react-i18next";
import RadioGroup from "../../../components/Radio2/Group";
import { Radio } from "../../../components/Radio2";
import { Div, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import years from "../../../assets/icons/years.svg";
import percent from "../../../assets/icons/percent.svg";
import { ProductionScenarioOptions } from "../../../utilities/strategiesOptions";
import { amortizationProfileOptions } from "../../../utilities/generalOptions";

export const Sizing = ({
  sourceForm,
  handleChangeNumber,
  handleSelect,
  handleRadio,
  handleChangePercent,
  handleChangeDec
}) => {
  const { t } = useTranslation("strategies");

  const productionPriceCurveOptions = [
    { value: "Central curve", label: "Central curve" },
    { value: "Low curve", label: "Low curve" },
    { value: "Avg. central and low", label: "Avg. central and low" },
  ];

  const InterestCalculationBase = [
    { value: "360", label: "360" },
    { value: "365", label: "365" }
  ];

  const interestOptions = [
    { value: 2, label: "Variable" },
    { value: 1, label: "Fixed" },
  ];

  return (
    <Row gutter={[32, 32]} style={{ width: '100%', marginTop: "32px" }}>
      <Col md={8}>
        <Select 
          label={t("seniorDebtForm.prodScenery")}
          placeholder={t("select")}
          options={ProductionScenarioOptions}
          value={sourceForm.productionScenario}
          onChange={(e) => handleSelect(e, "productionScenario")}
          arrowColor={theme.colors.gray200}
          width={"100%"}
        />
      </Col>
      <Col md={8}>
        <Select 
          label={t("seniorDebtForm.amortizationType")}
          placeholder={t("select")}
          options={amortizationProfileOptions}
          value={sourceForm.debtPayment}
          onChange={(e) => handleSelect(e, "debtPayment")}
          arrowColor={theme.colors.gray200}
          width={"100%"}
        />
      </Col>
      <Col md={8}>
        <Input 
          id="tenor"
          label={t("seniorDebtForm.tenor")}
          value={sourceForm.tenor}
          onChange={handleChangeNumber}
          suffix={<img alt="years" src={years} />}
        />
      </Col>
      <Col md={8}>
        <Input 
          id="legalTenor"
          label={t("seniorDebtForm.legalTenor")}
          value={sourceForm.legalTenor}
          onChange={handleChangeNumber}
          suffix={<img alt="years" src={years} />}
        />
      </Col>
      <Col md={8}>
        <Input 
          id="PPATenorRequirement"
          label={t("seniorDebtForm.ppaTerm")}
          value={sourceForm.PPATenorRequirement}
          onChange={handleChangeNumber}
          suffix={<img alt="years" src={years} />}
        />
      </Col>
      <Col md={8}>
        <Div direction="column" align="start" gap="16px">
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray200}
            align="left"
          >
            {t("seniorDebtForm.priceCurveCondition")}
          </Text>
          <RadioGroup 
            name="priceCurveCondition"
            gap="50px" 
            value={sourceForm.priceCurveCondition} 
            onChange={handleRadio}
          >         
            <Radio label={t("seniorDebtForm.yes")} value={true} />
            <Radio label={t("seniorDebtForm.no")} value={false} />
          </RadioGroup>
        </Div>
      </Col>
      <Col md={8}>
        <Select 
          label={t("seniorDebtForm.priceCurveScenario")}
          placeholder={t("select")}
          options={productionPriceCurveOptions}
          value={sourceForm.priceCurveScenario}
          onChange={(e) => handleSelect(e, "priceCurveScenario")}
          arrowColor={theme.colors.gray200}
          width={"100%"}
        />
      </Col>
      <Col md={8}>
        <Input 
          id="PPACoverage"
          label={t("seniorDebtForm.ppaCoverage")}
          value={sourceForm.PPACoverage}
          onChange={handleChangePercent}
          suffix={<img alt="percent" src={percent} />}
        />
      </Col>
      <Col md={8}>
        <Input 
          id="DSCRForPPA"
          label={t("seniorDebtForm.DSCRPPA")}
          value={sourceForm.DSCRForPPA}
          onChange={handleChangeDec}
        />
      </Col>
      <Col md={8}>
        <Input 
          id="DSCRForMerchant"
          label={t("seniorDebtForm.DSCRMerchant")}
          value={sourceForm.DSCRForMerchant}
          onChange={handleChangeDec}
        />
      </Col>
      <Col md={8}>
        <Select 
          label={t("seniorDebtForm.baseCalc")}
          placeholder={t("select")}
          options={InterestCalculationBase}
          value={sourceForm.interestCalculationBase}
          onChange={(e) => handleSelect(e, "interestCalculationBase")}
          arrowColor={theme.colors.gray200}
          width={"100%"}
        />
      </Col>
      <Col md={8}>
        <Select 
          label={t("seniorDebtForm.interest")}
          placeholder={t("select")}
          options={interestOptions}
          value={sourceForm.interest}
          onChange={(e) => handleSelect(e, "interest")}
          arrowColor={theme.colors.gray200}
          width={"100%"}
        />
      </Col>
      <Col md={8}>
        <Div direction="column" align="start" gap="16px">
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray200}
            align="left"
          >
            {t("seniorDebtForm.capitalizationInterest")}
          </Text>
          <RadioGroup 
            name="interestAccured"
            gap="50px" 
            value={sourceForm.interestAccured} 
            onChange={handleRadio}
          >         
            <Radio label={t("seniorDebtForm.yes")} value={true} />
            <Radio label={t("seniorDebtForm.no")} value={false} />
          </RadioGroup>
        </Div>
      </Col>
      <Col md={8}>
        <Div direction="column" align="start" gap="16px">
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray200}
            align="left"
          >
            {t("seniorDebtForm.euriborFloor")}
          </Text>
          <RadioGroup 
            name="euriborFloor"
            gap="50px" 
            value={sourceForm.euriborFloor} 
            onChange={handleRadio}
          >         
            <Radio label={t("seniorDebtForm.yes")} value={true} />
            <Radio label={t("seniorDebtForm.no")} value={false} />
          </RadioGroup>
        </Div>
      </Col>
      <Col md={8}>
        <Input 
          id="maximumLeverage"
          label={t("seniorDebtForm.maxLeverageCAPEX")}
          value={sourceForm.maximumLeverage}
          onChange={handleChangePercent}
          suffix={<img alt="percent" src={percent} />}
        />
      </Col>
    </Row>
  )
};

Sizing.propTypes = {
  sourceForm: PropTypes.object,
  handleChangeNumber: PropTypes.func,
  handleSelect: PropTypes.func,
  handleRadio: PropTypes.func,
  handleChangePercent: PropTypes.func,
  handleChangeDec: PropTypes.func
};