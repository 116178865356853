import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Div, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { formatNumber } from "../../../utilities/helpers";
import { Input } from "../../../components/Input";
import { TextArea } from "../../../components/TextArea";

export const Price = ({
  priceInfo,
  setPriceInfo,
  handleChange,
  handleChangeFormat,
}) => {
  const { t, i18n } = useTranslation("newProject");
  const locale = i18n.language;

  const inputProps = {
    sizeLabel: theme.fonts.size.default,
    weightLabel: theme.fonts.weight.regular,
    width: "50%",
  };

  return (
    <>
      <Text
        weight={theme.fonts.weight.medium}
        size={theme.fonts.size.h6}
        color={theme.colors.blue}
        mb="32px"
      >
        {t("price")}
      </Text>
      <Div direction="column" gap="22.5px">
        <Input
          {...inputProps}
          label={t("priceOrientation")}
          id={"precioOrientativoMW"}
          suffix={"€"}
          value={priceInfo.precioOrientativoMW === "" ? "" : formatNumber(priceInfo.precioOrientativoMW, locale)}
          onChange={(e) => handleChangeFormat(e, priceInfo, setPriceInfo)}
        />
        <TextArea 
          {...inputProps}
          label={t("priceConsiderations")}
          id={"consideracionesSobrePrecio"}
          value={priceInfo.consideracionesSobrePrecio}
          onChange={(e) => handleChange(e, priceInfo, setPriceInfo)}
        />
      </Div>
    </>
  );
};

Price.propTypes = {
  priceInfo: PropTypes.object,
  setPriceInfo: PropTypes.func,
  handleChange: PropTypes.func,
  handleChangeFormat: PropTypes.func,
};
