import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { Input } from "../../../components/Input";
import { useTranslation } from "react-i18next";
import percent from "../../../assets/icons/percent.svg";
import { Div, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import RadioGroup from "../../../components/Radio2/Group";
import { Radio } from "../../../components/Radio2";
import { Select } from "../../../components/Select2";
import { generateYearsArray } from "../../../utilities/generalOptions";

export const SwapCoverage = ({
  sourceForm,
  handleChangePercent,
  handleSelect,
  handleRadio
}) => {
  const { t } = useTranslation("strategies");

  return (
    <Row gutter={[32, 32]} style={{ width: '100%', marginTop: "32px" }}>
      <Col md={8}>
        <Div direction="column" align="start" gap="16px">
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray200}
            align="left"
          >
            {t("seniorDebtForm.euriborSwapFloor")}
          </Text>
          <RadioGroup 
            name="euriborFloorSwap"
            gap="50px" 
            value={sourceForm.euriborFloorSwap} 
            onChange={handleRadio}
          >         
            <Radio label={t("seniorDebtForm.yes")} value={true} />
            <Radio label={t("seniorDebtForm.no")} value={false} />
          </RadioGroup>
        </Div>
      </Col>
      <Col md={8}>
        <Input 
          id="swapRate"
          label={t("seniorDebtForm.swapInterest")}
          value={sourceForm.swapRate}
          onChange={handleChangePercent}
          suffix={<img alt="percent" src={percent} />}
        />
      </Col>
      <Col md={8}>
        <Input 
          id="hedge"
          label={t("seniorDebtForm.coverage")}
          value={sourceForm.hedge}
          onChange={handleChangePercent}
          suffix={<img alt="percent" src={percent} />}
        />
      </Col>
      <Col md={8}>
        <Select 
          label={t("seniorDebtForm.initYear")}
          placeholder={t("select")}
          options={generateYearsArray()}
          value={sourceForm.swapStartDate}
          onChange={(e) => handleSelect(e, "swapStartDate")}
          arrowColor={theme.colors.gray200}
          width={"100%"}
        />
      </Col>
      <Col md={8}>
        <Select 
          label={t("seniorDebtForm.lastYear")}
          placeholder={t("select")}
          options={generateYearsArray()}
          value={sourceForm.swapMaturity}
          onChange={(e) => handleSelect(e, "swapMaturity")}
          arrowColor={theme.colors.gray200}
          width={"100%"}
        />
      </Col>
    </Row>
  )
};

SwapCoverage.propTypes = {
  sourceForm: PropTypes.object,
  handleChangePercent: PropTypes.func,
  handleSelect: PropTypes.func,
  handleRadio: PropTypes.func,
};