import axios from "axios";

const axiosClient = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL ||
    "http://ec2-46-137-90-22.eu-west-1.compute.amazonaws.com:3000/",
  // baseURL: 'http://localhost:3000/',
});

const getLocalUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  return user;
};

const deleteLocalUser = () => {
  localStorage.removeItem("user");
};

const setLocalUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

const isRefresTokenURL = (url) => {
  return url.includes("/RefreshToken");
};

axiosClient.interceptors.request.use(
  (config) => {
    const user = getLocalUser();
    const isRefreshTokenUrl = isRefresTokenURL(config?.url);

    if (user) {
      if (isRefreshTokenUrl) {
        config.headers.Authorization = `Bearer ${user.RefreshTokenValue}`;
      } else {
        config.headers.Authorization = `Bearer ${user}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;

    if (
      error?.response?.status === 401 &&
      error.response.data.result.code === "Unauthorized"
    ) {
      deleteLocalUser();
      window.location.href = "/";
    }

    if (error?.response) {
      // Access Token was expired
      const isErrorFromRefresh = isRefresTokenURL(
        error?.response?.request?.responseURL
      );

      if (
        error?.response?.status === 401 &&
        !originalConfig._retry &&
        !isErrorFromRefresh
      ) {
        originalConfig._retry = true;

        /* try {
          const response = await refreshToken();
          const user = await response.data;
          setLocalUser(user);

          axiosClient.defaults.headers.common.Authorization = `Bearer ${user.TokenValue}`;

          return axiosClient(originalConfig);
        } catch (_error) {
          deleteLocalUser();
          return Promise.reject(_error);
        } */
      }
    }

    return Promise.reject(error);
  }
);

export { axiosClient, deleteLocalUser, setLocalUser, getLocalUser };
