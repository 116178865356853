import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Div, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Input } from "../../../components/Input";
import { useTranslation } from "react-i18next";
import { Select } from "../../../components/Select2";
import { SelectMultiple } from "../../../components/SelectMultiple";
import { UploadIcon } from "../../../assets/icons";
import {
  assetTypeOptions,
  projectStateOptions,
  countriesOptions,
  currencyOptions,
  projectProfileOptions,
  assetVerticalptions,
} from "../../../utilities/generalOptions";
import useGetProvinces from "../../Scraper/hooks/useGetCommunities";

export const ProjectSummary = ({
  projectSummary,
  setProjectSummary,
  handleChange,
  handleSelect,
  handleChangeNumber,
}) => {
  const { t } = useTranslation("teaserDocumentation");
  const { data: provincesData, isSuccess: isSuccessProvinces } =
    useGetProvinces();
  const [provincesOptions, setProvincesOptions] = useState([]);
  useEffect(() => {
    if (isSuccessProvinces) {
      setProvincesOptions(
        provincesData?.data?.data.map((ele, indx) => ({
          key: indx,
          label: ele.label,
          value: ele.label,
        }))
      );
    }
  }, [isSuccessProvinces, provincesData]);

  return (
    <>
      <Text color={theme.colors.gray200} mb="18px" mt="20px">
        {t("projectImage")}
      </Text>
      <Div
        style={{ cursor: "pointer" }}
        direction="column"
        background={theme.colors.green100}
        width="100%"
        radius="7px"
        justify="center"
        align="center"
        p="16px 0 24px 0"
        border="1px dashed"
        borderColor={theme.colors.gray200}
        height="139px"
      >
        <Div
          background={theme.colors.gray100}
          radius="50%"
          width="38px"
          height="38px"
          justify="center"
          align="center"
          m="0 0 8px 0"
        >
          <UploadIcon fill={theme.colors.gray200} />
        </Div>
        <Text mb="7px" color={theme.colors.gray200}>
          {t("attach")}
        </Text>
        <Text color={theme.colors.green}>{t("clicAttach")}</Text>
      </Div>

      <Row gap="28px" m="40px 0 0 0">
        <Input
          label={t("projectName")}
          id="projectName"
          width="310px"
          placeholder={t("projectName")}
          value={projectSummary.projectName}
          onChange={(event) =>
            handleChange(event, projectSummary, setProjectSummary)
          }
        />
        <SelectMultiple
          width="310px"
          label={t("assetType")}
          arrowColor={theme.colors.gray200}
          options={assetTypeOptions}
          value={projectSummary.assetType}
          onChange={(value) =>
            handleSelect(value, "assetType", projectSummary, setProjectSummary)
          }
        />
        <SelectMultiple
          label={t("assetVertical")}
          colorPlaceholder={theme.colors.gray200}
          width="310px"
          arrowColor={theme.colors.gray200}
          options={assetVerticalptions}
          value={projectSummary.assetVertical}
          onChange={(value) =>
            handleSelect(
              value,
              "assetVertical",
              projectSummary,
              setProjectSummary
            )
          }
        />
      </Row>

      <Row gap="28px" m="24px 0 0 0">
        <Input
          label={t("projectSize")}
          id="projectSize"
          type="number"
          width="310px"
          suffix="Mwp"
          value={projectSummary.projectSize}
          onChange={(event) =>
            handleChangeNumber(event, projectSummary, setProjectSummary)
          }
        />
        <Select
          placeholder={t("selectStatus")}
          colorPlaceholder={theme.colors.gray200}
          label={t("statusProject")}
          width="310px"
          arrowColor={theme.colors.gray200}
          options={projectStateOptions}
          value={projectSummary.projectStatus}
          onChange={(value) =>
            handleSelect(
              value,
              "projectStatus",
              projectSummary,
              setProjectSummary
            )
          }
        />
        <Select
          label={t("country")}
          placeholder={t("selectCountry")}
          colorPlaceholder={theme.colors.gray200}
          width="310px"
          arrowColor={theme.colors.gray200}
          options={countriesOptions}
          value={projectSummary.country}
          onChange={(value) =>
            handleSelect(value, "country", projectSummary, setProjectSummary)
          }
        />
      </Row>

      <Row gap="28px" m="24px 0 0 0">
        <Select
          placeholder={t("selectCommunity")}
          colorPlaceholder={theme.colors.gray200}
          label={t("autonomousCommunity")}
          width="310px"
          arrowColor={theme.colors.gray200}
          options={provincesOptions}
          value={projectSummary.community}
          onChange={(value) =>
            handleSelect(value, "community", projectSummary, setProjectSummary)
          }
        />
        <Select
          label={t("currency")}
          placeholder={t("selectCurrency")}
          colorPlaceholder={theme.colors.gray200}
          width="310px"
          arrowColor={theme.colors.gray200}
          options={currencyOptions}
          value={projectSummary.currency}
          onChange={(value) =>
            handleSelect(value, "currency", projectSummary, setProjectSummary)
          }
        />
        <Input
          label={t("constructionDeclared")}
          id="constructionDeclared"
          type="number"
          width="310px"
          colorPlaceholder={theme.colors.blue}
          value={projectSummary.constructionDeclared}
          onChange={(event) =>
            handleChangeNumber(event, projectSummary, setProjectSummary)
          }
        />
      </Row>
      <Row gap="28px" m="24px 0 0 0">
        <Input
          label={t("NumberFloors")}
          id="numberFloors"
          type="number"
          width="310px"
          value={projectSummary.numberFloors}
          onChange={(event) =>
            handleChangeNumber(event, projectSummary, setProjectSummary)
          }
        />
        <Select
          colorPlaceholder={theme.colors.gray200}
          label={t("projectProfile")}
          width="310px"
          arrowColor={theme.colors.gray200}
          options={projectProfileOptions}
          value={projectSummary.projectProfile}
          onChange={(value) =>
            handleSelect(
              value,
              "projectProfile",
              projectSummary,
              setProjectSummary
            )
          }
        />
        <Input
          label="PPA´s"
          id="ppa"
          type="number"
          width="310px"
          suffix="%"
          value={projectSummary.ppa}
          onChange={(event) =>
            handleChangeNumber(event, projectSummary, setProjectSummary)
          }
        />
      </Row>
      <Row m="24px 0 0 0">
        <Input
          label={t("otherInformation")}
          id="otherInformation"
          width="100%"
          height="106px"
          value={projectSummary.otherInformation}
          onChange={(event) =>
            handleChange(event, projectSummary, setProjectSummary)
          }
        />
      </Row>
    </>
  );
};

ProjectSummary.propTypes = {
  projectSummary: PropTypes.object,
  setProjectSummary: PropTypes.func,
  handleChange: PropTypes.func,
  handleSelect: PropTypes.func,
  handleChangeNumber: PropTypes.func,
};
