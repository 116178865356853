import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { theme } from "../../../../styles/theme";
import { Row } from "../../../../styles/Common";
import { Input } from "../../../../components/Input";
import { Select } from "../../../../components/Select2";
import {
  debtProfileOptions,
  interestRates,
} from "../../../../utilities/generalOptions";

export const TransactionSummaryLoan = ({
  strategyData,
  setStrategyData,
  tabActiveKey,
}) => {
  const { t } = useTranslation("teaserDocumentation");

  const handleChangeStrategy = (event) => {
    const { value, id } = event.target;
    setStrategyData((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[Number(tabActiveKey) - 1].transactionSummary.loan[id] =
        value;
      return updatedItems;
    });
  };

  const handleSelectStrategy = (value, id) => {
    setStrategyData((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[Number(tabActiveKey) - 1].transactionSummary.loan[id] =
        value;
      return updatedItems;
    });
  };

  return (
    <>
      <Row gap="23px" m="33.5px 0 24px 0">
        <Input
          label={t("targetAmount")}
          id="targetAmount"
          width="100%"
          type="number"
          suffix="€"
          sizeLabel={theme.fonts.size.default}
          value={
            strategyData[Number(tabActiveKey) - 1].transactionSummary.loan
              .targetAmount
          }
          onChange={handleChangeStrategy}
        />
        <Input
          label="Tenor"
          id="tenor"
          type="number"
          width="100%"
          suffix="Años"
          sizeLabel={theme.fonts.size.default}
          value={
            strategyData[Number(tabActiveKey) - 1].transactionSummary.loan.tenor
          }
          onChange={handleChangeStrategy}
        />
        <Select
          width="100%"
          label={t("margin")}
          placeholder="Selecciona"
          sizeLabel={theme.fonts.size.default}
          options={interestRates}
          value={
            strategyData[Number(tabActiveKey) - 1].transactionSummary.loan
              .margin
          }
          onChange={(value) => handleSelectStrategy(value, "margin")}
        />
      </Row>
      <Row gap="23px">
        <Input
          label={t("marginVariable")}
          id="marginVariable"
          type="number"
          width="100%"
          suffix="%"
          sizeLabel={theme.fonts.size.default}
          value={
            strategyData[Number(tabActiveKey) - 1].transactionSummary.loan
              .marginVariable
          }
          onChange={handleChangeStrategy}
        />
        <Input
          label="Non-Call"
          id="nonCall"
          type="number"
          width="100%"
          suffix="Años"
          sizeLabel={theme.fonts.size.default}
          value={
            strategyData[Number(tabActiveKey) - 1].transactionSummary.loan
              .nonCall
          }
          onChange={handleChangeStrategy}
        />
        <Select
          width="100%"
          label={t("debtProfile")}
          placeholder="Selecciona"
          sizeLabel={theme.fonts.size.default}
          options={debtProfileOptions}
          value={
            strategyData[Number(tabActiveKey) - 1].transactionSummary.loan
              .debtProfile
          }
          onChange={(value) => handleSelectStrategy(value, "debtProfile")}
        />
      </Row>
    </>
  );
};

TransactionSummaryLoan.propTypes = {
  strategyData: PropTypes.array,
  setStrategyData: PropTypes.func,
  tabActiveKey: PropTypes.string,
};
