import React from "react";
import PropTypes from "prop-types";
import { Table } from "../../../components/Table";
import { useTranslation } from "react-i18next";
import { Div } from "../../../styles/Common";
import { Tooltip } from "antd";
import { theme } from "../../../styles/theme";
import { ExternalLinkIcon } from "../../../assets/icons";

export const TableContent = ({ 
  source, 
  pages,
  totalItems,
  handleChangeTable,
  data
}) => {
  const { t } = useTranslation("investorActivity");

  const debtColumns = [
    {
      title: t("projectName"),
      dataIndex: "projectName",
      key: "projectName",
    },
    {
      title: t("teaserAccessed"),
      dataIndex: "teaserAccessed",
      key: "teaserAccessed",
    },
    {
      title: t("tearmsheetAccessed"),
      dataIndex: "tearmsheetAccessed",
      key: "tearmsheetAccessed",
    },
    {
      title: t("modelDownloaded"),
      dataIndex: "modelDownloaded",
      key: "modelDownloaded",
    },
    {
      title: t("comments"),
      dataIndex: "comments",
      key: "comments",
      render: (value) => (
        <Div>
          {value === "Si" ? 
            <Div gap="35px">
              {value}
              <ExternalLinkIcon fill={theme.colors.green} style={{ cursor: "pointer" }} />
            </Div>
           : (
            value
          )}
        </Div>
      )
    },
    {
      title: t("signNDA"),
      dataIndex: "signNDA",
      key: "signNDA",
    },
    {
      title: t("dataRoomAccess"),
      dataIndex: "dataRoomAccess",
      key: "dataRoomAccess",
    },
    {
      title: t("dueDiligenciesAccess"),
      dataIndex: "dueDiligenciesAccess",
      key: "dueDiligenciesAccess",
    },
    {
      title: t("lastReminderDate"),
      dataIndex: "lastReminderDate",
      key: "lastReminderDate",
    },
    {
      title: t("lastActionDate"),
      dataIndex: "lastActionDate",
      key: "lastActionDate",
    },
    {
      title: t("actions"),
      dataIndex: "actions",
      key: "actions",
      render: (actions) => (
        <Div justify="space-between" align="center" style={{ cursor: "pointer" }}>
          {actions.map((action, i) => 
            <Tooltip 
              key={i} 
              title={action.tooltip} 
              color={theme.colors.white}
              placement="topRight"
              overlayInnerStyle={{
                color: theme.colors.gray300,
                fontFamily: theme.fonts.family,
                fontSize: theme.fonts.size.sm,
              }}
            >
              {action.icon}
            </Tooltip>
          )}
        </Div>
      ),
    },
  ];

  const maColumns = [
    {
      title: t("projectName"),
      dataIndex: "projectName",
      key: "projectName",
    },
    {
      title: t("teaserAccessed"),
      dataIndex: "teaserAccessed",
      key: "teaserAccessed",
    },
    {
      title: t("teaserDownloaded"),
      dataIndex: "teaserDownloaded",
      key: "teaserDownloaded",
    },
    {
      title: t("t&cRequest"),
      dataIndex: "tcRequest",
      key: "tcRequest",
    },
    {
      title: t("signT&C"),
      dataIndex: "signTC",
      key: "signTC",
    },
    {
      title: t("dataRoomAccess"),
      dataIndex: "dataRoomAccess",
      key: "dataRoomAccess",
    },
    {
      title: t("nboSended"),
      dataIndex: "nboSended",
      key: "nboSended",
    },
    {
      title: t("questions"),
      dataIndex: "questions",
      key: "questions",
    },
    {
      title: t("lastReminderDate"),
      dataIndex: "lastReminderDate",
      key: "lastReminderDate",
    },
    {
      title: t("lastActionDate"),
      dataIndex: "lastActionDate",
      key: "lastActionDate",
    },
    {
      title: t("actions"),
      dataIndex: "actions",
      key: "actions",
      render: (actions) => (
        <Div justify="space-between" align="center" style={{ cursor: "pointer" }}>
          {actions.map((action, i) => 
            <Tooltip 
              key={i} 
              title={action.tooltip} 
              color={theme.colors.white}
              placement="topRight"
              overlayInnerStyle={{
                color: theme.colors.gray300,
                fontFamily: theme.fonts.family,
                fontSize: theme.fonts.size.sm,
              }}
            >
              {action.icon}
            </Tooltip>
          )}
        </Div>
      ),
    },
  ];

  return (
    <Table 
      scroll={{ x: 500 }}
      columns={source === "debt" ? debtColumns : maColumns}
      data={data}
      rowClassName={(record, index) =>
        index % 2 === 0 ? "table-row-light" : "table-row-dark"
      }
      pagination={{
        defaultCurrent: 1,
        ...pages,
        total: totalItems,
        position: ["bottomRight"],
      }}
      onChange={handleChangeTable}
      rowSelection={{
        type: 'checkbox',
      }}
      shadow={"none"}
      padding={"0px"}
      style={{ width: "75vw" }}
      locale={{
        emptyText: 
        // isLoading ? (
        //   <Div justify="center" gap="16px">
        //     {[...Array(9)].map((item, idx) => (
        //       <Skeleton
        //         key={idx}
        //         title={false}
        //         paragraph={{
        //           rows: 10,
        //           width: "100%",
        //         }}
        //         loading
        //       />
        //     ))}
        //   </Div>
        // ) : (
          "No Data"
        // ),
      }}
    />
  )
};

TableContent.propTypes = {
  source: PropTypes.string,
  pages: PropTypes.object,
  totalItems: PropTypes.number,
  handleChangeTable: PropTypes.func,
  data: PropTypes.array,
};