import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { Input } from "../../../components/Input";
import { useTranslation } from "react-i18next";
import { Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import percent from "../../../assets/icons/percent.svg";

export const Intermediation = ({
  sourceForm,
  handleChangePercent
}) => {
  const { t } = useTranslation("strategies");

  const textProps = {
    size: theme.fonts.size.h6,
    weight: theme.fonts.weight.medium,
    color: theme.colors.blue
  }

  return (
    <Row gutter={[32, 16]} style={{ width: '100%' }}>
      <Col md={24}>
        <Text {...textProps}>
          {t("intermediationForm.comission")}
        </Text>
      </Col>
      <Col md={8}>
        <Input 
          id="intermediationOpeningFee"
          label={t("intermediationForm.upFrontFee")}
          value={sourceForm.intermediationOpeningFee}
          onChange={handleChangePercent}
          suffix={<img alt="percent" src={percent} />}
        />
      </Col>
      <Col md={8}>
        <Input 
          id="intermediationSpread"
          label={t("intermediationForm.marginFee")}
          value={sourceForm.intermediationSpread}
          onChange={handleChangePercent}
          suffix={<img alt="percent" src={percent} />}
        />
      </Col>
    </Row>
  )
};

Intermediation.propTypes = {
  sourceForm: PropTypes.object,
  handleChangePercent: PropTypes.func,
};