import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Tabs from "../../../../components/Tabs";
import { Div } from "../../../../styles/Common";
import { ProjectsTableManager } from "./projectsTableManager";

export const ProjectsTabsViewManager = ({
  projects,
  totalProjects,
  payload,
  setPayload,
  activeKey,
  setActiveKey,
  pageSize,
  currentPage,
  onPageChange,
  setShowDocumentsDrawer,
  setShowModalDistribution,
  setSelectedProject,
  setShowDrawerA5Modeling,
  companiesOptions,
  usersOptions,
}) => {
  const { t } = useTranslation("projectsTabsView");

  const tabs = (tab) => {
    switch (tab) {
      case "1":
        return "created";
      case "2":
        return "drafted";
      default:
        break;
    }
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
    setPayload({
      ...payload,
      selectedTab: tabs(key),
    });
  };

  const panels = [
    {
      tab: `${t("projectsCreated")} (${totalProjects.totalCreated})`,
      key: "1",
      type: "created",
      disabled: false,
    },
    {
      tab: `${t("draftProjects")} (${totalProjects.totalDrafted})`,
      key: "2",
      type: "drafted",
      disabled: totalProjects.totalDrafted === 0,
    },
  ];

  return (
    <Div style={{ flexDirection: "column", width: "100%" }}>
      <Tabs
        defaultActiveKey="1"
        onChange={handleTabChange}
        activeKey={activeKey}
      >
        {panels.map((panel) => (
          <Tabs.Panel tab={panel.tab} key={panel.key} disabled={panel.disabled}>
            <ProjectsTableManager
              projects={projects}
              activeKey={activeKey}
              totalProjects={
                payload.selectedTab === "created"
                  ? totalProjects.totalCreated
                  : totalProjects.totalDrafted
              }
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={onPageChange}
              setShowDocumentsDrawer={setShowDocumentsDrawer}
              setShowModalDistribution={setShowModalDistribution}
              setSelectedProject={setSelectedProject}
              setShowDrawerA5Modeling={setShowDrawerA5Modeling}
              companiesOptions={companiesOptions}
              usersOptions={usersOptions}
            />
          </Tabs.Panel>
        ))}
      </Tabs>
    </Div>
  );
};

ProjectsTabsViewManager.propTypes = {
  projects: PropTypes.array,
  totalProjects: PropTypes.number,
  payload: PropTypes.object,
  setPayload: PropTypes.func,
  activeKey: PropTypes.string,
  setActiveKey: PropTypes.func,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  setShowDocumentsDrawer: PropTypes.func,
  setShowModalDistribution: PropTypes.func,
  setSelectedProject: PropTypes.func,
  setShowDrawerA5Modeling: PropTypes.func,
  companiesOptions: PropTypes.array,
  usersOptions: PropTypes.array,
};
