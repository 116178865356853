import React from "react";
import PropTypes from "prop-types";
import Drawer from "../../../../components/Drawer";
import { useTranslation } from "react-i18next";
import { Div, Text } from "../../../../styles/Common";
import CheckboxGroup from "../../../../components/Checkbox/Group";
import Checkbox from "../../../../components/Checkbox";
import { theme } from "../../../../styles/theme";
import { Button } from "../../../../components/Button";
import { Link } from "../../../../components/Link";
import { TransparentScroll } from "../styles";

export const FilterDrawer = ({ 
  open, 
  onClose, 
  filtersOptions, 
  handleFilterChange, 
  handleSaveFilters,
  cleanFilters 
}) => {
  const { t } = useTranslation("projectsTabsView");

  return (
    <Drawer 
      open={open}
      onClose={onClose}
      title={t("filters.filterProjects")}
      width={"332px"}
    >
      <TransparentScroll>
        {Object.keys(filtersOptions).map((cat) => (
          <>
            <Text
              color={theme.colors.blue}
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.h5i}
              mb="14px"
            >
              {`${t(cat)}`}
            </Text>
            <CheckboxGroup direction={cat === "filters.type" ? "row" : "column"} gap={cat === "filters.type" ? 26 : 17}>
              {filtersOptions[cat].map((opt, indx) => (
                <Checkbox 
                  key={indx}
                  id={opt.value}
                  label={`${t(opt.label)}`} 
                  checked={opt.checked}
                  onChange={(event) => handleFilterChange(event, cat, indx)} 
                />
              ))}
            </CheckboxGroup>

            {cat !== "filters.strategy" && (
              <hr style={{ border: `1px solid ${theme.colors.gray100}`, width: "100%", margin: "21.5px 0 16.5px 0" }} />
            )}
          </>
        ))}
      </TransparentScroll>
      <Div direction="column" align="center" justify="center" gap="24px" p="12px 0">
        <Button
          width={"169px"}
          onClick={handleSaveFilters}
        >
          {t("filters.save")}
        </Button>
        <Link onClick={cleanFilters}>{t("filters.clean")}</Link>
      </Div>
    </Drawer>
  )
};

FilterDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  filtersOptions: PropTypes.object,
  handleFilterChange: PropTypes.func,
  handleSaveFilters: PropTypes.func,
  cleanFilters: PropTypes.func,
};
