import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetTyC = (projectId) => {
  const data = useQuery(
    ["getTermsAndConditions", projectId],
    () => axiosClient.get(`project/terms-and-conditions/${projectId}`),
    { enabled: projectId !== null }
  );

  return data;
};
export default useGetTyC;
