import React, { useState, useEffect } from "react";
import { theme } from "../../../styles/theme";
import Drawer from "../../../components/Drawer";
import { Text, Col, Row, Div } from "../../../styles/Common";
import PropTypes from "prop-types";
import { Button } from "../../../components/Button";
import { useTranslation } from "react-i18next";
import Tabs from "../../../components/Tabs";
import Checbox from "../../../components/Checkbox";
import useGetStrategyRelated from "./hooks/useGetStrategyRelated";

export const DrawerStrategies = ({
  openDrawer,
  handleClose,
  selectedCompany,
}) => {
  const { t } = useTranslation("users");
  const [activeKey, setActiveKey] = useState("1");
  const onChange = (key) => {
    setActiveKey(key);
  };

  const getStrategyRelated = useGetStrategyRelated(selectedCompany);
  const { data, isSuccess } = getStrategyRelated;
  const [saleStrategies, setSaleStrategies] = useState([]);
  const [financingStrategies, setFinancingStrategies] = useState([]);
  useEffect(() => {
    if (isSuccess) {
      setSaleStrategies(
        data?.data?.data
          .map((item) => ({ name: item.name, type: item.type, id: item.id }))
          .filter((item) => item.type !== 1)
      );
      setFinancingStrategies(
        data?.data?.data
          .map((item) => ({ name: item.name, type: item.type, id: item.id }))
          .filter((item) => item.type !== 2)
      );
    }
  }, [data, isSuccess]);

  const panels = [
    {
      key: "1",
      tab: "Compraventa",
      children: saleStrategies.map((item, index) => (
        <Row key={index} align="center">
          <Checbox label={item.name} />
        </Row>
      )),
    },
    {
      key: "2",
      tab: "Financiación",
      children: (
        <>
          <Row m="0 0 26px 0">
            <Text weight={theme.fonts.weight.medium} color={theme.colors.blue}>
              Tipo de deuda
            </Text>
          </Row>
          {financingStrategies.map((item, index) => (
            <Row key={index} width="100%">
              <Checbox label={item.name} />
            </Row>
          ))}
        </>
      ),
    },
  ];

  return (
    <Drawer open={openDrawer} onClose={handleClose} width="332px">
      <Text
        size={theme.fonts.size.h5}
        weight={theme.fonts.weight.medium}
        color={theme.colors.blue}
        mb="32px"
      >
        Definir estrategias
      </Text>

      <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onChange}>
        {panels.map((panel) => (
          <Tabs.Panel tab={panel.tab} key={panel.key} disabled={panel.disabled}>
            <Div width="100%" direction="column">
              {panel.children}
            </Div>
          </Tabs.Panel>
        ))}
      </Tabs>

      <Col align="center" style={{ marginTop: "auto" }}>
        <Button
          width="189px"
          background={theme.colors.green}
          hBackground={theme.colors.green}
          color={theme.colors.white}
          border={theme.colors.green}
          m="41px 0 0 0"
        >
          Guardar
        </Button>
        <Button
          width="211px"
          background={theme.colors.white}
          color={theme.colors.green}
          border={theme.colors.white}
          onClick={handleClose}
          hBackground={theme.colors.white}
        >
          {t("drawerNewInvestmentGroup.cancel")}
        </Button>
      </Col>
    </Drawer>
  );
};

DrawerStrategies.propTypes = {
  openDrawer: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedCompany: PropTypes.string,
};
